import React from 'react'

const UIcon = () => {
	return (
		<svg
			width='14'
			height='17'
			viewBox='0 0 14 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14 7.03988C14 3.15079 10.8677 0 7.00136 0C3.13506 0 0 3.15079 0 7.03988C0 7.04537 0 7.04811 0 7.0536V16.9973H2.62483V7.05634C2.62483 7.05086 2.62483 7.04811 2.62483 7.04262C2.62483 4.61091 4.5839 2.64304 6.99864 2.64304C9.41337 2.64304 11.3724 4.61366 11.3724 7.04262C11.3724 7.04811 11.3724 7.05086 11.3724 7.05634V17H14V7.05634C14 7.05086 14 7.04537 14 7.03988Z'
				fill='#939393'
			/>
		</svg>
	)
}

export default React.memo(UIcon)
