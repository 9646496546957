import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const roleApiRedux = createApi({
	reducerPath: 'roleApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllRolesApi: builder.query({
			query: () => `role`,
			transformResponse: response =>
				response.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const { useGetAllRolesApiQuery } = roleApiRedux
