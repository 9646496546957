let colors = [
	{ main: '#EBF3FD', content: '#3484F0' },
	{ main: '#EEFDEB', content: '#107C41' },
	{ main: '#FDEBEB', content: '#AD3C3C' },
	{ main: '#FDFDEB', content: '#929217' },
	{ main: '#EBFDFC', content: '#077872' },
	{ main: '#FAEBFD', content: '#580E67' }
]

export const useColor = () => {
	let color = colors[Math.floor(Math.random() * colors.length)]
	return color
}
