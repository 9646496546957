import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isDragged: false,
	draggedData: null,
	isChanged: false
}

const ProgressReportDrag = createSlice({
	name: 'ProgressReportDrag',
	initialState,
	reducers: {
		setDraggedData: (state, { payload }) => ({ ...state, ...payload })
	}
})

export const { setDraggedData } = ProgressReportDrag.actions
export default ProgressReportDrag.reducer
