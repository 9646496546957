import React from 'react'

const MessageTypePhoto = () => {
	return (
		<div className='sm:w-[263px] w-full'>
			<img
				className='object-cover  w-full max-h-[263px] rounded-[10px] '
				src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrjifigpdr1FX4MX8Ypk9nlj0UwmBcqHheWESKBlzx&s'
			/>
		</div>
	)
}

export default MessageTypePhoto
