import useFetch from 'hooks/useFetch'
const endPoint = '/milestone'

export const AllMilestoneApi = async router => {
	let response = await useFetch(router, true).get(`${endPoint}`)

	return response
}

export const AllMilestoneByProjectIdApi = async (router, projectId) => {
	let response = await useFetch(router, true).get(
		`${endPoint}/getByProjectId/${projectId}`
	)

	return response
}

export const AddMilestoneApi = async (router, data) => {
	let response = await useFetch(router, true).post(`${endPoint}`, {
		...data
	})

	return response
}

export const UpdateMilestoneApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/${data?.id}`, {
		...data
	})
	return response

}

export const DeleteMilestoneApi = async (router, id) => {
	let response = await useFetch(router, true).delete(`${endPoint}/forceDelete/${id}`)

	return response
}
