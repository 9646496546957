import CounterBox from 'components/Common/CounterBox'
import Input from 'components/Common/Input'
import MultiSelectBox from 'components/Common/MultiSelectBox'
import Range from 'components/Common/Range'
import SelectBox from 'components/Common/SelectBox'
import Spinner from 'components/Common/Spinner'
import TextEditor from 'components/Common/TextEditor'
import ImportantPriorityIcon from 'components/Icons/tasks/importantPriority'
import LowPriorityIcon from 'components/Icons/tasks/lowPriority'
import MediumPriorityIcon from 'components/Icons/tasks/mediumPriority'
import UrgentPriorityIcon from 'components/Icons/tasks/urgentPriority'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLazyGetProjectContactsApiQuery } from 'redux/api/contact'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import { UpdateTaskApi } from 'services/dashboardServices/task'

const TaskDetailEditBox = ({ data, updateList, modalHandler }) => {
	const { data: projects, isLoading: projectLoading } =
		useGetProjectsByOrgIdApiQuery({})
	const [getMilestones, { data: milestones, isLoading: milestoneLoading }] =
		useLazyGetAllMilestoneByProjectIdApiQuery()
	const [getContacts, { data: contacts, isLoading: contactsLoading }] =
		useLazyGetProjectContactsApiQuery()
	const navigate = useNavigate()
	const [errors, setErrors] = useState()
	const [isLoading, setIsLoading] = useState(null)
	const [dataSchema, setDataSchema] = useState({
		id: null,
		name: null,
		description: null,
		project_id: null,
		milestone_id: null,
		due_date: null,
		time_budget: null,
		team_id: null,
		priority: null,
		type: null,
		status: null
	})
	const taskPriorities = [
		{id: 1, label: 'low', icon: <LowPriorityIcon />},
		{id: 2, label: 'medium', icon: <MediumPriorityIcon />},
		{id: 3, label: 'important', icon: <ImportantPriorityIcon />},
		{id: 4, label: 'urgent', icon: <UrgentPriorityIcon />},
	]
	const taskTypes = [
		{id: 1, label: 'Task'},
		{id: 2, label: 'QA'},
		{id: 3, label: 'Story'},
		{id: 4, label: 'BUG'},
		{id: 5, label: 'Hotfix'},
	]

	useEffect(() => {
		if (data) {
			setTimeout(() => {
				setDataSchema({
					id: data?.id,
					name: data?.name,
					description: data?.description,
					project_id: {
						...data?.project,
						label: data?.project?.name,
						value: data?.project?.id
					},
					milestone_id: data?.milestone?.id,
					due_date: data?.due_date,
					time_budget: data?.time_budget ? data?.time_budget?.split(':')[0] : 0,
					team_id: data?.teams?.map(item => ({
						...item,
						id: item.team_id,
						value: item.team_id,
						label: `${item.first_name} ${item.last_name}`
					})),
					priority: data?.priority,
					type: data?.type,
					status: null
				})

				getMilestones({ projectId: data?.project?.id })
				getContacts({ projectId: data?.project?.id })
			}, 300)
		}
	}, [data])

	const updateTask = async e => {
		e.preventDefault()
		setIsLoading(true)
		const newDataDataSchema = {
			...dataSchema,
			project_id: dataSchema?.project_id?.id,
			team_ids: dataSchema?.team_id?.map(item => item?.id),
			priority: dataSchema?.priority,
			type: dataSchema?.type
		}
		try {
			let response = await UpdateTaskApi(navigate, newDataDataSchema)
			if (response.status === 200) {
				toast.success('Task successfully Updated')
				updateList()
				modalHandler()
			} if (response.status === 403) {
				toast.error(response?.data?.message)
				modalHandler()
			}
			 else if (response.status === 422) {
				setErrors(response.data.errors)
				toast.error(response?.data?.message)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			toast.error(error?.data?.message)
		}
	}

	const dataSchemaHandler = (_key, _value) => {
		_value = (_key === 'priority' || _key === 'type') ? _value?.label : _value
		setDataSchema({ ...dataSchema, [_key]: _value })

		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setDataSchema(data)
		}
	}
	return (
		<div className=''>
			<form onSubmit={updateTask}>
				<div className='grid grid-cols-2 gap-5'>
					<Input
						label={'Task Name'}
						height={50}
						name='name'
						value={dataSchema.name}
						onInput={dataSchemaHandler}
						placeholder='Enter Task Name'
						errors={errors?.name}
					/>
					<SelectBox
						height={50}
						errors={errors?.project_id}
						label={'Select Project'}
						isDisabled={false}
						name={'project_id'}
						grayBg
						withSearchIcon
						isLoading={!data?.project && projectLoading ? true : false}
						onChange={(_, e) => {
							setDataSchema({
								...dataSchema,
								project_id: e,
								milestone_id: null
							})
							e?.id && getMilestones({ projectId: e.id })
						}}
						value={dataSchema?.project_id}
						options={projects}
						placeholder='Search Project'
					/>
				</div>
				<div className='grid grid-cols-2 gap-5 mt-5'>
					<div className='flex flex-col gap-5'>
						
							<TextEditor
								mode='advanced'
								label={'Task Description'}
								height={252}
								onChange={dataSchemaHandler}
								name='description'
								errors={errors?.description}
								value={dataSchema.description}
							/>
						
						{/* {!dataSchema?.description ||
						dataSchema?.description?.includes('<') ? (
							<TextEditor
								mode='advanced'
								label={'Task Description'}
								height={252}
								onChange={dataSchemaHandler}
								name='description'
								errors={errors?.description}
								value={dataSchema.description}
							/>
						) : (
							<Input
								type={'textarea'}
								label={'Task Description'}
								height={144}
								name='description'
								onInput={dataSchemaHandler}
								placeholder='Describe your task'
								errors={errors?.description}
								value={dataSchema.description}
							/>
						)} */}
						<MultiSelectBox
							name={'team_id'}
							onChange={dataSchemaHandler}
							isDisabled={contactsLoading}
							value={dataSchema?.team_id}
							errors={errors?.team_id}
							options={contacts?.filter(item => !item.role.name.includes('Accountant') && !item.role.name.includes('Client'))}
							placeholder={'Search Contact'}
							height={50}
							label={'Assign To'}
						/>
					</div>
					<div className='task_select_box  flex flex-col gap-5'>
						<SelectBox
							isLoading={!data?.project && milestoneLoading ? true : false}
							onChange={(_, e) => dataSchemaHandler('milestone_id', e.id)}
							options={milestones}
							placeholder='Search milestone'
							value={
								data?.milestone && !milestones
									? {
											value: data?.milestone?.id,
											label: data?.milestone?.name
									  }
									: dataSchema.milestone_id
									? milestones.find(item => item.id === dataSchema.milestone_id)
									: ''
							}
							isDisabled={!milestones}
							height={50}
							errors={errors?.milestone_id}
							label={'Select Milestone'}
							name={'milestone_id'}
							grayBg
						/>
						<Input
							type={'date'}
							label={'Due Date'}
							placeholder='Choose from Calendar'
							defaultValue={dataSchema.due_date}
							height={50}
							name='due_date'
							onInput={dataSchemaHandler}
							errors={errors?.due_date}
							format='YYYY-MM-DD'
						/>

						<CounterBox
							handler={dataSchemaHandler}
							label={'Task Time Budget'}
							name={'time_budget'}
							placeholder={'Enter Task Time Budget'}
							height={50}
							value={dataSchema?.time_budget}
						/>
						{/* <Range
							label={'Task Priority'}
							name={'priority'}
							onChange={dataSchemaHandler}
							value={dataSchema.priority}
						/> */}
						<div className='flex items-center gap-5 w-full'>
							<SelectBox
								height={50}
								containerClassName={'w-full'}
								errors={errors?.priority}
								label={'Select Task Priority'}
								onChange={dataSchemaHandler}
								options={taskPriorities}
								value={taskPriorities.filter(item => item.label === dataSchema.priority)[0]}
								name={'priority'}
							/>		
							<SelectBox
								height={50}
								containerClassName={'w-full'}
								errors={errors?.type}
								label={'Select Task Type'}
								onChange={dataSchemaHandler}
								options={taskTypes}
								value={taskTypes.filter(item => item.label === dataSchema.type)[0]}
								name={'type'}
							/>		
						</div>
					</div>
				</div>

				<div className='grid mt-24 gap-5'>
					<button
						disabled={isLoading}
						name='close'
						type='submit'
						className={`h-11 rounded-[10px] flex items-center justify-center gap-3 border border-solid bg-primaryBlue text-white text-center text-base font-medium${
							!isLoading && 'border-primaryBlue'
						}`}>
						Update
						<Spinner show={isLoading} scale={25} />
					</button>
				</div>
			</form>
		</div>
	)
}

export default TaskDetailEditBox
