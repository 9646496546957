import { createSlice, nanoid } from '@reduxjs/toolkit'

const initialState = {}

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login: (state, action) => ({
			...action.payload
		})
	}
})

export const { login } = user.actions
export default user.reducer
