import React from 'react'

const LogoutIcon = () => {
	return (
		<svg
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.61955 0.5C9.48147 0.5 11 1.9925 11 3.83V7.4225H6.42151C6.09339 7.4225 5.83394 7.6775 5.83394 8C5.83394 8.315 6.09339 8.5775 6.42151 8.5775H11V12.1625C11 14 9.48147 15.5 7.60429 15.5H3.88808C2.01853 15.5 0.5 14.0075 0.5 12.17V3.8375C0.5 1.9925 2.02616 0.5 3.89571 0.5H7.61955ZM12.9051 5.41265C13.1301 5.18015 13.4976 5.18015 13.7226 5.40515L15.9126 7.58765C16.0251 7.70015 16.0851 7.84265 16.0851 8.00015C16.0851 8.15015 16.0251 8.30015 15.9126 8.40515L13.7226 10.5877C13.6101 10.7002 13.4602 10.7601 13.3176 10.7601C13.1677 10.7601 13.0176 10.7002 12.9051 10.5877C12.6801 10.3626 12.6801 9.99515 12.9051 9.77015L14.1052 8.57765H11.0002V7.42265H14.1052L12.9051 6.23015C12.6801 6.00515 12.6801 5.63765 12.9051 5.41265Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(LogoutIcon)
