import ArrowIcon from 'components/Icons/ArrowIcon'
import ReadAllCheckIcon from 'components/Icons/ReadAllCheckIcon'
import RingIcon from 'components/Icons/RingIcon'
import React, { useEffect, useRef, useState } from 'react'
import NotificationProjectItem from './notification/NotificationProjectItem'
import { useParams } from 'react-router-dom'
import { useLazyGetPendingProjectsQuery } from 'redux/api/projects'
import ListZeroPage from 'components/Common/ListZeroPage'
import { DropdownOutsideClick } from 'utils/DropdownOutsideClick'

const NotificationBox = () => {
	const params = useParams()
	const [dropDownCondition, setDropDownCondition] = useState('All')
	const [getPendingProject, { data, isLoading, isFetching }] =
		useLazyGetPendingProjectsQuery()

	const [dropDownStatus, setDropDownStatus] = useState(false)
	const dropdownRef = useRef()

	useEffect(() => {
		getPendingProject()

		document.addEventListener('click', e =>
			DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
		)
		return () =>
			document.removeEventListener('click', e =>
				DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
			)
	}, [])

	return (
		<li ref={dropdownRef} className='lg:px-4 px-0 relative'>
			<button
				onClick={() => setDropDownStatus(true)}
				className='w-11 bg-gray-200 text-gray-400 rounded-[10px] flex items-center justify-center relative h-11 '>
				<RingIcon width={13} height={15} />
				{data?.find(item => item.pending === 1) && (
					<i className='absolute w-2 h-2 rounded-full bg-red-700 top-[12px] right-[14px]'></i>
				)}
			</button>
			{dropDownStatus && (
				<div
					className={`pt-4 mb-2 w-[440px] absolute  lg:top-full bottom-full lg:bottom-0 ${
						params?.projectId ? 'right-0' : 'right-1/2 translate-x-1/2 '
					}`}>
					<div className='bg-white shadow-custom rounded-[10px] w-full'>
						<div className=' px-4 py-3 flex items-center justify-between'>
							<div className='flex items-center gap-2'>
								<span className='text-gray-700 font-medium text-sm'>
									Notifications
								</span>
								<dev className='group/filter relative'>
									<span className='text-gray-400 flex items-center gap-3  text-sm'>
										{dropDownCondition} <ArrowIcon />
									</span>
									<div className='absolute  group-hover/filter:block hidden left-1/2 -translate-x-1/2 top-full z-10 pt-2'>
										<div className='grid text-left gap-1 shadow-lg rounded-md overflow-hidden bg-white '>
											<button
												className='py-1 px-5 hover:bg-gray-200'
												onClick={() => setDropDownCondition('All')}>
												All
											</button>
											<button
												className='py-1 px-5 hover:bg-gray-200'
												onClick={() => setDropDownCondition('Unread')}>
												Unread
											</button>
										</div>
									</div>
								</dev>
							</div>
							<button className='flex items-center gap-1.5 text-sm font-medium text-primaryBlue'>
								Mark all as read
								<ReadAllCheckIcon />
							</button>
						</div>

						<div
							className={`divide-y-2 py-3 divide-gray-200 max-h-[400px] overflow-auto`}>
							<ListZeroPage
								isFetching={isFetching}
								data={data}
								isLoading={isLoading}>
								{data?.map((item, index) => (
									<NotificationProjectItem
										updateList={getPendingProject}
										data={item}
										key={'notification-item-' + index}
									/>
								))}
							</ListZeroPage>
						</div>
					</div>
				</div>
			)}
		</li>
	)
}

export default NotificationBox
