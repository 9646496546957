import { createSlice, nanoid } from '@reduxjs/toolkit'

const initialState = {
    attachments: [],
    recentAttachments: []
}

const attachment = createSlice({
    name: 'attachment',
    initialState,
    reducers: {
        setAttachment: (state, action) => ({
            ...action.payload
        }),
        setRecentAttachments: (state, action) => {
            state.recentAttachments = action.payload
        }
    }
})

export const { setAttachment, setRecentAttachments } = attachment.actions
export default attachment.reducer