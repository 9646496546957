import React, { useEffect, useMemo, useRef, useState } from 'react'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import XIcon from 'components/Icons/XIcon'
import ClockFillIcon from 'components/Icons/ClockFillIcon'
import { useDispatch, useSelector } from 'react-redux'
import { logModalHandler } from 'redux/slices/originModals'
import { trackingStatusHandler } from 'redux/slices/trackingDetail'
import { useNavigate, useParams } from 'react-router-dom'
import {
	AddLogApi,
	DeleteLogApi,
	EditLogApi
} from 'services/dashboardServices/log'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import Spinner from '../Spinner'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import {
	useLazyGetSubtaskByTaskIdApiQuery,
	useLazyGetTaskByMilestoneApiQuery
} from 'redux/api/task'
import {
	useLazyGetAllLogsApiQuery,
	useLazyGetLogByTaskIdQuery,
	useLazyGetProjectsLogQuery
} from 'redux/api/log'
import './AddTaskModal/Task.scss'
import SelectBox from '../SelectBox'
import Input from '../Input'
import DefaultImage from '../DefaultImage'
import { useWindowSize } from 'hooks/useWindowSize'
import { LabelAndValueHandler } from 'utils/LabelAndValueHandler'
import DeleteModal from '../DeleteModal'
import { useRole } from 'hooks/useRole'

const AddLogModal = ({ handler, defaultData, taskDetail, refreshData }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()
	const [width] = useWindowSize()
	const user = useSelector(state => state.user)
	const [getAllLogs] = useLazyGetAllLogsApiQuery()
	const [getProjectLogs] = useLazyGetProjectsLogQuery()
	const { data: projects, isLoading: projectsIsLoading } =
		useGetProjectsByOrgIdApiQuery()
	const [getSubtask, { data: subtasks, isLoading: subtasksIsLoading }] =
		useLazyGetSubtaskByTaskIdApiQuery()
	const [getMilestone, { data: milestones, isLoading: milestonesIsLoading }] =
		useLazyGetAllMilestoneByProjectIdApiQuery()
	const [getTasks, { data: tasks, isLoading: tasksIsLoading }] =
		useLazyGetTaskByMilestoneApiQuery()
	let [getLogs] = useLazyGetLogByTaskIdQuery()
	const [timeTrackingDetail] = useState(
		localStorage.timeTracked ? JSON.parse(localStorage.timeTracked) : null
	)
	const [errors, setErrors] = useState()
	const [isLoading, setIsLoading] = useState(null)
	const [deleteModal, setDeleteModal] = useState(false)
	const [dataSchema, setDataSchema] = useState({
		// project_image: null,
		// project_name: null,
		project_id: null,
		milestone_id: null,
		task_id: null,
		description: null,
		start_datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
		end_datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
		start_timezone: moment().format('YYYY-MM-DD HH:mm:ss'),
		end_timezone: moment().format('YYYY-MM-DD HH:mm:ss'),
		subtask_id: null,
		// team_id: 0,
		// user_id: 0,
		status: 0
	})
	const [duration, setDuration] = useState(timeTrackingDetail?.duration)
	const isPM = useRole(['Project Manager'])
	const taskRef = useRef()

	useEffect(() => {
		if (taskDetail) {
			let task =
				taskDetail.parent_id === null
					? {
							value: taskDetail?.id,
							name: taskDetail?.name,
							id: taskDetail?.id,
							label: taskDetail?.name
					  }
					: {
							value: taskDetail?.parent_id?.id,
							name: taskDetail?.parent_id?.name,
							id: taskDetail?.parent_id?.id,
							label: taskDetail?.parent_id?.name
					  }
			// let task = {
			// 	value: taskDetail?.id,
			// 	name: taskDetail?.name,
			// 	id: taskDetail?.id,
			// 	label: taskDetail?.name
			// }
			getMilestone({ projectId: taskDetail?.project?.id })
			setDataSchema({
				...dataSchema,
				...defaultData,
				milestone_id: LabelAndValueHandler(taskDetail, 'milestone'),
				project_id: LabelAndValueHandler(taskDetail, 'project'),
				task_id: task,
				// project_image: taskDetail?.project?.logo,
				// project_name: taskDetail?.project?.name,
				subtask_id: {
					value: taskDetail?.id,
					name: taskDetail?.name,
					id: taskDetail?.id,
					label: taskDetail?.name
				}
			})
			getTasks(taskDetail?.milestone?.id)
			getSubtask({ taskId: taskDetail?.id })
		} else if (defaultData) {
			setDataSchema({
				...dataSchema,
				...defaultData,
				milestone_id: LabelAndValueHandler(defaultData, 'milestone'),
				project_id: LabelAndValueHandler(defaultData, 'project'),
				task_id: LabelAndValueHandler(defaultData, 'task')
				// subtask_id: defaultData?.subTasks?.length && {
				// 	...defaultData?.subTasks[0],
				// 	label: defaultData?.subTasks[0]?.name,
				// 	value: defaultData?.subTasks[0]?.id
				// },
				// project_image: defaultData?.project?.logo,
				// project_name: defaultData?.project?.name
			})
			getTasks(defaultData?.milestone?.id)
			getSubtask({ taskId: defaultData?.task?.id })
		} else if (timeTrackingDetail?.project_id && checkIsForAUser()) {
			getMilestone({ projectId: timeTrackingDetail?.project_id })
			setTimeout(() => {
				setDataSchema({
					...dataSchema,
					start_datetime: moment(timeTrackingDetail?.startTime).format(
						'YYYY-MM-DD HH:mm:ss'
					),
					start_timezone: moment(timeTrackingDetail?.startTime).format(
						'YYYY-MM-DD HH:mm:ss'
					),
					project_image: timeTrackingDetail?.logo,
					project_name: timeTrackingDetail?.name,
					project_id: {
						id: timeTrackingDetail?.id,
						value: timeTrackingDetail?.id,
						name: timeTrackingDetail?.name,
						label: timeTrackingDetail?.name
					}
				})
			}, 300)
		} else if (params?.projectId) {
			getMilestone({ projectId: params?.projectId })
		}
	}, [])

	useEffect(() => {
		if (
			projects &&
			!timeTrackingDetail &&
			params?.projectId &&
			!defaultData &&
			!taskDetail
		) {
			setTimeout(() => {
				setDataSchema({
					...dataSchema,
					project_name: projects?.find(
						item => item.id === Number(params?.projectId)
					)?.name,
					project_image: projects?.find(
						item => item.id === Number(params?.projectId)
					)?.logo,
					project_id: projects?.find(
						item => item.id === Number(params?.projectId)
					)
				})
			}, 300)
		}
	}, [projects])

	const isOtherLog = () => {
		if (!defaultData || defaultData.user_id === user.user_id) return false
		return true
	}

	const dataSchemaHandler = (_key, _value) => {
		setDataSchema({ ...dataSchema, [_key]: _value })

		_key === 'milestone_id' &&
			_value === null &&
			setDataSchema({
				...dataSchema,
				milestone_id: null,
				task_id: null,
				subTask_id: null
			})
		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setErrors(data)
		}
	}

	const dataSchemaTimeHandler = (_time, _timZone, _value) => {
		setDataSchema({
			...dataSchema,
			[_time]: _value,
			[_timZone]: _value
		})
		if (errors && errors[_time]) {
			const data = errors
			delete data[_time]
			setErrors(data)
		}
	}

	const addLog = async e => {
		let newSchema = {
			...dataSchema,
			project_id: dataSchema?.project_id?.id,
			task_id: dataSchema?.subtask_id?.id || dataSchema?.task_id?.id,
			subtask_id: dataSchema?.subtask_id?.id,
			milestone_id: dataSchema?.milestone_id?.id
		}

		e.preventDefault()
		setIsLoading(true)
		try {
			const response = defaultData
				? await EditLogApi(navigate, newSchema)
				: await AddLogApi(navigate, newSchema)

			if (response.status === 200) {
				if (!defaultData) {
					toast.success('Log successfully created')
					dispatch(logModalHandler(false))
					refreshData ? refreshData() : getAllLogs()
					dispatch(trackingStatusHandler(false))
					localStorage.removeItem('timeTracked')
				} else {
					toast.success('Log successfully edited')
				}
			} else if (response.status === 422) {
				setErrors(response.data.errors)
				setIsLoading(false)
				return
			}
			if (taskDetail) {
				await getLogs({ taskId: taskDetail?.id })
			} else if (params?.projectId) {
				await getProjectLogs({ projectId: params?.projectId })
			} else {
				await getAllLogs({})
			}

			handler()
			setDeleteModal(false)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	const checkIsForAUser = () => {
		return (
			JSON.parse(localStorage?.timeTracked)?.user?.user_id === user?.user_id
		)
	}

	const deleteLog = async () => {
		setIsLoading('delete')
		try {
			await DeleteLogApi(navigate, defaultData?.id)

			if (params?.projectId) {
				await getProjectLogs({ projectId: params?.projectId })
			} else {
				await getAllLogs({})
			}
			handler()
			setDeleteModal(false)
			setIsLoading(null)
		} catch (error) {
			setIsLoading(null)
			toast.error(error.message)
		}
	}

	const durationHandler = useMemo(() => {
		let { start_timezone, end_timezone } = dataSchema
		let duration = Number(
			(
				(new Date(end_timezone).getTime() -
					new Date(start_timezone).getTime()) /
				1000
			).toFixed()
		)

		let hours = Math.floor(duration / 3600)
		let minutes = Math.floor((duration - hours * 3600) / 60)
		let second = duration % 60 < 10 ? `0${duration % 60}` : duration % 60
		let finalDuration = `${hours < 10 ? `0${hours}` : hours}:${
			minutes < 10 ? `0${minutes}` : minutes
		}:${second}`
		setTimeout(() => {
			setDuration(finalDuration)
		}, 100)
	}, [dataSchema.end_datetime, dataSchema.start_datetime])

	useEffect(() => {
		if (isPM && defaultData) {
			getMilestone({ projectId: defaultData?.project?.id })
			getTasks(defaultData?.milestone?.id)
			getSubtask({ taskId: defaultData?.task?.id })
		}
	}, [defaultData])

	return (
		<ModalContainer rounded={10} setShowModal={handler}>
			<div className='md:w-[575px] w-full md:p-10 p-5 md:pb-9'>
				<div className='flex items-center  mb-5 justify-between'>
					<h3 className='flex items-center gap-2 font-medium text-lg text-gray-700'>
						<span className='text-primaryBlue'>
							<ClockFillIcon />
						</span>
						{defaultData ? 'Edit' : 'New'} log
					</h3>
					<button onClick={handler} className='opacity-40'>
						<XIcon color='#212121' />
					</button>
				</div>
				{(timeTrackingDetail?.name && checkIsForAUser()) ||
				dataSchema?.project_id ? (
					<div className='p-2 grid md:mb-5 mb-4 border border-solid border-gray-300 md:grid-cols-2 items-center md:gap-9 gap-2 rounded-[10px]'>
						<div className='bg-[#FBFBFB] px-5 flex items-center gap-4 rounded-[10px] md:h-16 h-12'>
							<DefaultImage
								src={dataSchema?.project_image}
								bgColor={dataSchema?.background_color}
								mainColor={dataSchema?.foreground_color}
								type='project'
								iconSize={30}
								size={width > 768 ? 44 : 32}
							/>

							<h5 className='md:text-base text-sm text-gray-700 font-medium'>
								{timeTrackingDetail?.name
									? timeTrackingDetail?.name
									: dataSchema?.project_id?.name}
							</h5>
						</div>
						<div className='flex md:flex-col -mt-3 justify-between md:items-start items-center'>
							<h6 className='font-medium text-[10px] text-gray-400 text-opacity-40 '>
								Duration
							</h6>
							<span className='text-primaryBlue flex font-bold md:text-2xl text-[22px] md:-mb-4'>
								{duration}
							</span>
						</div>
					</div>
				) : null}

				<form onSubmit={addLog}>
					<div className='grid gap-5 mb-8'>
						<SelectBox
							name={'project_id'}
							label={'Select Project'}
							height={50}
							withSearchIcon={true}
							isDisabled={isOtherLog() || !projects || taskDetail !== undefined}
							errors={errors?.project_id}
							onChange={(_, e) => {
								setDataSchema({
									...dataSchema,
									project_name: e?.name,
									project_image: e?.logo,
									project_id: e
								})
								getMilestone({ projectId: e?.id })
							}}
							isLoading={projectsIsLoading}
							options={projects}
							placeholder='Search Project'
							value={
								timeTrackingDetail && checkIsForAUser()
									? {
											value: dataSchema.project_id,
											label: dataSchema.project_name
									  }
									: dataSchema.project_id
							}
						/>
						<SelectBox
							name={'milestone_id'}
							label={'Select Milestone'}
							height={50}
							isDisabled={
								isPM
									? false
									: isOtherLog() ||
									  !dataSchema.project_id ||
									  milestonesIsLoading ||
									  taskDetail !== undefined
									? true
									: false
							}
							onChange={(_, e) => {
								dataSchemaHandler('milestone_id', e)
								e?.id && getTasks(e?.id)
							}}
							value={dataSchema?.milestone_id}
							errors={errors?.milestone_id}
							isLoading={milestonesIsLoading}
							options={milestones}
							isSearchable={false}
							placeholder='Select Milestone'
						/>

						<div className=' grid md:grid-cols-2 gap-3 '>
							<SelectBox
								name={'task_id'}
								label={'Select Task'}
								height={50}
								isDisabled={
									isPM
										? false
										: isOtherLog() ||
										  !dataSchema.milestone_id ||
										  tasksIsLoading ||
										  taskDetail !== undefined
										? true
										: false
								}
								value={dataSchema?.task_id}
								onChange={(_, e) => {
									dataSchemaHandler('task_id', e)
									e?.id && getSubtask({ taskId: e.id })
								}}
								isLoading={tasksIsLoading}
								options={tasks}
								isSearchable={false}
								errors={errors?.task_id}
								placeholder='Select Task'
							/>
							<SelectBox
								name={'subtask_id'}
								label={'Select Subtask (Optional)'}
								height={50}
								value={dataSchema?.subtask_id}
								isDisabled={
									isPM
										? false
										: isOtherLog() ||
										  !dataSchema.task_id ||
										  subtasksIsLoading ||
										  taskDetail !== undefined
										? true
										: false
								}
								onChange={(_, e) => {
									dataSchemaHandler('subtask_id', e)
								}}
								isLoading={subtasksIsLoading}
								options={subtasks}
								errors={errors?.subtask_id}
								isSearchable={false}
								placeholder='Select Subtask'
							/>
						</div>

						<div className=' grid md:grid-cols-2 gap-3 '>
							<Input
								className={'!bg-white'}
								height={50}
								label={'Start time'}
								name={'start_datetime'}
								disabled={isOtherLog()}
								onInput={(_, d) =>
									dataSchemaTimeHandler('start_datetime', 'start_timezone', d)
								}
								defaultValue={dataSchema.start_datetime}
								type={'date'}
								placeholder='Choose from Calendar'
								required={true}
								withTime={true}
								errors={errors?.start_datetime}
							/>
							<Input
								className={'!bg-white'}
								height={50}
								label={'End time'}
								disabled={isOtherLog()}
								name={'start_datetime'}
								onInput={(_, d) =>
									dataSchemaTimeHandler('end_datetime', 'end_timezone', d)
								}
								defaultValue={dataSchema.end_datetime}
								errors={errors?.end_datetime}
								type={'date'}
								placeholder='Choose from Calendar'
								required={true}
								withTime={true}
							/>
							{/* <div className='custom_date_picker_input  bg_white custom_date_picker_input_without_icon'>
								<label className='font-light flex text-gray-700 text-sm mb-0.5'>
									Start time
								</label>
								<div className='relative'>
									<DatePicker
										onChange={d =>
											dataSchemaTimeHandler(
												'start_datetime',
												'start_timezone',
												d.format('YYYY-MM-DD HH:mm:ss')
											)
										}
										format='YYYY-MM-DD HH:mm:ss'
								
										placeholder='Choose from Calendar'
										plugins={[<TimePicker position='bottom' />]}
									/>
									<span className='absolute text-gray-350 right-6 top-1/2 -translate-y-1/2'>
										<CalendarFillIcon />
									</span>
								</div>
							</div> */}
							{/* <div className='custom_date_picker_input bg_white custom_date_picker_input_without_icon'>
								<label className='font-light flex text-gray-700 text-sm mb-0.5'>
									End time
								</label>
								<div className='relative'>
									<DatePicker
										onChange={d =>
											dataSchemaTimeHandler(
												'end_datetime',
												'end_timezone',
												d.format('YYYY-MM-DD HH:mm:ss')
											)
										}
										format='YYYY-MM-DD HH:mm:ss'
										plugins={[<TimePicker position='bottom' />]}
										value={dataSchema.end_datetime}
										placeholder='Choose from Calendar'
									/>
									<span className='absolute text-gray-350 right-6 top-1/2 -translate-y-1/2'>
										<CalendarFillIcon />
									</span>
								</div>
							</div> */}
						</div>
						<Input
							className={'!bg-white'}
							height={99}
							disabled={isOtherLog()}
							label={'Description'}
							name={'description'}
							onInput={dataSchemaHandler}
							value={dataSchema.description}
							errors={errors?.description}
							type={'textarea'}
							placeholder='Eg. Webste Redesign'
							required={true}
							withTime={true}
						/>
					</div>

					<div className='text-center items-center flex'>
						<button
							type='button'
							onClick={() => {
								if (defaultData && !isOtherLog()) {
									setDeleteModal(true)
								} else {
									if (isOtherLog()) return handler()
									dispatch(trackingStatusHandler(false))
									localStorage.removeItem('timeTracked')
									handler()
								}
							}}
							className='w-fit mx-auto text-sm text-red-700'>
							{defaultData && !isOtherLog() ? 'Delete' : 'Cancel'} log
						</button>
						<button
							type='submit'
							disabled={isOtherLog() || isLoading}
							className='bg-primaryBlue w-[65%] gap-3  flex items-center justify-center h-12 rounded-[10px] font-medium text-base text-white '>
							{defaultData ? 'Edit' : 'Create'}
							<Spinner scale={25} show={isLoading} />
						</button>
					</div>
				</form>

				{deleteModal && (
					<DeleteModal
						deleteHandler={deleteLog}
						title={'Log'}
						isLoading={isLoading === 'delete'}
						modalHandler={() => setDeleteModal(false)}
					/>
				)}
			</div>
		</ModalContainer>
	)
}

export default AddLogModal
