import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const reportApiRedux = createApi({
	reducerPath: 'reportApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllHoursReportApi: builder.query({
			query: filters => {
				return {
					url: 'report/getHoursReport',
					params: filters
				}
			},
			transformResponse: response => response.data
		}),
		getAccountantReportApi: builder.query({
			query: filters => {
				return {
					url: 'report/getHoursReport',
					params: filters
				}
			},
			transformResponse: response => response.data
		}),
		getSelfReportApi: builder.query({
			query: filters => {
				return {
					url: 'report/getSelfReport',
					params: filters
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetAllHoursReportApiQuery,
	useLazyGetAccountantReportApiQuery,
	useLazyGetSelfReportApiQuery
} = reportApiRedux
