import axiosInstance from '../helper/axiosInstance'
import { toast } from 'react-toastify'

const AxiosInstance = (router = null, auth = false) => {
	axiosInstance.interceptors.request.use(
		function (config) {
			config = config ?? {}
			config.headers = config.headers ?? {}
			if (auth) {
				if (!localStorage?.token) {
					router('/login')
					return
				}
				return {
					...config,
					headers: {
						...config?.headers,
						Authorization: `Bearer ${localStorage?.token}`,
						orgId: localStorage?.organization
							? JSON.parse(localStorage?.organization)?.id
							: null
					}
				}
			} else {
				return config
			}
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error)
		}
	)

	// Add a response interceptor
	axiosInstance.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response
		},
		function (error) {

			// Any status codes that falls outside the range of 2xx cause this function to trigger
			if (!error.response) {
				toast.warn('Network Error', {
					toastId: 'custom-id-network'
				})
			} else {

				if (error.response.status === 400) {
					toast.warn(error.response.data.message, {
						toastId: 'custom-id-400'
					})
				}

				if (error.response.status === 401) {
					toast.error(error?.response?.data?.message || "You're Unauthorized", {
						toastId: 'error-401'
					})
					localStorage.clear()
					router('/login')
				}

				if (error.response.status === 403) {
					toast.error(error.response.data.message, {
						toastId: 'error-403'
					})
				}

				if (error.response.status === 422) {
					return error.response
				}

				if (error.response.status === 404) {
					toast.error(error.response.data.message, {
						toastId: 'custom-id-500'
					})
				}

				if (error.response.status === 500) {
					toast.warn('500 Internal Server Error', {
						toastId: 'custom-id-500'
					})
				}
			}
			return Promise.reject(error)
		}
	)
	return axiosInstance
}

export default AxiosInstance
