import React from 'react'

const SendMessageIcon = () => {
	return (
		<svg
			width='19'
			height='18'
			viewBox='0 0 19 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.84272 1.07892L17.6452 8.63741C17.8047 8.71377 17.9394 8.83368 18.0337 8.98331C18.1279 9.13295 18.178 9.30619 18.178 9.48304C18.178 9.65989 18.1279 9.83313 18.0337 9.98276C17.9394 10.1324 17.8047 10.2523 17.6452 10.3287L1.84272 17.8872C1.68065 17.9648 1.49968 17.9943 1.32134 17.972C1.143 17.9498 0.974828 17.8767 0.836841 17.7616C0.698854 17.6464 0.596871 17.494 0.543041 17.3226C0.489211 17.1511 0.485801 16.9678 0.533218 16.7944L2.11422 10.9984C2.13357 10.9274 2.17341 10.8636 2.22875 10.8151C2.2841 10.7665 2.35252 10.7353 2.42547 10.7254L10.083 9.68516C10.1149 9.68068 10.1451 9.66804 10.1707 9.64847C10.1964 9.62889 10.2165 9.60304 10.2292 9.57341L10.2427 9.52616C10.2486 9.48429 10.2402 9.44165 10.2188 9.40516C10.1975 9.36867 10.1644 9.34048 10.125 9.32516L10.0837 9.31391L2.43372 8.27441C2.3609 8.26435 2.29265 8.23311 2.23745 8.18457C2.18224 8.13603 2.14252 8.07234 2.12322 8.00141L0.533218 2.17166C0.485801 1.99831 0.489211 1.81498 0.543041 1.64351C0.596871 1.47205 0.698854 1.31966 0.836841 1.20452C0.974828 1.08937 1.143 1.01631 1.32134 0.994041C1.49968 0.971773 1.68065 1.00123 1.84272 1.07892Z'
				fill='white'
			/>
		</svg>
	)
}

export default SendMessageIcon
