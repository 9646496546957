import ConversationContentBox from 'components/Page/Conversations/ConversationContentBox'
import ConversationSendMessageBox from 'components/Page/Conversations/ConversationSendMessageBox'
import React, { useState } from 'react'
import TaskDetailConversationHead from './TaskDetailConversationHead'

const TaskDetailConversation = () => {
	const [haveReply, setHaveReply] = useState(true)
	const [selectMessage, setSelectMessage] = useState(null)

	return (
		<div
			className={`w-full -ml-[10px]  overflow-hidden z-[99] lg:top-0 top-[75px]  lg:relative bg-gray-300 bottom-0 transition duration-300 lg:right-0 ${
				selectMessage ? 'right-0' : '-right-full'
			} fixed`}>
			<TaskDetailConversationHead />
			<ConversationContentBox haveReply={haveReply} />
			<ConversationSendMessageBox
				haveReply={haveReply}
				setHaveReply={setHaveReply}
			/>
		</div>
	)
}

export default TaskDetailConversation
