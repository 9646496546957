import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const organizationApiRedux = createApi({
	reducerPath: 'organizationApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllOrganizationApi: builder.query({
			query: ({ contact }) => ({ url: 'organization', params: contact }),
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		GetOrganizationApi: builder.query({
			query: orgId => `organization/${orgId}`,
			transformResponse: response => response.data
		}),
		getOrganizationUsersApi: builder.query({
			query: () =>  `organization/users/all`,
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetAllOrganizationApiQuery,
	useLazyGetAllOrganizationApiQuery,
	useGetOrganizationApiQuery,
	useGetOrganizationUsersApiQuery
} = organizationApiRedux
