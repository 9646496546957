import ArrowIcon from 'components/Icons/ArrowIcon'
import XIcon from 'components/Icons/XIcon'
import React, { useEffect } from 'react'

const MultiSelectBox = ({
	label,
	placeholder,
	height,
	errors,
	onChange,
	name,
	isDisabled,
	options,
	value
}) => {
	const selectItem = data => {
		if (value) {
			onChange(name, [...value, data])
			return
		}
		onChange(name, [data])
	}
	const removeItem = data => {
		const result = value.filter(item => item.value !== data.value)
		onChange(name, result)
	}

	return (
		<div>
			<label className='font-light flex text-gray-700 text-sm mb-0.5'>
				{label}
			</label>
			<div className='relative group'>
				<div
					style={{ height: height + 'px' }}
					className={`relative font-light text-xs text-gray-700 text-opacity-30 px-6 flex items-center justify-between border border-solid rounded-[10px] ${errors
							? 'border-red-700 bg-red-500 bg-opacity-10'
							: 'bg-gray-300 border-gray-200'
						} ${isDisabled && 'bg-[#d8d8d8]'}`}>
					{placeholder}
					<ArrowIcon color='#939393' height={12} width={12} />
				</div>
				<div
					className={`absolute ${
						!isDisabled && 'group-hover:inline-block'
					}  hidden pt-2 top-full left-0 z-[2] w-full`}>
					<ul className='bg-white w-full overflow-hidden rounded-lg border border-solid border-gray-200 shadow-lg'>
						{options?.map(item => (
							<li className='w-full'>
								<button
									type='button'
									onClick={() =>
										!value?.find(select => select.value === item.value) &&
										selectItem(item)
									}
									className={`px-6 w-full text-left  py-3 ${value?.find(select => select.value === item.value)
											? 'bg-primaryBlue cursor-default text-white'
											: 'hover:bg-gray-200'
										}`}>
									{item?.label ? item?.label : item?.name}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
			<ul className='mt-2 flex flex-wrap gap-2'>
				{value?.map(item => (
					<li className='border text-sm text-primaryBlue font-medium flex items-center gap-4 border-primaryBlue border-solid rounded-[10px] px-3 h-[30px]'>
						{item?.label ? item?.label : item?.name}
						<button
							onClick={() => removeItem(item)}
							type='button'
							className='opacity-60'>
							<XIcon color='#3484F0' height={8} width={8} />
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}

export default MultiSelectBox
