import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MicIcon from 'components/Icons/MicIcon'
import ChatUploadIcon from 'components/Icons/ChatUploadIcon'
import SendMessageIcon from 'components/Icons/SendMessageIcon'
import ConversationReplyBox from './MessageTypes/ConversationReplyBox'
import ConversationVoiceRecorder from './ConversationVoiceRecorder'
import PauseIcon from 'components/Icons/PauseIcon'
import useRecorder from 'hooks/useRecorder'
import { useWindowSize } from 'hooks/useWindowSize'

const ConversationSendMessageBox = ({ haveReply, setHaveReply }) => {
	const [width] = useWindowSize()
	const [message, setMessage] = useState()
	const uploadFile = useRef()
	let [audioURL, isRecording, startRecording, stopRecording, clearAudio, time] =
		useRecorder()

	return (
		<div className='py-2 pl-3 pr-2 bg-white lg:rounded-[10px] relative lg:mx-5  lg:mb-3'>
			{haveReply && (
				<ConversationReplyBox
					removeReplyHandler={() => setHaveReply(false)}
					isInput={true}
				/>
			)}
			<button className='min-w-[97px] w-full lg:lg  flex-shrink-0 h-[58px] lg:hidden absolute top-0 left-0 rounded bg-gray-300 flex items-center justify-center text-xs font-light text-gray-700 text-opacity-50'>
				Select Task
			</button>
			<div className={`flex lg:mt-0 ${!haveReply && 'mt-[60px]'} relative`}>
				<button className='min-w-[97px] lg:flex hidden mr-2 flex-shrink-0 h-[37px] rounded bg-gray-200  items-center justify-center text-xs font-light text-gray-700 text-opacity-50'>
					Select Task
				</button>

				{audioURL ? (
					<ConversationVoiceRecorder
						recordingTime={time}
						voice={audioURL}
						removeVoice={clearAudio}
						startRecording={startRecording}
						stopRecording={stopRecording}
					/>
				) : (
					<>
						{' '}
						<input
							onChange={e => setMessage(e.target.value)}
							value={message}
							className='w-full flex-1 lg:ml-0 ml-[50px] x-3 font-medium text-sm text-gray-700 focus:outline-none placeholder:text-opacity-40'
							placeholder='Type your message'
						/>
						<div className='flex gap-1 flex-shrink-0'>
							<button
								onClick={e => uploadFile.current.click()}
								className='h-[37px] lg:relative absolute left-0 flex-shrink-0 text-gray-700 text-opacity-40  w-[37px] bg-gray-200  rounded-[5px] flex items-center justify-center '>
								<ChatUploadIcon />
								<input ref={uploadFile} type={'file'} className='hidden' />
							</button>
							{message ? (
								<button
									onClick={e => setMessage('')}
									className='h-[37px] flex-shrink-0  w-[37px] bg-primaryBlue  rounded-[5px] flex items-center justify-center '>
									<SendMessageIcon />
								</button>
							) : (
								<>
									<button
										onClick={() => {
											if (width <= 1024) {
												!isRecording ? startRecording() : stopRecording()
											}
										}}
										onMouseDown={() => width > 1024 && startRecording()}
										onMouseUp={() => width > 1024 && stopRecording()}
										onMouseLeave={() => {
											if (isRecording) {
												width > 1024 && stopRecording()
											}
										}}
										className={`h-[37px] flex-shrink-0 text-primaryBlue   w-[37px] border border-solid  rounded-[5px] flex items-center justify-center ${
											isRecording
												? 'bg-red-700 border-red-700'
												: 'border-primaryBlue'
										}`}>
										{isRecording ? <PauseIcon /> : <MicIcon />}
									</button>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

ConversationSendMessageBox.propTypes = {
	haveReply: PropTypes.bool.isRequired,
	setHaveReply: PropTypes.func
}

export default ConversationSendMessageBox
