import React from 'react'

const EyeIcon = () => {
	return (
		<svg
			width='13'
			height='9'
			viewBox='0 0 13 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.746 4.2527C12.7272 4.21156 12.2824 3.22387 11.2998 2.24125C9.98504 0.928554 8.32805 0.234375 6.49992 0.234375C4.6718 0.234375 3.01481 0.928554 1.7016 2.24125C0.718987 3.22387 0.274143 4.21156 0.253831 4.2527C0.219428 4.33069 0.20166 4.41501 0.20166 4.50025C0.20166 4.5855 0.219428 4.66981 0.253831 4.74781C0.27262 4.78945 0.717464 5.77664 1.70059 6.75926C3.01481 8.07195 4.6718 8.76562 6.49992 8.76562C8.32805 8.76562 9.98504 8.07195 11.2977 6.75926C12.2809 5.77664 12.7257 4.78945 12.7445 4.74781C12.7791 4.66992 12.7972 4.58566 12.7974 4.50041C12.7977 4.41517 12.7802 4.3308 12.746 4.2527ZM10.407 5.92848C9.31676 7.00199 8.00254 7.54688 6.49992 7.54688C4.99731 7.54688 3.68309 7.00199 2.59434 5.92797C2.16592 5.50406 1.7974 5.02363 1.49899 4.5C1.79748 3.97658 2.166 3.49633 2.59434 3.07254C3.6836 1.99801 4.99731 1.45312 6.49992 1.45312C8.00254 1.45312 9.31625 1.99801 10.4055 3.07254C10.8339 3.49629 11.2024 3.97655 11.5009 4.5C11.2024 5.0236 10.8339 5.50402 10.4055 5.92797L10.407 5.92848ZM6.49992 2.26562C6.05801 2.26562 5.62601 2.39667 5.25857 2.64218C4.89113 2.8877 4.60475 3.23666 4.43563 3.64494C4.26652 4.05322 4.22227 4.50248 4.30848 4.9359C4.3947 5.36933 4.6075 5.76746 4.91998 6.07994C5.23247 6.39243 5.63059 6.60523 6.06402 6.69144C6.49745 6.77766 6.9467 6.73341 7.35498 6.56429C7.76326 6.39518 8.11222 6.10879 8.35774 5.74135C8.60326 5.37391 8.7343 4.94192 8.7343 4.5C8.73363 3.90761 8.498 3.33968 8.07912 2.9208C7.66024 2.50192 7.09231 2.2663 6.49992 2.26562ZM6.49992 5.51562C6.29905 5.51562 6.10269 5.45606 5.93567 5.34446C5.76866 5.23286 5.63848 5.07424 5.56161 4.88866C5.48474 4.70308 5.46463 4.49887 5.50381 4.30186C5.543 4.10485 5.63973 3.92388 5.78177 3.78184C5.92381 3.63981 6.10477 3.54308 6.30179 3.50389C6.4988 3.4647 6.70301 3.48481 6.88859 3.56168C7.07417 3.63856 7.23279 3.76873 7.34439 3.93575C7.45598 4.10277 7.51555 4.29913 7.51555 4.5C7.51555 4.76936 7.40855 5.02769 7.21808 5.21816C7.02761 5.40862 6.76929 5.51562 6.49992 5.51562Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(EyeIcon)
