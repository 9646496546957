import React from 'react'

const ReplyArrow = () => {
	return (
		<svg
			width='14'
			height='10'
			viewBox='0 0 14 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.700048 9.67933C0.700048 9.67933 2.25755 2.86693 8.40005 2.86693V0.078125L13.3 4.67923L8.40005 9.36223V6.31233C5.06595 6.31163 2.43885 6.60703 0.700048 9.67933Z'
				fill='#3484F0'
			/>
		</svg>
	)
}

export default React.memo(ReplyArrow)
