import React from 'react'

const RingIcon = ({ width = 31, height = 37 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 31 37'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.8507 31.4497C12.7464 31.2602 18.2042 31.2602 19.0998 31.4497C19.8655 31.6265 20.6935 32.0398 20.6935 32.942C20.649 33.8009 20.1451 34.5624 19.4489 35.0461C18.5461 35.7498 17.4866 36.1955 16.379 36.3561C15.7664 36.4355 15.1646 36.4373 14.5734 36.3561C13.464 36.1955 12.4045 35.7498 11.5035 35.0442C10.8055 34.5624 10.3016 33.8009 10.257 32.942C10.257 32.0398 11.0851 31.6265 11.8507 31.4497ZM15.5811 0.583008C19.308 0.583008 23.1151 2.35142 25.3765 5.28553C26.8438 7.17484 27.5169 9.06235 27.5169 11.9965V12.7598C27.5169 15.01 28.1116 16.3363 29.4204 17.8647C30.4123 18.9907 30.7292 20.4361 30.7292 22.0042C30.7292 23.5705 30.2146 25.0574 29.1836 26.2646C27.8338 27.7118 25.9303 28.6357 23.9876 28.7963C21.1724 29.0363 18.3553 29.2384 15.5009 29.2384C12.6447 29.2384 9.8295 29.1175 7.01427 28.7963C5.06978 28.6357 3.16624 27.7118 1.81827 26.2646C0.787268 25.0574 0.270874 23.5705 0.270874 22.0042C0.270874 20.4361 0.589614 18.9907 1.57967 17.8647C2.92941 16.3363 3.48498 15.01 3.48498 12.7598V11.9965C3.48498 8.98295 4.23642 7.01244 5.78382 5.08343C8.08445 2.27021 11.7722 0.583008 15.4208 0.583008H15.5811Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(RingIcon)
