import ClockIcon from 'components/Icons/ClockIcon'
import React, { useEffect, useState } from 'react'
import TaskDetailModal from './TaskDetailModal/TaskDetailModal'
import moment from 'moment'
import DefaultImage from 'components/Common/DefaultImage'
import { useRole } from 'hooks/useRole'
import { DeleteTaskApi } from 'services/dashboardServices/task'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DeleteModal from 'components/Common/DeleteModal'
import PencilIcon from 'components/Icons/PencilIcon'
import TrashcanIcon from 'components/Icons/TrashcanIcon'
import ProgressDropDown from 'components/Common/ProgressDropDown'
import EyeIcon from 'components/Icons/EyeIcon'
import LowPriorityIcon from 'components/Icons/tasks/lowPriority'
import MediumPriorityIcon from 'components/Icons/tasks/mediumPriority'
import ImportantPriorityIcon from 'components/Icons/tasks/importantPriority'
import UrgentPriorityIcon from 'components/Icons/tasks/urgentPriority'

function SubTaskItem({
	data,
	isStatus = true,
	updateList,
	collapseCondition,
	detailModalCondition,
	deleteModalCondition,
	detailModalConditionHandler,
	deleteModalConditionHandler
}) {
	const isClient = useRole('Client')
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const deleteItem = async () => {
		setIsLoading(true)
		try {
			await DeleteTaskApi(navigate, data?.id)
			await updateList()
			setIsLoading(false)
			deleteModalConditionHandler(false)
		} catch (error) {
			setIsLoading(false)
			toast.error(error.response.data?.message, {
				toastId: `delete-task-${data?.id}`
			})
		}
	}

	const taskPriorities = [
		{ id: 1, label: 'low', icon: <LowPriorityIcon /> },
		{ id: 2, label: 'medium', icon: <MediumPriorityIcon /> },
		{ id: 3, label: 'important', icon: <ImportantPriorityIcon /> },
		{ id: 4, label: 'urgent', icon: <UrgentPriorityIcon /> }
	]

	return (
		<>
			{/* ${collapseCondition ? 'absolute w-[80%] bg-white' : ''} */}
			<div
				className={`flex items-center px-8 py-3 justify-between z-100 `}
				onClick={() => detailModalConditionHandler(true)}>
				<div className='flex items-center md:gap-6 gap-3'>
					<div>
						<h5 className='md:text-base text-sm text-gray-700 font-bold'>
							{data.name}
						</h5>
						<h6 className='md:text-xs gap-2 flex items-center text-[10px] font-light text-gray-700 text-opacity-80'>
							<span className='font-medium'>
								Logged hours {data?.totalHours} /{data?.time_budget}
							</span>
						</h6>
					</div>
				</div>
				<div className='flex items-center'>
					{data?.priority === 'urgent' && (
						<div className='lg:flex items-center gap-1 opacity-80 hidden text-sm text-red-700  text-right mr-1 font-light text-opacity-80'>
							{data?.priority &&
								taskPriorities.filter(item => item.label === data?.priority)[0]
									?.icon}
							{'Urgent'}
						</div>
					)}
					{!isClient && (
						<div className='lg:flex hidden pr-3  border-r border-solid border-gray-200 ml-[10px]'>
							{data?.teams?.map(
								(item, index) =>
									index <= 2 && (
										<DefaultImage
											bgColor={item?.background_color}
											mainColor={item?.foreground_color}
											className={' border border-solid border-white'}
											size={40}
											src={item?.avatar}
											name={item?.first_name + ' ' + item?.last_name}
										/>
									)
							)}
							{data?.teams.length > 3 && (
								<div className='w-10 h-10 text-sm font-medium text-gray-400 flex items-center justify-center first:ml-0 bg-gray-200 -ml-5 rounded-[10px] border border-solid border-white'>
									+{data?.teams.length - 1}
								</div>
							)}
						</div>
					)}

					<div className='ml-3 flex gap-1.5 mr-3'>
						{!isClient && (
							<ProgressDropDown
								isSubtask
								parentDetail={data}
								data={data?.progress}
							/>
						)}

						<div className='h-10    rounded-[10px]  gap-3  pl-4 flex items-center justify-between'>
							<ClockIcon />
							<span className='text-gray-700 text-sm'>
								{moment(data.due_date).format('YYYY-MM-DD')}
							</span>
						</div>
					</div>
					{isClient && (
						<button
							onClick={() => detailModalConditionHandler(true)}
							className='w-10 h-10 shrink-0 text-gray-400 rounded-[10px] flex justify-center items-center bg-gray-200'>
							<EyeIcon opacity={0.5} color='currentColor' />
						</button>
					)}
					{!isClient && (
						<div className='flex gap-2 pl-3 ml-3  border-l border-solid border-gray-200'>
							<button
								onClick={() => detailModalConditionHandler(true)}
								className='w-10 h-10 text-gray-400 rounded-[10px] flex justify-center items-center bg-gray-200'>
								<PencilIcon opacity={0.5} color='currentColor' />
							</button>
							<button
								onClick={() => deleteModalConditionHandler(true)}
								className='w-10 h-10 text-gray-400 rounded-[10px] flex justify-center items-center bg-gray-200'>
								<TrashcanIcon opacity={0.5} color='currentColor' />
							</button>
						</div>
					)}
				</div>
			</div>

			{detailModalCondition && (
				<TaskDetailModal
					data={data}
					isSubtask={true}
					updateList={updateList}
					handler={() => detailModalConditionHandler(false)}
				/>
			)}

			{deleteModalCondition && (
				<DeleteModal
					isLoading={isLoading}
					deleteHandler={deleteItem}
					title={data?.name}
					modalHandler={() => deleteModalConditionHandler(false)}
				/>
			)}
		</>
	)
}

export default SubTaskItem
