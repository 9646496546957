import React from 'react'

function PenFillIcon() {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g opacity='0.6'>
				<path
					d='M1.99276 10.9553L1.09554 14.8275C1.06459 14.969 1.06565 15.1157 1.09865 15.2568C1.13164 15.3979 1.19574 15.5298 1.28626 15.643C1.37677 15.7561 1.49142 15.8476 1.62182 15.9108C1.75223 15.9739 1.89509 16.0072 2.03999 16.008C2.1075 16.0148 2.17553 16.0148 2.24304 16.008L6.13888 15.1108L13.6189 7.65914L9.44443 3.49414L1.99276 10.9553Z'
					fill='currentColor'
				/>
				<path
					d='M15.9705 3.92906L13.1844 1.14295C13.0013 0.960706 12.7534 0.858398 12.495 0.858398C12.2366 0.858398 11.9887 0.960706 11.8055 1.14295L10.2567 2.69184L14.4264 6.86156L15.9753 5.31268C16.0659 5.22157 16.1377 5.11349 16.1865 4.99462C16.2354 4.87574 16.2603 4.7484 16.2598 4.61989C16.2594 4.49138 16.2336 4.36421 16.184 4.24567C16.1343 4.12713 16.0618 4.01955 15.9705 3.92906Z'
					fill='currentColor'
				/>
			</g>
		</svg>
	)
}

export default React.memo(PenFillIcon)
