import MinesIcon from 'components/Icons/MinesIcon'
import PlusIcon from 'components/Icons/PlusIcon'
import React from 'react'

const CounterBox = ({
	name,
	label,
	handler,
	value,
	height = 50,
	placeholder
}) => {
	return (
		<div>
			<label className='font-light flex text-gray-700 text-sm mb-0.5'>
				{label}
			</label>

			<div
				style={{ height: height + 'px' }}
				className=' flex justify-between items-center bg-gray-300 p-[5px] w-full border border-solid border-gray-200 placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50 rounded-[10px]'>
				<button
					type='button'
					disabled={value > 0 ? false : true}
					onClick={() =>
						value > 0
							? handler(
									name,

									value - 1
							  )
							: () => {}
					}
					className={`w-10 text-primaryBlue rounded-lg h-10 border border-solid  bg-white flex items-center justify-center ${
						value > 0 ? 'border-primaryBlue' : 'border-gray-400'
					}`}>
					<MinesIcon />
				</button>

				<input
					className='font-light border-none text-center bg-transparent text-xs text-gray-700 '
					placeholder={placeholder}
					onChange={e =>
						handler(name, !isNaN(e.target.value) ? Number(e.target.value) : 0)
					}
					value={value === 0 ? '' : value}
				/>
				<button
					type='button'
					onClick={() => handler(name, !value ? 1 : value + 1)}
					className='w-10 text-primaryBlue rounded-lg h-10 border border-solid border-primaryBlue bg-white flex items-center justify-center'>
					<PlusIcon />
				</button>
			</div>
		</div>
	)
}

export default CounterBox
