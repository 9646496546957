import React from 'react'

const LowPriorityIcon = ({...props}) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
            {...props}>
			    <g id="emojione-monotone:down-arrow" clip-path="url(#clip0_5541_16352)">
                    <path id="Vector" d="M8.90625 1.19138C4.73189 1.19138 1.34766 4.57561 1.34766 8.74997C1.34766 12.9243 4.73189 16.3086 8.90625 16.3086C13.0806 16.3086 16.4648 12.9243 16.4648 8.74997C16.4648 4.57561 13.0806 1.19138 8.90625 1.19138ZM8.90625 13.0332L4.875 9.17703H7.61046V4.46677H10.2018V9.17703H12.9375L8.90625 13.0332Z" 
                    fill={"#219653"}/>
                </g>
                <defs>
                    <clipPath id="clip0_5541_16352">
                        <rect width="16.125" height="16.125" fill="white" transform="translate(0.84375 0.687469)"/>
                    </clipPath>
                </defs>
		</svg>
	)
}

export default LowPriorityIcon