import { useSelector } from 'react-redux'

export const useRole = userRole => {
	const { role } = useSelector(state => state.user)
	if (Array.isArray(userRole) && userRole.includes(role?.name)) {
		return true
	} else if (userRole === role?.name) {
		return true
	} else {
		return false
	}
}
