import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	refreshTask: false,
}

const Task = createSlice({
	name: 'Task',
	initialState,
	reducers: {
		setRefreshTask: (state, { payload }) => ({ refreshTask: payload }),
	}
})

export const { setRefreshTask } = Task.actions
export default Task.reducer