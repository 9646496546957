import useFetch from 'hooks/useFetch'
const endPoint = '/auth'

export const LoginApi = async (router, _data) => {
	let response = await useFetch(router).post(`${endPoint}/login`, {
		email: _data.email,
		password: _data.password
	})

	return response
}

export const ChangePasswordApi = async (router, _data) => {
	let response = await useFetch(router, true).put(
		`${endPoint}/changePassword`,
		_data
	)

	return response
}

export const ResetPasswordApi = async (router, _data) => {
	let response = await useFetch(router).post(`${endPoint}/resetPassword`, _data)

	return response
}
export const SendEmailForResetPasswordApi = async (router, _email) => {
	let response = await useFetch(router).post(`${endPoint}/forgetPassword`, {
		email: _email
	})

	return response
}

export const GetUserData = async (router, token, project_id) => {
	const url = project_id === 0 ?  `${endPoint}` : `${endPoint}?project_id=${project_id}`;
	let response = await useFetch(router).get(url,{ headers: {"Authorization" : `Bearer ${token}`} });
	return response;
}
