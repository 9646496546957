import React from 'react'

const TimelineIcon = () => {
	return (
		<svg
			width='20'
			height='18'
			viewBox='0 0 20 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.1622 2.15009C13.1255 2.35842 13.1072 2.56675 13.1072 2.77509C13.1072 4.65009 14.7755 6.16592 16.8288 6.16592C17.058 6.16592 17.278 6.14175 17.5072 6.10842V12.8326C17.5072 15.6584 15.6738 17.3334 12.5572 17.3334H5.78392C2.66634 17.3334 0.833008 15.6584 0.833008 12.8326V6.66675C0.833008 3.83342 2.66634 2.15009 5.78392 2.15009H13.1622ZM13.3464 7.21675C13.098 7.19175 12.8514 7.29175 12.7038 7.47509L10.4864 10.0834L7.94634 8.26675C7.79051 8.15842 7.60717 8.11592 7.42384 8.13342C7.24142 8.15842 7.07642 8.24925 6.96551 8.38259L4.25309 11.5918L4.19717 11.6668C4.04134 11.9326 4.11467 12.2743 4.38967 12.4584C4.51801 12.5334 4.65551 12.5834 4.81134 12.5834C5.02309 12.5918 5.22384 12.4909 5.35217 12.3334L7.65301 9.64092L10.2655 11.4251L10.348 11.4743C10.6413 11.6159 11.008 11.5501 11.2188 11.2993L13.868 8.19175L13.8313 8.20842C13.978 8.02509 14.0055 7.79175 13.9047 7.58342C13.8048 7.37509 13.5838 7.23342 13.3464 7.21675ZM16.9573 0.666504C18.1764 0.666504 19.1664 1.5665 19.1664 2.67484C19.1664 3.78317 18.1764 4.68317 16.9573 4.68317C15.7381 4.68317 14.7481 3.78317 14.7481 2.67484C14.7481 1.5665 15.7381 0.666504 16.9573 0.666504Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(TimelineIcon)
