import React from 'react'
import PropTypes from 'prop-types'
import XIcon from 'components/Icons/XIcon'

const ConversationReplyBox = ({ isMe, isInput, removeReplyHandler }) => {
	return (
		<div
			className={`bg-gray-200 relative ${
				isMe && 'bg-opacity-40'
			} mb-3 rounded-[5px] py-[10px] px-3`}>
			<div className='border-l-[1.5px] w-[80%]   border-l-primaryBlue border-solid pl-2'>
				<h6 className='text-xs font-medium text-current'>Alirezaa</h6>
				<div className='text-current text-opacity-40 three-dots text-xs font-light  '>
					Lorem ipsum dolor sit amet, consect Lorem ipsum dolor sit amet,
					consect Lorem ipsum dolor sit amet, consect
				</div>
			</div>
			{isInput && (
				<button onClick={removeReplyHandler} className='absolute top-1/2 -translate-y-1/2 right-6 opacity-60'>
					<XIcon color='#212121' width={16} height={16} />
				</button>
			)}
		</div>
	)
}

ConversationReplyBox.propTypes = {
	isMe: PropTypes.bool.isRequired,
	isInput: PropTypes.bool.isRequired,
	removeReplyHandler: PropTypes.func
}

ConversationReplyBox.defaultProps = {
	isMe: false,
	isInput: false,
	removeReplyHandler: () => {}
}

export default ConversationReplyBox
