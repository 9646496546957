import React from 'react'

const DefaultOrganizationIcon = () => {
	return (
		<svg
			width='17'
			height='19'
			viewBox='0 0 17 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4.99823 4.03125C4.99852 3.41011 5.17501 2.80179 5.50721 2.27694C5.83941 1.7521 6.31369 1.33226 6.87496 1.0662C7.43623 0.800131 8.06147 0.69875 8.67806 0.773828C9.29464 0.848907 9.87728 1.09736 10.3583 1.49035C10.8393 1.88333 11.199 2.40471 11.3955 2.99394C11.5921 3.58316 11.6174 4.21606 11.4687 4.81912C11.3199 5.42218 11.0031 5.97066 10.555 6.40086C10.107 6.83107 9.54609 7.12534 8.93748 7.2495V9.0625H11.7812C12.3034 9.0625 12.8041 9.26992 13.1733 9.63913C13.5426 10.0083 13.75 10.5091 13.75 11.0312V11.7531C14.5453 11.9178 15.2515 12.3713 15.732 13.0261C16.2126 13.6809 16.4336 14.4905 16.3522 15.2987C16.2708 16.1068 15.893 16.8561 15.2915 17.402C14.6901 17.9479 13.9077 18.2515 13.0955 18.2544C12.2804 18.2567 11.4936 17.9557 10.8882 17.41C10.2828 16.8643 9.90199 16.1129 9.81992 15.302C9.73784 14.4911 9.96035 13.6786 10.4442 13.0226C10.928 12.3667 11.6385 11.9142 12.4375 11.7531V11.0312C12.4375 10.8572 12.3683 10.6903 12.2453 10.5672C12.1222 10.4441 11.9553 10.375 11.7812 10.375H4.78123C4.60719 10.375 4.44027 10.4441 4.31719 10.5672C4.19412 10.6903 4.12498 10.8572 4.12498 11.0312V11.7531C4.92033 11.9178 5.62646 12.3713 6.10704 13.0261C6.58761 13.6809 6.80855 14.4905 6.7272 15.2987C6.64584 16.1068 6.26795 16.8561 5.66651 17.402C5.06506 17.9479 4.2827 18.2515 3.47048 18.2544C2.65542 18.2567 1.86865 17.9557 1.26321 17.41C0.657784 16.8643 0.276992 16.1129 0.194917 15.302C0.112842 14.4911 0.335354 13.6786 0.819165 13.0226C1.30298 12.3667 2.01349 11.9142 2.81248 11.7531V11.0312C2.81248 10.5091 3.01991 10.0083 3.38912 9.63913C3.75833 9.26992 4.25909 9.0625 4.78123 9.0625H7.62498V7.2495C6.88338 7.09787 6.2169 6.69476 5.73826 6.10834C5.25963 5.52193 4.99822 4.7882 4.99823 4.03125Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(DefaultOrganizationIcon)
