import React from 'react'

const ReadAllCheckIcon = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14 8.00024C14 4.68774 11.3125 2.00024 8 2.00024C4.6875 2.00024 2 4.68774 2 8.00024C2 11.3127 4.6875 14.0002 8 14.0002C11.3125 14.0002 14 11.3127 14 8.00024Z'
				stroke='#3484F0'
				stroke-width='1.5'
				stroke-miterlimit='10'
			/>
			<path
				d='M10.9999 5.50049L6.79994 10.5005L4.99994 8.50049'
				stroke='#3484F0'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(ReadAllCheckIcon)
