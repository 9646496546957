import CheckBoxStrokeIcon from 'components/Icons/CheckBoxStrokeIcon'
import CheckboxIcon from 'components/Icons/CheckboxIcon'
import CubeIcon from 'components/Icons/CubeIcon'
import DocumentFillIcon from 'components/Icons/DocumentFillIcon'
import PlusIcon from 'components/Icons/PlusIcon'
import ProjectIcon from 'components/Icons/ProjectIcon'
import useOutsideClick from 'hooks/useOutsideClick'
import { useRole } from 'hooks/useRole'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	milestoneModalHandler,
	noteModalHandler,
	subTaskModalHandler,
	taskModalHandler
} from 'redux/slices/originModals'
import { DropdownOutsideClick } from 'utils/DropdownOutsideClick'

const HeaderModalsDropdown = () => {
	const isAdmin = useRole(['Admin'])
	const isPM = useRole(['Project Manager'])
	const dispatch = useDispatch()
	const [dropDownStatus, setDropDownStatus] = useState(false)
	const dropdownRef = useRef()

	useEffect(() => {
		document.addEventListener('click', e =>
			DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
		)
		return () =>
			document.removeEventListener('click', e =>
				DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
			)
	}, [])

	return (
		<>
			<li className='lg:px-4 px-0' ref={dropdownRef}>
				<div className='relative'>
					{!isAdmin && <button
						onClick={() => setDropDownStatus(!dropDownStatus)}
						className='w-11 bg-primaryBlue rounded-[10px] flex items-center justify-center text-white h-11 '>
						<PlusIcon />
					</button>}
					{dropDownStatus && (
						<div className='pt-4 absolute block  lg:right-1/2 lg:translate-x-1/2 lg:top-full lg:bottom-0 bottom-full mb-2'>
							<ul className='bg-white shadow-custom  rounded-[10px] min-w-[214px] p-4'>
								<li className='p-4 pt-0 first:pt-0 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
									<button
										onClick={() => dispatch(taskModalHandler(true))}
										className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
										<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
											<CheckboxIcon />
										</span>
										New Task
									</button>
								</li>
								<li className='p-4   pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
									<button
										onClick={() => dispatch(subTaskModalHandler(true))}
										className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
										<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
											<CheckBoxStrokeIcon />
										</span>
										New Subtask
									</button>
								</li>
								{isPM && (
									<li className='p-4 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
										<button
											onClick={() => dispatch(milestoneModalHandler(true))}
											className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
											<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
												<CubeIcon width={17} height={17} />
											</span>
											New Milestone
										</button>
									</li>
								)}

								<li className='p-4 last:pb-0 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
									<button
										onClick={() => dispatch(noteModalHandler(true))}
										className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
										<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
											<DocumentFillIcon />
										</span>
										New Note
									</button>
								</li>
								{isPM && (
									<li className='p-4 pb-0 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
										<Link
											to={'/dashboard/projects/add-project'}
											className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
											<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
												<ProjectIcon />
											</span>
											New Project
										</Link>
									</li>
								)}
							</ul>
						</div>
					)}
				</div>
			</li>
		</>
	)
}

export default HeaderModalsDropdown
