import CubeIcon from 'components/Icons/CubeIcon'
import XIcon from 'components/Icons/XIcon'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	AddMilestoneApi,
	UpdateMilestoneApi
} from 'services/dashboardServices/milestone'
import { toast } from 'react-toastify'
import Spinner from '../Spinner'
import Input from '../Input'
import SelectBox from '../SelectBox'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import CounterBox from '../CounterBox'
import { useLazyGetProjectMilestoneApiQuery } from 'redux/api/milestone'
const AddMilestoneModal = ({ defaultData, modalHandler }) => {
	const { data: projects } = useGetProjectsByOrgIdApiQuery()
	const [getMilestone] = useLazyGetProjectMilestoneApiQuery()

	const params = useParams()
	const navigate = useNavigate()
	const [errors, setErrors] = useState()
	const [isLoading, setIsLoading] = useState(null)
	const [dataSchema, setDataSchema] = useState({
		name: null,
		description: null,
		project_id: null,
		start_date: null,
		end_date: null,
		duration: 0,
		budget_hours: 0,
		status: null
	})
	useEffect(() => {
		if (params?.projectId && projects) {
			let project = projects.find(item => item.id === Number(params?.projectId))
			setDataSchema({ ...defaultData, project_id: project })
		}
	}, [projects])
	const addMilestone = async e => {
		e.preventDefault()
		const status = e.nativeEvent.submitter.getAttribute('name')
		setIsLoading(status)
		const newSchema = { ...dataSchema, project_id: dataSchema?.project_id?.id }
		try {
			const response = defaultData
				? await UpdateMilestoneApi(navigate, newSchema)
				: await AddMilestoneApi(navigate, newSchema)
			if (response.status === 200) {
				if (params?.projectId) {
					await getMilestone({ projectId: params?.projectId })
				}
				e.target.reset()
				setDataSchema({
					projectId: params?.projectId || null,
					name: null,
					description: null,
					start_date: null,
					end_date: null,
					duration: 0,
					budget_hours: 0,
					status: null
				})
				toast.success('Milestone successfully created')
				if (status === 'close') {
					modalHandler()
				}
			} else if (response.status === 422) {
				setErrors(response.data.errors)
			}
			setIsLoading(null)
		} catch (error) {
			setIsLoading(null)
		}
	}

	const dataSchemaHandler = (_key, _value) => {
		setDataSchema({ ...dataSchema, [_key]: _value })

		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setErrors(data)
		}
	}

	return (
		<ModalContainer rounded={10} setShowModal={() => modalHandler()}>
			<div className=' w-[575px] p-10 pb-9'>
				<div className='flex items-center mb-10 justify-between'>
					<h3 className='flex items-center gap-2 font-medium text-lg text-gray-700'>
						<span className='text-primaryBlue'>
							<CubeIcon width={17} height={17} />
						</span>
						{defaultData ? 'Edit' : 'New'} Milestone
					</h3>
					<button onClick={() => modalHandler()} className='opacity-40'>
						<XIcon color='#212121' />
					</button>
				</div>

				<form onSubmit={addMilestone}>
					<div className='grid gap-5 mb-12'>
						<Input
							label={'Milestone Name'}
							height={50}
							errors={errors?.name}
							name={'name'}
							value={dataSchema?.name}
							required={true}
							placeholder={'Enter Milestone Name'}
							onInput={dataSchemaHandler}
						/>
						<SelectBox
							label={'Select Project'}
							withSearchIcon={true}
							grayBg={true}
							isDisabled={defaultData ? true : !projects}
							height={50}
							errors={errors?.project_id}
							name={'project_id'}
							value={dataSchema.project_id}
							required={true}
							placeholder='Search Project'
							options={projects}
							isLoading={projects === 'loading' ? true : false}
							onChange={dataSchemaHandler}
						/>

						<Input
							label={'Description'}
							height={50}
							errors={errors?.description}
							name={'description'}
							value={dataSchema?.description}
							placeholder='Eg. Webste Redesign'
							onInput={dataSchemaHandler}
						/>
						<div className=' grid md:grid-cols-2 gap-3 '>
							<Input
								label={'Expected Start Date'}
								height={50}
								errors={errors?.start_date}
								name={'start_date'}
								defaultValue={dataSchema?.start_date}
								placeholder='Choose from Calendar'
								onInput={dataSchemaHandler}
								format='YYYY-MM-DD'
								type='date'
							/>
							<Input
								label={'Expected End Date'}
								height={50}
								errors={errors?.end_date}
								name={'end_date'}
								defaultValue={dataSchema?.end_date}
								placeholder='Choose from Calendar'
								onInput={dataSchemaHandler}
								format='YYYY-MM-DD'
								type='date'
							/>
						</div>
						<CounterBox
							handler={dataSchemaHandler}
							label={'Duration Days'}
							name={'duration'}
							placeholder={'Enter Duration Days'}
							height={50}
							value={dataSchema?.duration}
						/>
						<CounterBox
							handler={dataSchemaHandler}
							label={'Budget Hours'}
							name={'budget_hours'}
							height={50}
							placeholder={'Enter Budget Hours'}
							value={dataSchema?.budget_hours}
						/>
					</div>

					<div className='text-center'>
						<button
							name={'close'}
							disabled={isLoading}
							type='submit'
							className='bg-primaryBlue mb-5 w-full gap-3 flex items-center justify-center h-12 rounded-[10px] font-medium text-base text-white '>
							Submit & Close
							{isLoading === 'close' && <Spinner scale={25} />}
						</button>
						<button
							name={'clear'}
							disabled={isLoading}
							type='submit'
							className={`h-11 rounded-[10px] text-primaryBlue justify-center  flex items-center border border-solid w-full gap-3 ${
								!isLoading && 'border-primaryBlue'
							} text-center text-base font-medium`}>
							Submit & Create More
							{isLoading === 'clear' && <Spinner scale={25} />}
						</button>
					</div>
				</form>
			</div>
		</ModalContainer>
	)
}

export default AddMilestoneModal
