import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const logApiRedux = createApi({
	reducerPath: 'logApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllLogsApi: builder.query({
			query: ({ projectId, search, milestoneId, taskId, teamId, approval }) => ({
				url: `log`,
				params: { search, milestoneId, taskId, teamId, projectId, approval }
			})
		}),
		getProjectsLog: builder.query({
			query: ({ projectId, search, milestoneId, taskId, teamId }) => ({
				url: `log/getLogsByProjectId/${projectId}`,
				params: { search, milestoneId, taskId, teamId }
			}),

			transformResponse: response => response.data
		}),
		getLogByTaskId: builder.query({
			query: ({ taskId, search }) => ({
				url: `log/getLogsByTaskId/${taskId}`,
				params: { search }
			}),

			transformResponse: response => response.data
		}),
		getTeamLogsApi: builder.query({
			query: ({ teamId, dateFilter, startDate, endDate }) => ({
				url: `log/getLogsByTeamId/${teamId}`,
				params: {
					dateFilter,
					startDate,
					endDate
				}
			}),

			transformResponse: response => response.data
		}),
		getUserLogsByProjectIdApi: builder.query({
			query: ({ projectId, dateFilter, startDate, endDate }) => ({
				url: `log/getUserLogsByProjectId/${projectId}`,
				params: {
					dateFilter,
					startDate,
					endDate
				}
			}),

			transformResponse: response => response.data
		}),
		getLogByUserIdApi: builder.query({
			query: ({ projectId, dateFilter, startDate, endDate, userId }) => ({
				url: `log/viewLogsByUserId/${userId}`,
				params: { projectId, dateFilter, startDate, endDate }
			}),
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetAllLogsApiQuery,
	useLazyGetAllLogsApiQuery,
	useGetProjectsLogQuery,
	useLazyGetProjectsLogQuery,
	useLazyGetTeamLogsApiQuery,
	useLazyGetUserLogsByProjectIdApiQuery,
	useGetLogByTaskIdQuery,
	useLazyGetLogByTaskIdQuery,
	useLazyGetLogByUserIdApiQuery
} = logApiRedux

