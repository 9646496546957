import React from 'react'
import PropTypes from 'prop-types'
import ConversationMessageItem from './MessageTypes/ConversationMessageItem'
import { useWindowSize } from 'hooks/useWindowSize'

const ConversationContentBox = ({ haveReply }) => {
	const [width] = useWindowSize()
	return (
		<div className='custom_scroll'>
			<div
				style={{
					height: `calc(100vh - ${
						width <= 1024
							? haveReply
								? '254px'
								: '254px'
							: haveReply
							? '305px'
							: '240px'
					})`
				}}
				className='sm:pl-5 pl-3 pr-3 pb-3 flex flex-col gap-3 py-1.5 overflow-auto '>
				<ConversationMessageItem />
				<ConversationMessageItem type={'audio'} />
				<ConversationMessageItem isMe={true} />
				<ConversationMessageItem type='photo' />
				<ConversationMessageItem type='video' />
				<ConversationMessageItem type='file' />
				<ConversationMessageItem type='link' />
			</div>
		</div>
	)
}

ConversationContentBox.propTypes = {
	haveReply: PropTypes.bool.isRequired
}

export default ConversationContentBox
