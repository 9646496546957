import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from '../../../ModalContainer/ModalContainer'

// icon
import ArrowIcon from 'components/Icons/ArrowIcon'
import ClockIcon from 'components/Icons/ClockIcon'
import FlagIcon from 'components/Icons/FlagIcon'
import XIcon from 'components/Icons/XIcon'
import TaskDetailModalTabContent from './TaskDetailModalTabContent'
import moment from 'moment'
import DefaultImage from 'components/Common/DefaultImage'
import { useGetTaskApiQuery } from 'redux/api/task'
import Spinner from 'components/Common/Spinner'
import ProgressDropDown from 'components/Common/ProgressDropDown'
import TaskAttachment from 'components/Page/Attachments/taskAttachment'
import { useRole } from 'hooks/useRole'
import { useLazyGetLogByTaskIdQuery } from 'redux/api/log'
import ListZeroPage from 'components/Common/ListZeroPage'
import LowPriorityIcon from 'components/Icons/tasks/lowPriority'
import MediumPriorityIcon from 'components/Icons/tasks/mediumPriority'
import ImportantPriorityIcon from 'components/Icons/tasks/importantPriority'
import UrgentPriorityIcon from 'components/Icons/tasks/urgentPriority'
import TextEditor from 'components/Common/TextEditor'

function TaskDetailModal({ handler, data, updateList, position = '', isSubtask = false }) {
	const { data: task, isLoading } = useGetTaskApiQuery({ taskId: data.id })
	let [getLogs, {data: logsData}] = useLazyGetLogByTaskIdQuery()
	const isClient = useRole('Client')

	const taskPriorities = [
		{id: 1, label: 'low', icon: <LowPriorityIcon />},
		{id: 2, label: 'medium', icon: <MediumPriorityIcon />},
		{id: 3, label: 'important', icon: <ImportantPriorityIcon />},
		{id: 4, label: 'urgent', icon: <UrgentPriorityIcon />},
	]

	return (
		<ModalContainer maxHeight={true} setShowModal={handler}>
			{!isClient ? 
				<div className='bg-white w-[1090px] h-full '>
				{isLoading && (
					<div className='absolute flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm z-20 top-0 left-0 w-full h-full'>
						<Spinner />
					</div>
				)}

				<div className='bg-primaryBlue bg-opacity-10 px-10 pt-[28px] pb-5'>
					<div className='flex justify-between items-center mb-5'>
						<h6 className='font-medium text-lg'>{task?.name}</h6>
						<button onClick={() => handler()}>
							<XIcon width={15} hight={15} color='#212121' />
						</button>
					</div>
					<div className='flex gap-[10px] h-[163px]'>
							{/* <p className='overflow-hidden overflow-y-scroll bg-white p-4 flex-1 text-sm rounded-[10px] font-light text-gray-700 text-opacity-80'>
								<TextEditor mode='simple'  value={task?.description} height={252}/>
							</p>  */}
						{!task?.description || task?.description?.includes('<') ? (
							<div
								dangerouslySetInnerHTML={{ __html: `${task?.description ?? ''}`}}
								className='bg-white p-4 flex-1 text-sm rounded-[10px] font-light list-disc text-gray-700 text-opacity-80 overflow-h-[163px] overflow-auto'></div>
						) : (
							<p className='bg-white p-4 flex-1 text-sm rounded-[10px] font-light text-gray-700 text-opacity-80'>
								{task?.description ?? ''}
							</p>
						)}

						<div className='bg-white px-5 py-4 text-sm rounded-[10px] flex-shrink-0 w-[516px]'>
							<div className='flex items-center mb-5 justify-between'>
								<div className='flex gap-1 items-center'>
									{task?.teams?.map(item => (
										<DefaultImage
											bgColor={item?.background_color}
											mainColor={item?.foreground_color}
											name={item?.first_name + ' ' + item?.last_name}
											size={40}
											src={item?.avatar}
										/>
									))}
								</div>
								<div className='grid font-light text-[10px] text-gray-400'>
									Created at
									<span>{moment(task?.created_at).format('YYYY-MM-DD')}</span>
								</div>
							</div>
							<div className='flex items-center gap-[5px]'>
								{task && (
									<ProgressDropDown
										bigSize
										parentDetail={data}
										data={task?.progress}
										position='searchResult'
									/>
								)}

								<div className='bg-gray-300 rounded-[10px]  px-3  flex flex-col justify-center items-center gap-4 h-[71px]'>
									<span className='flex items-center gap-1.5 text-xs text-gray-700 text-opacity-80 font-light'>
										<FlagIcon />
										Priority
									</span>
									<span className='font-medium -mt-3 text-opacity-100 text-sm text-gray-700 flex items-center'>
										{task?.priority} {task?.priority && taskPriorities.filter(item => item.label === task?.priority)[0].icon} 
									</span>
								</div>
								<div className='bg-gray-300 rounded-[10px] px-4 flex flex-col justify-center items-center gap-4 h-[71px]'>
									<span className='flex items-center gap-1.5 text-xs text-gray-700 text-opacity-80 font-light'>
										<ClockIcon />
										Due Time
									</span>
									<span className='font-medium -mt-3 text-opacity-100 text-sm text-gray-700 '>
										{moment(task?.due_date).format('YYYY-MM-DD')}
									</span>
								</div>
								<div className='bg-gray-300 rounded-[10px] px-1 flex items-center gap-4 h-[71px]'>
									<div className='grid text-sm text-gray-700 text-right font-[300] w-full'>
										<span className='text-opacity-80 font-[300] text-xs'>Budgeted Hours</span>
										<span className='font-medium '> {task?.time_budget}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='px-10 pt-5 pb-8 flex gap-[10px]'>
					<TaskDetailModalTabContent
						isSubtask={isSubtask}
						modalHandler={handler}
						updateList={updateList}
						data={task}
					/>
					{/* <TaskDetailModalFilter /> */}
					<TaskAttachment data={position === '' ? task : data} position={position}/>
				</div>
			</div>
			:
			<div className='bg-white w-[695px] h-full'>
				<div className='flex flex-col justify-center items-start w-full gap-[20px] px-[40px] py-[30px]'>
					<span className='text-lg font-medium text-gray-4'>{task?.name}</span>
					{task?.description && <span className='border border-solid border-gray-200 bg-gray-300 rounded-[10px] text-base font-light text-gray-4 p-4 text-opacity-80 w-full'>{task?.description.replace(/<\/?[^>]+(>|$)/g, "") }</span>}
					<div className='w-full border border-solid border-gray-200 bg-gray-300 rounded-[10px] text-base font-light text-gray-4 p-4 text-opacity-80 '>
						<div className='flex justify-between items-center w-full mb-[27px]'>
							<span className='text-base font-medium text-gray-4'>{task?.teams?.length} Member(s) assigned </span>
							<span className='flex flex-col items-center w-fit text-xs font-light text-gray-400'>
								<span>Created At</span>
								<span>{task?.created_at.split("T")[0]}</span>
							</span>
						</div>
						<div className='flex justify-between items-center w-full'>
							<div className='w-[134px] rounded-[10px] border border-solid border-red-500 flex flex-col items-start text-red-500 font-light text-sm px-[15px] py-6'>{task?.progress?.name}</div>
							<div className='flex justify-center items-start gap-[46px] w-full'>
								<div className='flex flex-col items-center w-fit text-xs font-light text-gray-4'>
									<span className='flex justify-center items-center gap-[5px] text-gray-400 '><FlagIcon /> Priority</span>
									<span className='text-sm font-medium text-opacity-80 text-gray-4'>{task?.priority}%</span>
								</div>
								<div className='flex flex-col items-center w-fit text-xs font-light text-gray-4'>
									<span className='flex justify-center items-center gap-[5px] text-gray-400 '><ClockIcon /> Due Date</span>
									<span className='text-sm font-medium text-opacity-80 text-gray-4'>{task?.due_date}</span>
								</div>
								<div className='flex flex-col items-center w-fit text-xs font-light text-gray-4'>
									<span className='flex justify-center items-center gap-[5px] font-[300] text-gray-400 text-sm'>Budgeted Hours</span>
									<span className='text-sm font-medium text-opacity-80 text-gray-4'>{task?.time_budget}</span>
								</div>
							</div>
							
						</div>
						
					</div>
					<span className='text-xl font-bold text-gray-4'>Logs</span>
					<div className='flex flex-col items-center justify-center w-full'>
						{logsData && logsData.map(item => (
							<div className='min-w-[556px] min-h-[166px] h-full w-full border border-solid border-gray-200 flex flex-col justify-start items-start rounded-[10px] '>
								<div className='flex justify-between items-center px-[20px] py-[15px] rounded-tl-[10px] rounded-tr-[10px] bg-blue-300 bg-opacity-5 w-full'>
									<span className='text-base font-semibold text-blue-300'>{item.duration}</span>
									<span className='flex items-center gap-[5px] text-sm font-light text-gray-400'>{item.start_datetime}<span>to</span>{item.end_datetime}</span>
								</div>
								<div className='flex items-center gap-[10px] p-[15px]'>
									<span className='text-sm font-light text-gray-4 rounded-[6px] bg-gray-200 p-1'>{item.milestone?.name}</span>
									<span className='text-sm font-light text-gray-4 text-opacity-80 '>{item.description}</span>
								</div>
							</div>
						))}
					</div>
						
				</div>
			</div>
			}
		</ModalContainer>
	)
}

ModalContainer.propTypes = {
	handler: PropTypes.func.isRequired
}
ModalContainer.defaultProps = {
	handler: () => { }
}

export default TaskDetailModal
