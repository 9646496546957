import React from 'react'

const ProjectIcon = ({ width = 18, height = 18 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.59996 10.2246C6.77496 10.2246 7.71662 11.1754 7.71662 12.3588V15.1996C7.71662 16.3746 6.77496 17.3329 5.59996 17.3329H2.78329C1.61663 17.3329 0.666626 16.3746 0.666626 15.1996V12.3588C0.666626 11.1754 1.61663 10.2246 2.78329 10.2246H5.59996ZM15.2167 10.2246C16.3834 10.2246 17.3334 11.1754 17.3334 12.3588V15.1996C17.3334 16.3746 16.3834 17.3329 15.2167 17.3329H12.4C11.225 17.3329 10.2834 16.3746 10.2834 15.1996V12.3588C10.2834 11.1754 11.225 10.2246 12.4 10.2246H15.2167ZM5.59996 0.666504C6.77496 0.666504 7.71662 1.62484 7.71662 2.80067V5.6415C7.71662 6.82484 6.77496 7.77484 5.59996 7.77484H2.78329C1.61663 7.77484 0.666626 6.82484 0.666626 5.6415V2.80067C0.666626 1.62484 1.61663 0.666504 2.78329 0.666504H5.59996ZM15.2167 0.666504C16.3834 0.666504 17.3334 1.62484 17.3334 2.80067V5.6415C17.3334 6.82484 16.3834 7.77484 15.2167 7.77484H12.4C11.225 7.77484 10.2834 6.82484 10.2834 5.6415V2.80067C10.2834 1.62484 11.225 0.666504 12.4 0.666504H15.2167Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(ProjectIcon)
