import React from 'react'

function MicIcon() {
	return (
		<svg
			width='13'
			height='18'
			viewBox='0 0 13 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.50004 11.125C7.95254 11.125 9.11629 9.9525 9.11629 8.5L9.12504 3.25C9.12504 1.7975 7.95254 0.625 6.50004 0.625C5.04754 0.625 3.87504 1.7975 3.87504 3.25V8.5C3.87504 9.9525 5.04754 11.125 6.50004 11.125ZM11.82 8.5C11.4525 8.5 11.1463 8.7625 11.0938 9.12125C10.77 11.405 8.71379 12.9625 6.50004 12.9625C4.28629 12.9625 2.23004 11.4137 1.90629 9.12125C1.87934 8.94805 1.79131 8.7902 1.65811 8.67626C1.52491 8.56232 1.35533 8.4998 1.18004 8.5C0.725045 8.5 0.375045 8.9025 0.436295 9.34875C0.838795 11.9475 3.02629 13.9862 5.62504 14.38V16.375C5.62504 16.8563 6.01879 17.25 6.50004 17.25C6.98129 17.25 7.37504 16.8563 7.37504 16.375V14.38C9.96504 14.0037 12.1613 11.9475 12.5638 9.34875C12.5779 9.24282 12.5692 9.13511 12.5382 9.03282C12.5073 8.93053 12.4549 8.83604 12.3844 8.75567C12.314 8.6753 12.2272 8.61091 12.1299 8.56681C12.0325 8.52272 11.9269 8.49994 11.82 8.5Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(MicIcon)
