import React from 'react'

const PlayIcon = ({ width = 7, height = 9, color = 'white' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 7 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.42175 5.06611L1.25181 8.06586C0.813062 8.32017 0.25 8.01223 0.25 7.49954V1.50004C0.25 0.988169 0.81225 0.679419 1.25181 0.934544L6.42175 3.93429C6.52155 3.99127 6.60452 4.07363 6.66222 4.17301C6.71993 4.2724 6.75032 4.38528 6.75032 4.5002C6.75032 4.61512 6.71993 4.728 6.66222 4.82739C6.60452 4.92677 6.52155 5.00913 6.42175 5.06611Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(PlayIcon)
