import MagnifierIcon from 'components/Icons/MagnifierIcon'
import React from 'react'

const SearchBox = ({ placeholder, onSearch }) => {
    return (
        <div className='w-full relative'>
            <input
                className='h-11 bg-white px-5 w-full placeholder:text-gray-350 placeholder:text-xs pl-[50px] placeholder:text-opacity-50 rounded-[10px]'
                placeholder={placeholder}
                onInput={(e) => onSearch(e.target.value)}
            />
            <span className='absolute text-gray-350 left-4 top-1/2 -translate-y-1/2'>
                <MagnifierIcon />
            </span>
        </div>
    )
}

export default SearchBox
