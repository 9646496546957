import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const progressReportApiRedux = createApi({
	reducerPath: 'progressReportApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		GetProjectProgressReportApi: builder.query({
			query: ({
				projectId,
				milestoneId,
				teamId,
				search,
				type,
				priority,
				sort = ''
			}) => {
				return {
					url: `task/getProgressReport/${projectId}?sort=${sort}`,
					params: {
						milestoneId: milestoneId?.id,
						teamId: teamId?.id,
						search: search,
						type,
						priority
					}
				}
			},
			transformResponse: response => response.data
		}),
		GetProgressItemsApi: builder.query({
			query: () => {
				return {
					url: `progress`
				}
			},
			transformResponse: response => response
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetProjectProgressReportApiQuery,
	useGetProgressItemsApiQuery
} = progressReportApiRedux
