import React from 'react'

function Spinner({ scale = 20, size = 4, color = '#3498db', show = true }) {
	return (
		<>
			{show && (
				<div id='spinner-container'>
					<div
						style={{
							width: scale + 'px',
							height: scale + 'px',
							borderWidth: size + 'px',
							borderTopColor: color
						}}
						id='loading-spinner'></div>
				</div>
			)}
		</>
	)
}

export default Spinner
