import React from 'react'

const TrackingPlayIcon = () => {
	return (
		<svg
			width='21'
			height='21'
			viewBox='0 0 21 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.5 0.0834961C16.2413 0.0834961 20.9167 4.75668 20.9167 10.5062C20.9167 16.2437 16.2413 20.9168 10.5 20.9168C4.75881 20.9168 0.083374 16.2437 0.083374 10.5062C0.083374 4.75668 4.75881 0.0834961 10.5 0.0834961ZM9.31359 6.36465C9.09238 6.36465 8.88124 6.4149 8.68014 6.5154C8.42877 6.6561 8.22768 6.87719 8.11708 7.13849C8.0467 7.31939 7.93609 7.86208 7.93609 7.87213C7.82549 8.46507 7.76516 9.42986 7.76516 10.4951C7.76516 11.5112 7.82549 12.4348 7.91598 13.0378C7.92604 13.0478 8.03664 13.7211 8.1573 13.9523C8.3785 14.3744 8.81085 14.6357 9.27337 14.6357H9.31359C9.61523 14.6256 10.2487 14.3643 10.2487 14.3543C11.3145 13.9121 13.4159 12.5353 14.2605 11.6207L14.3208 11.5604C14.4314 11.4499 14.5722 11.279 14.6024 11.2388C14.7632 11.0278 14.8437 10.7665 14.8437 10.5062C14.8437 10.2137 14.7532 9.9424 14.5822 9.7213C14.542 9.6811 14.3912 9.51025 14.2504 9.36956C13.426 8.48517 11.2743 7.03799 10.1481 6.5958C9.9772 6.52645 9.54485 6.3747 9.31359 6.36465Z'
				fill='#3484F0'
			/>
		</svg>
	)
}

export default TrackingPlayIcon
