import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const teamApiRedux = createApi({
	reducerPath: 'teamApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		GetProjectContactArchiveApi: builder.query({
			query: ({ search, roleId, projectId }) => {
				return {
					url: 'team/getArchivedTeams',
					params: { search, roleId, projectId }
				}
			}
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetProjectContactArchiveApiQuery,
	useLazyGetProjectContactArchiveApiQuery
} = teamApiRedux
