import React from 'react'


function AttachmentIcon(props) {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g id="fluent:attach-16-filled">
				<path id="Vector" d="M3.43499 8.88391L8.02999 4.28791C8.45195 3.86595 9.02425 3.6289 9.62099 3.6289C10.2177 3.6289 10.79 3.86595 11.212 4.28791C11.634 4.70987 11.871 5.28217 11.871 5.87891C11.871 6.47565 11.634 7.04795 11.212 7.46991L5.90999 12.7729C5.84084 12.8446 5.75811 12.9018 5.66663 12.9411C5.57514 12.9805 5.47674 13.0012 5.37715 13.0021C5.27757 13.003 5.1788 12.9841 5.08661 12.9464C4.99442 12.9087 4.91066 12.8531 4.8402 12.7827C4.76975 12.7123 4.71402 12.6286 4.67627 12.5365C4.63851 12.4443 4.61949 12.3456 4.62031 12.246C4.62113 12.1464 4.64177 12.048 4.68103 11.9564C4.7203 11.8649 4.77739 11.7821 4.84899 11.7129L10.152 6.40891C10.2845 6.26674 10.3566 6.07869 10.3532 5.88439C10.3497 5.69009 10.271 5.5047 10.1336 5.36729C9.9962 5.22988 9.81082 5.15117 9.61652 5.14774C9.42221 5.14431 9.23417 5.21643 9.09199 5.34891L3.78799 10.6519C3.36603 11.0739 3.12898 11.6462 3.12898 12.2429C3.12898 12.8397 3.36603 13.412 3.78799 13.8339C4.20995 14.2559 4.78225 14.4929 5.37899 14.4929C5.97573 14.4929 6.54803 14.2559 6.96999 13.8339L12.274 8.53091C12.9773 7.82756 13.3725 6.87361 13.3725 5.87891C13.3725 4.88422 12.9773 3.93027 12.274 3.22691C11.5706 2.52356 10.6167 2.12842 9.62199 2.12842C8.6273 2.12842 7.67335 2.52356 6.96999 3.22691L2.37399 7.82291C2.30233 7.89206 2.24515 7.9748 2.2058 8.06628C2.16645 8.15776 2.14572 8.25617 2.14481 8.35575C2.14389 8.45534 2.16282 8.5541 2.20049 8.64629C2.23816 8.73848 2.29381 8.82225 2.3642 8.8927C2.43458 8.96315 2.51829 9.01888 2.61045 9.05664C2.7026 9.09439 2.80135 9.11341 2.90094 9.1126C3.00052 9.11178 3.09895 9.09113 3.19047 9.05187C3.28199 9.01261 3.36577 8.95551 3.43499 8.88391Z" fill="#3484F0" />
			</g>
		</svg>
	)
}

export default React.memo(AttachmentIcon)
