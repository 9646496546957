import useFetch from 'hooks/useFetch'
const endPoint = '/log'

export const AllLogApi = async router => {
	let response = await useFetch(router, true).get(`${endPoint}`)

	return response
}

export const AddLogApi = async (router, data) => {
	let response = await useFetch(router, true).post(`${endPoint}`, {
		...data
	})

	return response
}

export const EditLogApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/${data?.id}`, {
		...data
	})

	return response
}

export const DeleteLogApi = async (router, id) => {
	let response = await useFetch(router, true).delete(`${endPoint}/${id}`)

	return response
}

export const SetLogApproval = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/setApproval`, { ...data })
	return response
}
