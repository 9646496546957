import React from 'react'

const SingleUserIcon = () => {
	return (
		<svg
			width='12'
			height='16'
			viewBox='0 0 12 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6 10.3804C9.25397 10.3804 12 10.9092 12 12.9492C12 14.99 9.23596 15.5 6 15.5C2.74678 15.5 0 14.9712 0 12.9312C0 10.8905 2.76404 10.3804 6 10.3804ZM6 0.5C8.20433 0.5 9.97049 2.26552 9.97049 4.46829C9.97049 6.67106 8.20433 8.43733 6 8.43733C3.79642 8.43733 2.02951 6.67106 2.02951 4.46829C2.02951 2.26552 3.79642 0.5 6 0.5Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(SingleUserIcon)
