import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const projectAdminApiRedux = createApi({
	reducerPath: 'projectAdApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllProjectsApi: builder.query({
			query: ({  search }) => {
				return {
					url: `project/getProjectList`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetAllProjectsApiQuery,
} = projectAdminApiRedux
