import { Outlet } from 'react-router-dom'
import Header from 'components/Header/Header'
import PropTypes from 'prop-types'
import { Suspense } from 'react'
import { useWindowSize } from 'hooks/useWindowSize'
import Spinner from '../Common/Spinner'

const AccountantLayout = () => {
	let [width] = useWindowSize()

	return (
		<section>
			<Header projectHeader={true} />
			<div className='flex'>
				<div
					style={{
						minHeight: `calc(100vh - 75px)`
					}}
					className='max-w-[1350px] w-full mx-auto xl:px-0 lg:px-6 px-3 pt-5 lg:pb-2 pb-[75px]'>
					<Suspense fallback={<Spinner />}>
						<Outlet />
					</Suspense>
				</div>
			</div>
			{/* <ContentContainer ChildComponent={} /> */}
		</section>
	)
}

AccountantLayout.propTypes = {
	ChildComponent: PropTypes.object.isRequired
}

AccountantLayout.defaultProps = {
	ChildComponent: {}
}

export default AccountantLayout
