import React from 'react'

function DocumentFillIcon() {
	return (
		<svg
			width='17'
			height='19'
			viewBox='0 0 17 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.1671 0.75C14.87 0.75 16.375 2.3075 16.375 4.97625V14.015C16.375 16.7275 14.87 18.25 12.1671 18.25H4.83375C2.17375 18.25 0.625 16.7275 0.625 14.015V4.97625C0.625 2.3075 2.17375 0.75 4.83375 0.75H12.1671ZM5.07 12.7725C4.8075 12.7463 4.55375 12.8688 4.41375 13.0963C4.27375 13.315 4.27375 13.6038 4.41375 13.8313C4.55375 14.05 4.8075 14.1813 5.07 14.1463H11.93C12.2791 14.1113 12.5425 13.8129 12.5425 13.4638C12.5425 13.105 12.2791 12.8075 11.93 12.7725H5.07ZM11.93 8.78163H5.07C4.69287 8.78163 4.3875 9.08875 4.3875 9.465C4.3875 9.84125 4.69287 10.1475 5.07 10.1475H11.93C12.3062 10.1475 12.6125 9.84125 12.6125 9.465C12.6125 9.08875 12.3062 8.78163 11.93 8.78163ZM7.68538 4.81875H5.07V4.8275C4.69287 4.8275 4.3875 5.13375 4.3875 5.51C4.3875 5.88625 4.69287 6.1925 5.07 6.1925H7.68538C8.0625 6.1925 8.36875 5.88625 8.36875 5.50038C8.36875 5.125 8.0625 4.81875 7.68538 4.81875Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(DocumentFillIcon)
