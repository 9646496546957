import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { useWindowSize } from 'hooks/useWindowSize'
import ArrowWithLineIcon from 'components/Icons/ArrowWithLineIcon'
import SideMenuIcon from 'components/Icons/SideMenuIcon'
import { useSelector } from 'react-redux'
import { useRole } from 'hooks/useRole'
import DefaultImage from 'components/Common/DefaultImage'
// import ArrowWithLineIcon from 'components/Icons/ArrowWithLineIcon'

const SideMenu = ({
	toggleSideMenu,
	setToggleSideMenu,
	menuLinks,
	isProject,
	data
}) => {
	const organization = useSelector(state => state.organization)
	const [width] = useWindowSize()
	const isAdmin = useRole('Admin')
	const location = useLocation()
	const { role, name } = useSelector(state => state.user)
	const isActive = (path, nested) => {
		let match = false
		if (nested) {
			match = location.pathname.includes(path)
		} else {
			match = location.pathname === path
		}
		return match ? 'active' : ''
	}
	return (
		<div
			style={{ height: width <= 1288 ? '100vh' : 'calc(100vh - 75px)' }}
			className={`w-[215px] pb-14 h-full bg-gray-300 xl:sticky xl:top-[70px] overflow-auto duration-100 fixed top-0  z-40 flex-shrink-0 mr-5 ${toggleSideMenu ? 'left-0' : '-left-[215px]'
				}`}>
			<Link
				to={'/dashboard'}
				className='flex items-center text-gray-400 text-sm font-medium gap-2 xl:hidden justify-center h-[53px] bg-white '>
				<span>
					<ArrowWithLineIcon width={10} height={9} color='currentColor' />
				</span>
				Back to Home
			</Link>
			<div className=' px-6 mb-4 '>
				<div className='flex items-center py-4 border-b border-gray-200 border-solid'>
					{isProject && !isAdmin ? (
						<Link className='flex items-center' to={'/dashboard/projects'}>
							<DefaultImage
								src={data?.logo}
								bgColor={data?.background_color}
								mainColor={data?.foreground_color}
								size={44}
								type='project'
								iconSize={25}
							/>
							<div className='ml-3'>
								<h1 className='text-gray-700 text-base font-medium'>
									{data?.name}
								</h1>
								<span className='text-[10px] flex -mt-0.5 font-light text-gray-700 text-opacity-40'>
									Change Project
								</span>
							</div>
						</Link>
					) : (
						<div>
							<div className='flex items-center gap-[6px]'>
								<img className='w-[26px] h-[26px]' src={organization?.logo} alt=''/>
								<h1 className='text-gray-700 text-base font-medium'>
									{isAdmin ? name : organization?.name}
								</h1>
							</div>
							<span className='text-[10px] flex -mt-0.5 font-light text-gray-700 text-opacity-40'>
								{isAdmin ? 'Admin' : ''}
							</span>
						</div>
					)}
				</div>
			</div>
			{menuLinks.length &&
				menuLinks.map(({ groupTitle, links, access }) =>
					access.includes('All') || access.includes(role?.name) ? (
						<div className='mb-12 last:mb-0' key={groupTitle}>
							<h3 className='mb-2 px-5 text-[10px] font-semibold text-opacity-20 text-gray-700'>
								{groupTitle}
							</h3>
							<div className=''>
								{links.length &&
									links.map(
										({ title, path, defaultIcon, nested, access }) =>
											access.includes(role?.name) && (
												<Link
													reloadDocument
													key={path}
													to={path}
													className={`px-5 flex relative items-center gap-3 h-12 ${isActive(path, nested)
														? 'text-primaryBlue'
														: 'text-gray-400'
														}`}>
													<span
														className={`w-[18px] -mt-0.5  flex justify-center`}>
														{defaultIcon}
													</span>
													<span>{title}</span>
													{isActive(path, nested) && (
														<i className='h-[30px] bg-primaryBlue absolute right-0 top-1/2 -translate-y-1/2 w-1 rounded-l'></i>
													)}
												</Link>
											)
									)}
							</div>
						</div>
					) : null
				)}

			{toggleSideMenu && (
				<div
					className='fixed xl:hidden top-0 left-[215px] w-full h-full bg-gray-700 bg-opacity-40'
					onClick={() => setToggleSideMenu(false)}>
					<button
						onClick={() => setToggleSideMenu(false)}
						className=' bg-gray-200 w-11 h-11 mt-4 ml-1.5 -rotate-90 flex items-center justify-center rounded-[10px]'>
						<SideMenuIcon />
					</button>
				</div>
			)}
		</div>
	)
}

SideMenu.propTypes = {
	toggleSideMenu: PropTypes.bool.isRequired,
	setToggleSideMenu: PropTypes.func.isRequired,
	menuLinks: PropTypes.array.isRequired,
	isProject: PropTypes.bool.isRequired
}

SideMenu.defaultProps = {
	toggleSideMenu: false,
	isProject: true,
	setToggleSideMenu: () => { },
	menuLinks: []
}

export default SideMenu
