import React from 'react'

function CheckboxIcon({ width = 19, height = 19 }) {
	return (
		<svg
			className='shrink-0'
			width={width}
			height={height}
			viewBox='0 0 19 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.2976 0.75C16.2638 0.75 18.2501 2.8325 18.2501 5.93V13.0796C18.2501 16.1675 16.2638 18.25 13.2976 18.25H5.71131C2.74506 18.25 0.750061 16.1675 0.750061 13.0796V5.93C0.750061 2.8325 2.74506 0.75 5.71131 0.75H13.2976ZM13.1576 6.875C12.8601 6.5775 12.3701 6.5775 12.0726 6.875L8.45881 10.4887L6.92756 8.9575C6.63006 8.66 6.14006 8.66 5.84256 8.9575C5.54506 9.255 5.54506 9.73625 5.84256 10.0425L7.92506 12.1162C8.07381 12.265 8.26631 12.335 8.45881 12.335C8.66006 12.335 8.85256 12.265 9.00131 12.1162L13.1576 7.96C13.4551 7.6625 13.4551 7.18125 13.1576 6.875Z'
				fill={'currentColor'}
			/>
		</svg>
	)
}

export default React.memo(CheckboxIcon)
