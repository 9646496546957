import { configureStore } from '@reduxjs/toolkit'
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { contactApiRedux } from './api/contact'
import { logApiRedux } from './api/log'
import { milestoneApiRedux } from './api/milestone'
import { noteApiRedux } from './api/note'
import { projectApiRedux } from './api/projects'
import { roleApiRedux } from './api/role'
import { taskApiRedux } from './api/task'
import { rootReducer } from './rootReducer'
import { organizationApiRedux } from './api/organization'
import { meetingApiRedux } from './api/meeting'
import { timelineApiRedux } from './api/timeline'
import { reportApiRedux } from './api/report'
import { dashboardApiRedux } from './api/dashboard'
import { progressReportApiRedux } from './api/progressReport'
import { profileApiRedux } from './api/profile'
import { teamApiRedux } from './api/team'
import { clientApiRedux } from './api/client'
import { accountantApiRedux } from './api/accountant'
import { userAdminApiRedux } from './api/admin/user'
import { organizationAdminApiRedux } from './api/admin/organization'
import { projectAdminApiRedux } from './api/admin/project'
import { AttachmentApiRedux } from './api/attachment'

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [
		taskApiRedux.reducerPath,
		logApiRedux.reducerPath,
		projectApiRedux.reducerPath,
		noteApiRedux.reducerPath,
		milestoneApiRedux.reducerPath,
		contactApiRedux.reducerPath,
		roleApiRedux.reducerPath,
		organizationApiRedux.reducerPath,
		meetingApiRedux.reducerPath,
		timelineApiRedux.reducerPath,
		reportApiRedux.reducerPath,
		dashboardApiRedux.reducerPath,
		progressReportApiRedux.reducerPath,
		profileApiRedux.reducerPath,
		teamApiRedux.reducerPath,
		clientApiRedux.reducerPath,
		accountantApiRedux.reducerPath,
		userAdminApiRedux.reducerPath,
		organizationAdminApiRedux.reducerPath,
		projectAdminApiRedux.reducerPath,
		AttachmentApiRedux.reducerPath
	]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		}).concat(
			taskApiRedux.middleware,
			projectApiRedux.middleware,
			logApiRedux.middleware,
			noteApiRedux.middleware,
			milestoneApiRedux.middleware,
			contactApiRedux.middleware,
			roleApiRedux.middleware,
			organizationApiRedux.middleware,
			meetingApiRedux.middleware,
			timelineApiRedux.middleware,
			reportApiRedux.middleware,
			dashboardApiRedux.middleware,
			progressReportApiRedux.middleware,
			profileApiRedux.middleware,
			teamApiRedux.middleware,
			clientApiRedux.middleware,
			accountantApiRedux.middleware,
			accountantApiRedux.middleware,
			userAdminApiRedux.middleware,
			organizationAdminApiRedux.middleware,
			projectAdminApiRedux.middleware,
			AttachmentApiRedux.middleware
		)
})

export let persistor = persistStore(store)
