import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const clientApiRedux = createApi({
	reducerPath: 'clientApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getClientAllProjectsReportApi: builder.query({
			query: ({ dateFilter, search, startDate, endDate }) => {
				return {
					url: `client/allProjectsReport/${
						JSON.parse(localStorage.organization).id
					}`,
					params: { dateFilter, search, startDate, endDate }
				}
			},
			transformResponse: response => response.data
		}),
		getClientProjectReportApi: builder.query({
			query: ({ projectId, dateFilter, search, startDate, endDate }) => {
				return {
					url: `client/projectReport/${projectId}`,
					params: { dateFilter, search, startDate, endDate }
				}
			},
			transformResponse: response => response.data
		}),
		getClientLogsApi: builder.query({
			query: ({ modelName, modelId, milestoneId, dateFilter, endDate, startDate }) => {
				return {
					url: modelName === 'task' ? `client/getLogsByModel/${modelName}/${modelId}?milestoneId=${milestoneId}` : `client/getLogsByModel/${modelName}/${modelId}`,
					params: { dateFilter, endDate, startDate }
				}
			},
			transformResponse: response => response.data
		}),
		getClientProgressReportApi: builder.query({
			query: ({ milestoneId, projectId, search, teamId, sort }) => {
				return {
					url: `client/progressReport/${projectId}?sort=${sort}`,
					params: { search, teamId:teamId?.id, milestoneId: milestoneId?.id }
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetClientAllProjectsReportApiQuery,
	useLazyGetClientProjectReportApiQuery,
	useLazyGetClientLogsApiQuery,
	useLazyGetClientProgressReportApiQuery
} = clientApiRedux
