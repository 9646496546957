import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const contactApiRedux = createApi({
	reducerPath: 'contactApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllContactsApi: builder.query({
			query: ({ search, roleId }) => {
				return {
					url: 'contact',
					params: { search, roleId: roleId?.id }
				}
			},
			transformResponse: response => response.data
		}),
		getProjectContactsApi: builder.query({
			query: ({ projectId, search }) => {
				return {
					url: `team/getTeamsByProjectId/${projectId}`,
					params: { search }
				}
			},
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: `${item.first_name} ${item.last_name}`
				}))
		}),
		getAllTeamsApi: builder.query({
			query: filters => {
				return { url: `team/getTeams`, params: filters }
			},
			transformResponse: response => response.data
		}),
		getProjectArchiveContactsApi: builder.query({
			query: ({ search, roleId, orgId }) => {
				return {
					url: 'contact/getArchivedContacts',
					params: { search, roleId: roleId?.id, orgId: orgId }
				}
			},
			transformResponse: response => response.data
		}),
		getContactAvailabilityApi: builder.query({
			query: contactId => {
				return {
					url: `contact/getAvailability/${contactId}`
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetAllContactsApiQuery,
	useLazyGetAllContactsApiQuery,
	useGetProjectContactsApiQuery,
	useLazyGetProjectContactsApiQuery,
	useGetProjectArchiveContactsApiQuery,
	useLazyGetProjectArchiveContactsApiQuery,
	useGetContactAvailabilityApiQuery,
	useGetAllTeamsApiQuery
} = contactApiRedux
