import PauseIcon from 'components/Icons/PauseIcon'
import PlayIcon from 'components/Icons/PlayIcon'
import React, { useEffect, useRef, useState } from 'react'

const MessageTypeAudio = () => {
	const audioRef = useRef(
		new Audio(
			'https://ts10.tarafdari.com/contents/user326704/content-sound/08_happier.mp3'
		)
	)
	const intervalRef = useRef()

	const [trackProgress, setTrackProgress] = useState(0)
	const [wasPlayed, setWasPlayed] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)
	const [playerWidth, setPlayerWidth] = useState([])
	const playerSize = useRef()

	const { duration } = audioRef.current

	useEffect(() => {
		if (isPlaying) {
			audioRef.current.play()
			startTimer()
		} else {
			clearInterval(intervalRef.current)
			audioRef.current.pause()
		}
	}, [isPlaying])

	useEffect(() => {
		// Pause and clean up on unmount
		let playerCountWidth = (playerSize.current.offsetWidth / 19).toFixed()
		let count = []
		for (let index = 0; index < playerCountWidth; index++) {
			count.push(index)
		}
		setPlayerWidth(count)

		return () => {
			audioRef.current.pause()
			clearInterval(intervalRef.current)
		}
	}, [])

	const startTimer = () => {
		// Clear any timers already running
		clearInterval(intervalRef.current)
		setWasPlayed(true)
		intervalRef.current = setInterval(() => {
			if (audioRef.current.ended) {
				setIsPlaying(false)
				setTrackProgress(0)
			} else {
				setTrackProgress(audioRef.current.currentTime)
			}
		}, [1000])
	}

	const onScrub = value => {
		// Clear any timers already running
		clearInterval(intervalRef.current)
		audioRef.current.currentTime = value
		setTrackProgress(audioRef.current.currentTime)
	}

	const onScrubEnd = () => {
		// If not already playing, start
		if (!isPlaying) {
			setIsPlaying(true)
		}
		startTimer()
	}

	const timeHandler = _time => {
		let minutes = Math.floor(_time / 60)
		let second = _time % 60 < 10 ? `0${_time % 60}` : _time % 60
		if (second) {
			return minutes + ':' + second
		} else {
			return '--:--'
		}
	}

	return (
		<div className='flex items-center -mb-1'>
			<button
				className='w-11 h-11 flex-shrink-0 rounded-full mr-2 bg-primaryBlue flex items-center justify-center'
				onClick={() => setIsPlaying(!isPlaying)}>
				{isPlaying ? <PauseIcon /> : <PlayIcon height={15} width={13} />}
			</button>

			<div ref={playerSize} className='w-full'>
				<div className='relative'>
					<input
						type='range'
						value={trackProgress}
						step='1'
						min='0'
						max={duration ? duration : `${duration}`}
						className='w-full absolute z-[1] cursor-pointer left-0 opacity-0'
						onChange={e => onScrub(e.target.value)}
						onMouseUp={onScrubEnd}
						onKeyUp={onScrubEnd}
					/>

					<div className='relative w-full'>
						<ul
							className={`flex gap-0.5 ${trackProgress !== 0 && 'opacity-50'}`}>
							{playerWidth.map((item, index) => (
								<li key={'audio' + index} className='flex gap-0.5 items-center'>
									<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-2'></i>
									<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-3'></i>
									<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-4'></i>
									<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-3'></i>
									<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-2'></i>
								</li>
							))}
						</ul>
						<ul
							style={{
								width: trackProgress
									? `${(trackProgress * 100) / duration}%`
									: 0
							}}
							className='flex gap-0.5 left-0 top-0 overflow-hidden absolute'>
							{[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
								<li key={'audio' + index} className='flex gap-0.5 items-center'>
									<i className='flex border-l-2 border-solid border-primaryBlue h-2'></i>
									<i className='flex border-l-2 border-solid border-primaryBlue h-3'></i>
									<i className='flex border-l-2 border-solid border-primaryBlue h-4'></i>
									<i className='flex border-l-2 border-solid border-primaryBlue h-3'></i>
									<i className='flex border-l-2 border-solid border-primaryBlue h-2'></i>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className='flex  items-center mt-2'>
					{wasPlayed && (
						<span className='font-medium text-primaryBlue text-[10px] mr-1'>
							{timeHandler(trackProgress.toFixed())}
						</span>
					)}

					<span className='font-light text-gray-700 text-opacity-40 text-[10px]'>
						{wasPlayed && '/ '}
						{timeHandler(duration.toFixed())}
					</span>
					{!isPlaying && !wasPlayed ? (
						<i className='w-[5px] h-[5px] rounded-full bg-primaryBlue ml-1 -mt-0.5'></i>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default MessageTypeAudio
