import React from 'react'

const PauseIcon = ({ color = 'white', width = 15, height = 17 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 15 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3.28125 0.90625C3.95258 0.90625 4.59641 1.17293 5.07111 1.64764C5.54582 2.12234 5.8125 2.76617 5.8125 3.4375V13.5625C5.8125 14.2338 5.54582 14.8777 5.07111 15.3524C4.59641 15.8271 3.95258 16.0938 3.28125 16.0938C2.60992 16.0938 1.96609 15.8271 1.49139 15.3524C1.01668 14.8777 0.75 14.2338 0.75 13.5625V3.4375C0.75 2.76617 1.01668 2.12234 1.49139 1.64764C1.96609 1.17293 2.60992 0.90625 3.28125 0.90625ZM11.7188 0.90625C12.3901 0.90625 13.0339 1.17293 13.5086 1.64764C13.9833 2.12234 14.25 2.76617 14.25 3.4375V13.5625C14.25 14.2338 13.9833 14.8777 13.5086 15.3524C13.0339 15.8271 12.3901 16.0938 11.7188 16.0938C11.0474 16.0938 10.4036 15.8271 9.92889 15.3524C9.45418 14.8777 9.1875 14.2338 9.1875 13.5625V3.4375C9.1875 2.76617 9.45418 2.12234 9.92889 1.64764C10.4036 1.17293 11.0474 0.90625 11.7188 0.90625Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(PauseIcon)
