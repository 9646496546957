import AccountantLayout from 'components/Common/AccountantLayout'
import PanelLayoutWrapper from 'components/Common/PanelLayoutWrapper'
import ProjectLayout from 'components/Common/ProjectLayout/ProjectLayout'
import Loading from 'components/Loading/Loading'
import Transfer from 'pages/Project/transfer'
import TermsOfService from 'pages/termsOfService'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const Login = lazy(() => import('pages/auth/Login/Login'))
const PressRelease = lazy(() => import('pages/PressRelease'))
const ForgetPassword = lazy(() =>
	import('pages/auth/ForgetPassword/ForgetPassword')
)
const ResetPassword = lazy(() =>
	import('pages/auth/ResetPassword/ResetPassword')
)
const NotFound = lazy(() => import('pages/NotFound/NotFound'))
// Profile
const ProfileReport = lazy(() => import('./pages/Profile/ProfileReport'))
// organization
const Organization = lazy(() => import('./pages/organization/organization'))
const CreateOrganization = lazy(() => import('./pages/organization/create'))
// aggregate
const AggregateLayout = lazy(() =>
	import('components/Common/AggregateLayout/AggregateLayout')
)
const AggregateCalendar = lazy(() =>
	import('pages/Aggregate/AggregateCalendar')
)
const AggregateCreateProjectAsTemplate = lazy(() =>
	import('pages/Aggregate/AggregateProject/AggregateCreateProjectAsTemplate')
)
const Profile = lazy(() => import('pages/Aggregate/Profile'))
const AggregateConversations = lazy(() =>
	import('pages/Aggregate/AggregateConversations')
)
const AdminAggregateOrganizations = lazy(() =>
	import('pages/Admin/Organization/AggregateOrganizationsContainer')
)
const AdminAggregateViewOrganization = lazy(() =>
	import('pages/Admin/Organization/View/AggregateViewOrganizationContainer')
)

const AdminAggregateProjects = lazy(() =>
	import('pages/Admin/Project/AggregateProjectsContainer')
)
// const AdminAggregateViewProject = lazy(() => import('pages/Admin/Project/View/AggregateViewProjectContainer'))

const AdminAggregateUsers = lazy(() =>
	import('pages/Admin/User/AggregateUsersContainer')
)
const AdminAggregateViewUser = lazy(() =>
	import('pages/Admin/User/View/AggregateViewUserContainer')
)

const AggregateSetting = lazy(() => import('pages/Aggregate/AggregateSetting'))
const AggregateLog = lazy(() => import('pages/Aggregate/AggregateLog'))
const AggregateNote = lazy(() => import('pages/Aggregate/AggregateNote'))
const AggregateProjects = lazy(() =>
	import('pages/Aggregate/AggregateProject/AggregateProjects')
)
const AggregateAddProject = lazy(() =>
	import('pages/Aggregate/AggregateProject/AggregateAddProject')
)
const AggregateCreateProject = lazy(() =>
	import('pages/Aggregate/AggregateProject/AggregateCreateProject')
)
const AggregateTasks = lazy(() => import('pages/Aggregate/AggregateTasks'))
const AggregateTeamReport = lazy(() =>
	import('pages/Aggregate/AggregateTeamReport')
)
const AggregateTimeline = lazy(() =>
	import('pages/Aggregate/AggregateTimeline')
)
const AggregateDashboard = lazy(() => import('pages/Aggregate/Dashboard'))
const AggregateClientReport = lazy(() => import('pages/Client/ClientReport'))
// Accountant
const AccountantReport = lazy(() => import('pages/Accountant/AccountantReport'))
// project
const ProjectAttachments = lazy(() =>
	import('pages/Project/ProjectAttachments')
)
const ProjectCalendar = lazy(() => import('pages/Project/ProjectCalendar'))
const ProjectConversations = lazy(() =>
	import('pages/Project/ProjectConversations')
)
const ProjectLogs = lazy(() => import('pages/Project/ProjectLogs'))
const ProjectMeeting = lazy(() => import('pages/Project/ProjectMeeting'))
const ProjectMilestones = lazy(() => import('pages/Project/ProjectMilestones'))
const ProjectNotes = lazy(() => import('pages/Project/ProjectNotes'))
const ProjectProgressReport = lazy(() =>
	import('pages/Project/ProjectProgressReport')
)
const ProjectReport = lazy(() => import('pages/Project/ProjectReport'))
const ProjectSetting = lazy(() => import('pages/Project/ProjectSetting'))
const AddContactToTeam = lazy(() =>
	import('pages/Project/ProjectTeam/AddToTeams/AddToTeams')
)
const ProjectTeam = lazy(() => import('pages/Project/ProjectTeam/ProjectTeam'))
const ProjectTimeline = lazy(() => import('pages/Project/ProjectTimeline'))
const ProjectDashboard = lazy(() => import('pages/Project/Dashboard'))
const ProjectTasks = lazy(() => import('pages/Project/ProjectTasks'))
const ProjectAvailability = lazy(() =>
	import('pages/Project/ProjectAvailability')
)
const App = () => (
	<Suspense fallback={<Loading />}>
		<ToastContainer
			position='bottom-center'
			autoClose={3000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme='colored'
		/>
		<Routes>
			<Route path='/' element={<Navigate to='/login' />} />
			<Route path='login' element={<Login />} />
			<Route path='press-release' element={<PressRelease />} />
			<Route path='terms-of-service' element={<TermsOfService />} />
			<Route path='forgetPassword' element={<ForgetPassword />} />
			<Route path='/ResetPassword/:token' element={<ResetPassword />} />
			<Route path='/space' element={<Organization />} />
			<Route path='/space/create' element={<CreateOrganization />} />

			<Route element={<PanelLayoutWrapper />}>
				<Route path='dashboard' element={<AggregateLayout />}>
					<Route index element={<AggregateDashboard />} />
					<Route path='calendar' element={<AggregateCalendar />} />
					<Route path='conversations' element={<AggregateConversations />} />
					<Route path='logs' element={<AggregateLog />} />
					<Route path='notes' element={<AggregateNote />} />
					<Route path='projects'>
						<Route index element={<AggregateProjects />} />
						<Route path='add-project' element={<AggregateAddProject />} />{' '}
						<Route path='create-project' element={<AggregateCreateProject />} />{' '}
						<Route
							path='create-project-as-template/:projectId'
							element={<AggregateCreateProjectAsTemplate />}
						/>
					</Route>
					<Route path='tasks' exact element={<AggregateTasks />} />
					<Route path='team-report' element={<AggregateTeamReport />} />{' '}
					<Route path='project-report' element={<AggregateClientReport />} />
					<Route path='timeline' element={<AggregateTimeline />} />
					<Route path='setting' element={<AggregateSetting />} />
					<Route path='profile'>
						<Route index element={<Profile />} />
						<Route path='report' element={<ProfileReport />} />
					</Route>
					<Route path='user-tasks' exact element={<AggregateTasks />} />
				</Route>

				<Route path='accountant-report' element={<AccountantLayout />}>
					<Route index element={<AccountantReport />} />
				</Route>

				<Route path='progress/:projectId' element={<ProjectLayout />}>
					<Route index element={<ProjectDashboard />} />
					<Route path='notes' element={<ProjectNotes />} />
					<Route path='conversations' element={<ProjectConversations />} />
					<Route path='milestones' element={<ProjectMilestones />} />
					<Route path='tasks' element={<ProjectTasks />} />
					<Route path='timeline' element={<ProjectTimeline />} />
					<Route path='progress' element={<ProjectProgressReport />} />
					<Route path='calendar' element={<ProjectCalendar />} />
					<Route path='logs' element={<ProjectLogs />} />
					<Route path='attachments' element={<ProjectAttachments />} />
					<Route path='settings' element={<ProjectSetting />} />
					<Route path='meeting' element={<ProjectMeeting />} />
					<Route path='report' element={<ProjectReport />} />
					<Route path='team' element={<ProjectTeam />} />
					<Route path='availability' element={<ProjectAvailability />} />
					<Route
						path='team/add-contact-to-team'
						element={<AddContactToTeam />}
					/>
				</Route>
				<Route path='accountant-report' element={<ProjectLayout />}>
					<Route index element={<ProjectDashboard />} />
				</Route>
				<Route path='admin' element={<AggregateLayout />}>
					<Route path='users' element={<AdminAggregateUsers />} />
					<Route path='users/:userId' element={<AdminAggregateViewUser />} />

					<Route path='projects' element={<AdminAggregateProjects />} />
					{/* <Route path='projects/:projectId' element={<AdminAggregateViewProject />} /> */}

					<Route path='spaces' element={<AdminAggregateOrganizations />} />
					<Route
						path='spaces/:organizationId'
						element={<AdminAggregateViewOrganization />}
					/>
				</Route>
				<Route path='transfer/:id' element={<Transfer />} />
			</Route>

			<Route path='*' element={<NotFound />} />
		</Routes>
	</Suspense>
)

export default App
