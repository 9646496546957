import React from 'react'

function ClockFillIcon({ width = 19, height = 19 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 19 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.5 0.75C14.3387 0.75 18.25 4.67 18.25 9.5C18.25 14.3387 14.3387 18.25 9.5 18.25C4.67 18.25 0.75 14.3387 0.75 9.5C0.75 4.67 4.67 0.75 9.5 0.75ZM9.19375 5.06375C8.835 5.06375 8.5375 5.3525 8.5375 5.72V10.1388C8.5375 10.3663 8.66 10.5763 8.86125 10.6987L12.2912 12.7463C12.3962 12.8075 12.51 12.8425 12.6325 12.8425C12.8512 12.8425 13.07 12.7288 13.1925 12.5188C13.3763 12.2125 13.28 11.81 12.965 11.6175L9.85 9.7625V5.72C9.85 5.3525 9.5525 5.06375 9.19375 5.06375Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(ClockFillIcon)
