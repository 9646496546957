import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selectedLogs: [],
}

const Log = createSlice({
	name: 'Log',
	initialState,
	reducers: {
		setLogIds: (state, { payload }) => ({ selectedLogs: payload }),
	}
})

export const { setLogIds } = Log.actions
export default Log.reducer