import React from 'react'

const PlusStrokeIcon = () => {
	return (
		<svg
			width='22'
			height='22'
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.6522 9.326V6.974C12.6522 6.05379 11.9095 5.311 10.9892 5.311C10.069 5.311 9.32624 6.05379 9.32624 6.974V9.326H6.96324C6.51523 9.326 6.10458 9.50851 5.80129 9.79285L5.78364 9.80939L5.76709 9.82704C5.48309 10.13 5.30024 10.5394 5.30024 10.989C5.30024 11.9122 6.04261 12.6432 6.94694 12.6628L6.95509 12.663H6.96324H9.32624V15.026C9.32624 15.9462 10.069 16.689 10.9892 16.689C11.9095 16.689 12.6522 15.9462 12.6522 15.026V12.663H15.0262H15.0344L15.0425 12.6628C15.9469 12.6432 16.6892 11.9122 16.6892 10.989C16.6892 10.0688 15.9465 9.326 15.0262 9.326H12.6522ZM16.1262 0.75C17.8575 0.75 19.1134 1.23653 19.9365 2.0563C20.7592 2.87558 21.2502 4.12782 21.2502 5.863V16.137C21.2502 17.8612 20.7619 19.1145 19.9405 19.9369C19.1194 20.7592 17.8664 21.25 16.1372 21.25H5.86324C4.12805 21.25 2.87556 20.7589 2.05618 19.9373C1.23645 19.1153 0.750244 17.8622 0.750244 16.137V5.863C0.750244 4.12574 1.23699 2.87366 2.05545 2.05521C2.8739 1.23675 4.12598 0.75 5.86324 0.75H16.1262Z'
				stroke='currentColor'
				stroke-width='1.5'
			/>
		</svg>
	)
}

export default React.memo(PlusStrokeIcon)
