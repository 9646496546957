import DocumentIconV2 from 'components/Icons/DocumentIconV2'
import React from 'react'

const MessageTypeFile = () => {
	return (
		<div className='flex items-center px-5 py-4 bg-gray-300 rounded'>
			<spna className={"text-gray-700 text-opacity-60"}>
            <DocumentIconV2 />
            </spna>

			<span className='text-black text-sm font-semibold ml-4'>Doc 1 name</span>
		</div>
	)
}

export default MessageTypeFile
