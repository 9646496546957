import React from 'react'

const CheckBoxStrokeIcon = ({ size }) => {
	return (
		<svg
			className='shrink-0'
			width={size}
			height={size}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.4937 5.40161L11.4937 5.40157L11.4886 5.39645C11.0384 4.94618 10.3018 4.94618 9.85151 5.39645L7.10756 8.14039L6.14861 7.18145C5.69835 6.73118 4.96177 6.73118 4.51151 7.18145C4.05847 7.63449 4.06572 8.35973 4.50642 8.81339L4.50637 8.81344L4.51225 8.8193L6.29651 10.5961C6.29657 10.5961 6.29663 10.5962 6.2967 10.5962C6.52509 10.8245 6.82051 10.93 7.10756 10.93C7.39913 10.93 7.69568 10.8265 7.92611 10.5961L11.4886 7.03355C11.9417 6.58051 11.9344 5.85527 11.4937 5.40161ZM11.2551 1C12.4048 1 13.3303 1.40079 13.9695 2.06978C14.6106 2.74079 15.0001 3.72027 15.0001 4.94V11.0682C15.0001 12.2835 14.6108 13.2609 13.9697 13.931C13.3305 14.5992 12.4049 15 11.2551 15H4.75256C3.60268 15 2.67493 14.5991 2.03373 13.9306C1.39078 13.2602 1.00006 12.2828 1.00006 11.0682V4.94C1.00006 3.72088 1.39103 2.74147 2.03396 2.07023C2.67509 1.40087 3.6027 1 4.75256 1H11.2551Z'
				stroke='currentColor'
			/>
		</svg>
	)
}

export default React.memo(CheckBoxStrokeIcon)
