import DefaultImage from 'components/Common/DefaultImage'
import ArrowIcon from 'components/Icons/ArrowIcon'
import LogoutIcon from 'components/Icons/LogoutIcon'
import TeamReportIcon from 'components/Icons/Sidebar/TeamReportIcon'
import SingleUserIcon from 'components/Icons/SingleUserIcon'
import WalletIcon from 'components/Icons/WalletIcon'
import { useRole } from 'hooks/useRole'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { DropdownOutsideClick } from 'utils/DropdownOutsideClick'

const HeaderUserDropdown = () => {
	const isClient = useRole('Client')
	const isAccountant = useRole('Accountant')

	const { avatar, name, background_color, foreground_color } = useSelector(
		state => state.user
	)
	const navigate = useNavigate()

	const [dropDownStatus, setDropDownStatus] = useState(false)
	const dropdownRef = useRef()

	const logout = () => {
		localStorage.removeItem('token')
		localStorage.removeItem('organization')
		navigate('/')
	}

	useEffect(() => {
		document.addEventListener('click', e =>
			DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
		)
		return () =>
			document.removeEventListener('click', e =>
				DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
			)
	}, [])

	return (
		<li ref={dropdownRef} className={`lg:pl-4 pl-0 !border-0`}>
			<div className=' relative'>
				<button
					onClick={() => setDropDownStatus(!dropDownStatus)}
					className='w-11 rounded-[10px] overflow-hidden relative h-11 '>
					<span className='absolute left-0 bottom-0  w-4 h-4 rounded-full border border-primaryBlue border-solid flex items-center justify-center bg-white text-primaryBlue'>
						<ArrowIcon color={'currentColor'} />
					</span>
					<DefaultImage
						bgColor={background_color}
						mainColor={foreground_color}
						size={44}
						src={avatar}
						name={name}
					/>
				</button>
				{dropDownStatus && (
					<div className='pt-4 mb-2 absolute  right-0 lg:top-full bottom-full lg:bottom-0'>
						<ul className='bg-white shadow-custom rounded-[10px] min-w-[214px] p-4'>
							{!isClient && !isAccountant ? (
								<li className='p-4 first:pt-0 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
									<Link
										to={'/dashboard/profile/report'}
										className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
										<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
											<TeamReportIcon />
										</span>
										Report
									</Link>
								</li>
							) : null}

							<li className='p-4 first:pt-0  pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
								<Link
									to={'/dashboard/profile'}
									className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
									<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
										<SingleUserIcon />
									</span>
									Profile
								</Link>
							</li>
							{/* <li className='p-4 pl-1 border-b  border-solid border-black border-opacity-10 last:!border-none'>
								<Link
									to={'/dashboard/payment'}
									className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
									<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
										<WalletIcon />
									</span>
									Payment
								</Link>
							</li> */}
							<li className='p-4 first:pt-0 pl-1 pb-0 border-b  border-solid border-black border-opacity-10 last:!border-none'>
								<button
									onClick={logout}
									className='text-base group/item hover:text-primaryBlue text-gray-700 gap-4 font-medium flex items-center'>
									<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
										<LogoutIcon />
									</span>
									Log out
								</button>
							</li>
						</ul>
					</div>
				)}
			</div>
		</li>
	)
}

export default HeaderUserDropdown
