import { useEffect, useRef, useState } from 'react'

const useRecorder = () => {
	const voiceIntervalTime = useRef()
	const [audioURL, setAudioURL] = useState(null)
	const [isRecording, setIsRecording] = useState(false)
	const [recorder, setRecorder] = useState(null)
	const [time, setTime] = useState(0)

	useEffect(() => {
		// Lazily obtain recorder first time we're recording.
		if (recorder === null) {
			if (isRecording) {
				requestRecorder().then(setRecorder, console.error)
			}
			return
		}

		// Manage recorder state.
		if (isRecording) {
			setAudioURL(null)
			recorder.start()
			setTime(0)
			clearInterval(voiceIntervalTime.current)
			voiceIntervalTime.current = setInterval(() => {
				setTime(state => state + 1)
			}, [1000])
		} else {
			clearInterval(voiceIntervalTime.current)
			if (time === 0) {
				setTimeout(() => {
					clearAudio()
					recorder.stop()
				}, 1000)
			} else {
				recorder.stop()
			}
		}

		// Obtain the audio when ready.
		const handleData = e => {
			setAudioURL(URL.createObjectURL(e.data))
		}

		recorder.addEventListener('dataavailable', handleData)
		return () => recorder.removeEventListener('dataavailable', handleData)
	}, [recorder, isRecording])

	const startRecording = () => {
		setIsRecording(true)
	}

	const stopRecording = () => {
		setIsRecording(false)
	}
	const clearAudio = () => {
		setAudioURL(null)
	}

	return [
		audioURL,
		isRecording,
		startRecording,
		stopRecording,
		clearAudio,
		time
	]
}

async function requestRecorder() {
	const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
	return new MediaRecorder(stream)
}
export default useRecorder
