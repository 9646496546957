import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	name: 'admin'
}

const admin = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		updateAdmin: (state, { payload }) => ({ name: payload })
	}
})

export const { updateAdmin } = admin.actions
export default admin.reducer
