import Spinner from 'components/Common/Spinner'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { JoinToProjectApi } from 'services/team'

const NotificationProjectItem = ({ data, updateList }) => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	const joinToProject = async _status => {
		setLoading(true)
		try {
			let response = await JoinToProjectApi(navigate, {
				project_id: data.id,
				selection: _status ? 0 : 2
			})
			await updateList()
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		// data && console.log('data', data);
	},  [data])

	return (
		<div className='flex gap-4 p-4 relative'>
			{data.pending === 1 && (
				<i className='absolute  top-2 left-2 bg-primaryBlue w-2 h-2 rounded-full flex'></i>
			)}
			{loading && (
				<div className='flex w-full h-full absolute  items-center justify-center left-0 top-0 bg-black bg-opacity-20 backdrop-blur-sm'>
					<Spinner show={true} scale={25} size={3} />
				</div>
			)}

			<img className='w-8 h-8 rounded-full shrink-0' src={data?.logo || 'https://prod-api.teamup.space/storage/images/L2_9_05_01_2023-074743.png'} alt=''/>
			<div>
				<h6 className='text-gray-700 text-sm font-medium mb-4'>
					{data.pending ? (
						<>
							You have been invited to <b>“{data?.name}”</b> Project. Do you
							want to join to project?
						</>
					) : (
						<>
							You had invitation from <b>“{data?.name}”</b> Project.
						</>
					)}
				</h6>
				{data.pending === 1 && (
					<div className='flex gap-2 mb-2'>
						<button
							onClick={() => joinToProject(true)}
							className='h-7 rounded-[4px] border border-primaryBlue bg-primaryBlue text-white border-solid text-sm font-medium px-2 flex items-center justify-center'>
							Accept
						</button>
						<button
							onClick={() => joinToProject(false)}
							className='h-7 rounded-[4px] border border-gray-200 text-gray-700 border-solid text-sm font-medium px-2 flex items-center justify-center'>
							Reject
						</button>
					</div>
				)}

				<div className='text-sm font-medium text-gray-400'>
					{moment(data?.created_at).format('dddd MMM YYYY HH:mm A')}
				</div>
			</div>
		</div>
	)
}

export default NotificationProjectItem
