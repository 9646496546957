import React from 'react'

function FlagIcon() {
	return (
		<svg
			width='12'
			height='13'
			viewBox='0 0 12 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.0071 0.0690625C11.0626 0.106143 11.108 0.1563 11.1395 0.215106C11.1709 0.273912 11.1874 0.339559 11.1875 0.40625V6.5C11.1875 6.58113 11.1632 6.6604 11.1177 6.72759C11.0722 6.79478 11.0077 6.84682 10.9324 6.877L10.7812 6.5L10.9324 6.877L10.9299 6.87781L10.9251 6.88025L10.9064 6.88756C10.7996 6.93002 10.692 6.97065 10.5838 7.00944C10.3693 7.08663 10.0711 7.19063 9.73312 7.29381C9.07012 7.49856 8.20644 7.71875 7.53125 7.71875C6.84306 7.71875 6.2735 7.49125 5.77787 7.29219L5.75513 7.28406C5.24 7.07688 4.80125 6.90625 4.28125 6.90625C3.7125 6.90625 2.95038 7.09313 2.30119 7.29381C2.01052 7.3844 1.72251 7.4833 1.4375 7.59037V12.5938C1.4375 12.7015 1.3947 12.8048 1.31851 12.881C1.24233 12.9572 1.13899 13 1.03125 13C0.923506 13 0.820175 12.9572 0.743988 12.881C0.667801 12.8048 0.625 12.7015 0.625 12.5938V0.40625C0.625 0.298506 0.667801 0.195175 0.743988 0.118988C0.820175 0.0428012 0.923506 0 1.03125 0C1.13899 0 1.24233 0.0428012 1.31851 0.118988C1.3947 0.195175 1.4375 0.298506 1.4375 0.40625V0.635375C1.62112 0.571187 1.8405 0.49725 2.07938 0.424125C2.74237 0.221 3.60687 0 4.28125 0C4.96375 0 5.5195 0.225062 6.00456 0.421687L6.0395 0.436312C6.54487 0.64025 6.98525 0.8125 7.53125 0.8125C8.1 0.8125 8.86213 0.625625 9.51131 0.424937C9.88125 0.30941 10.2469 0.180407 10.6074 0.0381875L10.6228 0.0325L10.6261 0.030875H10.6269'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(FlagIcon)
