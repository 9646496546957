import TeamupLogo from 'assets/images/teamup-space-logo.png'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TrackingAction from './TrackingAction'
import SideMenuIcon from 'components/Icons/SideMenuIcon'
import UIcon from 'components/Icons/UIcon'
import HeaderSearchBox from './HeaderSearchBox'
import { useRole } from 'hooks/useRole'
import MainLogo from 'components/Icons/MainLogo'

const Header = ({ toggleSideMenu, setToggleSideMenu, projectHeader, data }) => {
	const isAdmin = useRole('Admin')
	const isAccountant = useRole('Accountant')
	return (
		<div className='h-[75px] flex items-center  pl-3  lg:pr-5 pr-3 border-b border-gray-200 border-solid bg-white sticky top-0 z-50'>
			<div className='flex items-center gap-4 justify-between w-full'>
				<div className='flex gap-1 xl:hidden'>
					<button
						onClick={() => setToggleSideMenu(!toggleSideMenu)}
						className='w-11 h-11  items-center xl:hidden flex justify-center bg-gray-200 rounded-[10px]'>
						<SideMenuIcon />
					</button>
					{projectHeader && (
						<Link
							to={'/dashboard'}
							className='w-11 h-11  items-center xl:hidden flex justify-center bg-gray-200 rounded-[10px]'>
							<UIcon />
						</Link>
					)}
				</div>
				{}

				<div className='flex items-center justify-center min-w-[211px] mr-2'>
					{projectHeader ? (
						<>
							<div className='flex xl:hidden items-end gap-2'>
								{/* <img
									className='w-[37px] h-[37px] mr-2'
									// src='http://localhost:3000/static/media/project-logo.931decf034042105ed606661046dd17b.svg'
									src='../../assets/images/TUS-logo.png'
									alt='tus logo'
								/> */}
								<div>
									<h2 className='text-gray-700 whitespace-nowrap text-sm font-medium'>
										Noble Pixels
									</h2>
									<Link
										className='text-[10px] flex whitespace-nowrap -mt-0.5 font-light text-gray-700 text-opacity-40'
										to={'/dashboard/projects'}>
										Change Project
									</Link>
								</div>
							</div>
							<Link
								to={`${isAccountant ? window.location : '/dashboard'}`}
								className='xl:inline-block hidden'>
								<MainLogo />
							</Link>
						</>
					) : (
						<Link
							to={`${isAdmin || isAccountant ? window.location : '/dashboard'}`}
							className=''>
							<MainLogo />
						</Link>
					)}
				</div>

				<HeaderSearchBox />

				<TrackingAction data={data} />
			</div>
		</div>
	)
}

Header.propTypes = {
	toggleSideMenu: PropTypes.bool.isRequired,
	setToggleSideMenu: PropTypes.func.isRequired,
	projectHeader: PropTypes.bool.isRequired
}

Header.defaultProps = {
	toggleSideMenu: false,
	projectHeader: false,
	setToggleSideMenu: () => {}
}

export default Header
