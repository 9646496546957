import React from 'react'

const PlusBoxIcon = ({ color = '#3484F0', width = 21, height = 21 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 21 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15.3932 0C18.9632 0 21.0002 2.016 21.0002 5.5965V15.4035C21.0002 18.963 18.9737 21 15.4037 21H5.59674C2.01624 21 0.000244141 18.963 0.000244141 15.4035V5.5965C0.000244141 2.016 2.01624 0 5.59674 0H15.3932ZM10.4897 5.7855C10.0067 5.7855 9.61824 6.174 9.61824 6.657V9.618H6.64674C6.41574 9.618 6.19524 9.7125 6.02724 9.87C5.86974 10.038 5.77524 10.2574 5.77524 10.4895C5.77524 10.9725 6.16374 11.361 6.64674 11.3715H9.61824V14.343C9.61824 14.826 10.0067 15.2145 10.4897 15.2145C10.9727 15.2145 11.3612 14.826 11.3612 14.343V11.3715H14.3432C14.8262 11.361 15.2147 10.9725 15.2147 10.4895C15.2147 10.0065 14.8262 9.618 14.3432 9.618H11.3612V6.657C11.3612 6.174 10.9727 5.7855 10.4897 5.7855Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(PlusBoxIcon)
