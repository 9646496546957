import React from 'react'

const WalletIcon = () => {
	return (
		<svg
			width='16'
			height='14'
			viewBox='0 0 16 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.3867 0.25C13.9733 0.25 15.5 1.73844 15.5 4.28635H12.3267V4.31235C10.8539 4.31235 9.66 5.47637 9.66 6.91225C9.66 8.34814 10.8539 9.51216 12.3267 9.51216H15.5V9.74615C15.5 12.2616 13.9733 13.75 11.3867 13.75H4.61333C2.02667 13.75 0.5 12.2616 0.5 9.74615V4.25385C0.5 1.73844 2.02667 0.25 4.61333 0.25H11.3867ZM14.94 5.40431C15.2493 5.40431 15.5 5.64875 15.5 5.95029V7.84822C15.4964 8.14829 15.2478 8.39069 14.94 8.3942H12.3867C11.6411 8.40398 10.9891 7.90628 10.82 7.19824C10.7353 6.75872 10.8542 6.30517 11.1448 5.95916C11.4354 5.61315 11.868 5.41006 12.3267 5.40431H14.94ZM12.6867 6.28178H12.44C12.2886 6.28004 12.1427 6.33748 12.035 6.44128C11.9273 6.54508 11.8667 6.6866 11.8667 6.83426C11.8666 7.14404 12.1223 7.39617 12.44 7.39973H12.6867C13.0033 7.39973 13.26 7.14947 13.26 6.84076C13.26 6.53204 13.0033 6.28178 12.6867 6.28178ZM8.28667 3.16839H4.05333C3.73928 3.16837 3.48365 3.4147 3.48 3.72087C3.48 4.03066 3.73561 4.28279 4.05333 4.28635H8.28667C8.60331 4.28635 8.86 4.03609 8.86 3.72737C8.86 3.41866 8.60331 3.16839 8.28667 3.16839Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(WalletIcon)
