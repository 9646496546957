import Spinner from 'components/Common/Spinner'
import DownloadIcon from 'components/Icons/DownloadIcon'
import React from 'react'

const TaskAttachmentItem = ({ item, onDownloadFile, loading, id }) => {
    return (
        <div className='flex justify-between items-center border-b border-solid border-300a py-5 gap-3 w-full'>
            <div className='flex items-center justify-center border border-solid border-blue-300 rounded-[5px] max-w-[26px] min-h-[35px] max-h-[35px] w-full h-full bg-white text-[10px] font-light text-blue-300'>
                {item.type}
            </div>
            <div className='flex flex-col items-start justify-start text-sm font-medium text-gray-700 truncate w-full'>
                {item.file_name}
                <span className='text-sm font-light text-gray-700a'>{(item.size / 1000).toFixed(2)} {`${(item.size / 1000).toFixed(2) > 1000 ? 'MB' : 'KB'}`}</span>
            </div>
            {loading && id === item.id ? <Spinner show={loading}/> : <DownloadIcon className='text-gray-700b cursor-pointer' onClick={() => onDownloadFile(item)} />}
        </div>
    )
}

export default TaskAttachmentItem