import React from 'react'

const LinkIcon = () => {
	return (
		<svg
			width='20'
			height='10'
			viewBox='0 0 20 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.5833 9.58317H11.8333V7.74984H14.5833C15.3127 7.74984 16.0121 7.46011 16.5279 6.94438C17.0436 6.42866 17.3333 5.72918 17.3333 4.99984C17.3333 4.27049 17.0436 3.57102 16.5279 3.05529C16.0121 2.53957 15.3127 2.24984 14.5833 2.24984H11.8333V0.416504H14.5833C15.7989 0.416504 16.9647 0.899389 17.8242 1.75893C18.6838 2.61847 19.1667 3.78426 19.1667 4.99984C19.1667 6.21541 18.6838 7.3812 17.8242 8.24074C16.9647 9.10028 15.7989 9.58317 14.5833 9.58317ZM8.16666 9.58317H5.41666C4.20109 9.58317 3.0353 9.10028 2.17576 8.24074C1.31621 7.3812 0.833328 6.21541 0.833328 4.99984C0.833328 3.78426 1.31621 2.61847 2.17576 1.75893C3.0353 0.899389 4.20109 0.416504 5.41666 0.416504H8.16666V2.24984H5.41666C4.68732 2.24984 3.98784 2.53957 3.47212 3.05529C2.95639 3.57102 2.66666 4.27049 2.66666 4.99984C2.66666 5.72918 2.95639 6.42866 3.47212 6.94438C3.98784 7.46011 4.68732 7.74984 5.41666 7.74984H8.16666V9.58317ZM14.5833 5.9165H5.41666V4.08317H14.5833V5.9165Z'
				fill='#212121'
			/>
		</svg>
	)
}

export default React.memo(LinkIcon)
