import MagnifierIcon from 'components/Icons/MagnifierIcon'
import XIcon from 'components/Icons/XIcon'
import React, { useState } from 'react'

const TaskDetailConversationHead = () => {
	const [searchCondition, setSearchCondition] = useState(false)

	return (
		<div
			className={`g-white py-[10px] pl-7 bg-white  pr-5 flex justify-between items-center relative`}>
			<div className='flex items-center gap-[22px]'>
				<div>
					<h3 className='font-medium text-base text-gray-700'>Noble Pixels</h3>
					<h6 className='text-[10px] font-light text-gray-700 opacity-80'>
						6 Members
					</h6>
				</div>
			</div>
			<div className='flex gap-6'>
				<button onClick={() => setSearchCondition(true)} className='opacity-60'>
					<MagnifierIcon />
				</button>
			</div>

			<div
				className={`absolute h-full duration-500 w-full left-0 pr-7 pl-9 bg-white flex justify-between items-center ${
					searchCondition ? 'top-0' : '-top-full'
				} `}>
				<div className='w-full flex-1 flex items-center'>
					<span className='opacity-60'>
						<MagnifierIcon />
					</span>
					<input
						className='ml-7 text-base placeholder:text-gray-400 font-medium'
						placeholder='Search...'
					/>
				</div>
				<button
					onClick={() => setSearchCondition(false)}
					className='flex-shrink-0 opacity-60 '>
					<XIcon color='#939393' />
				</button>
			</div>
		</div>
	)
}

export default TaskDetailConversationHead
