import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import DocumentFillIcon from 'components/Icons/DocumentFillIcon'
import XIcon from 'components/Icons/XIcon'
import FullScreenIcon from 'components/Icons/FullScreenIcon'
import { useWindowSize } from 'hooks/useWindowSize'
import { AddNoteApi, EditNoteApi } from 'services/dashboardServices/note'
import { useNavigate } from 'react-router-dom'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import Spinner from '../Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { noteModalHandler } from 'redux/slices/originModals'
import { useLazyGetAllNotesApiQuery } from 'redux/api/note'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import { useLazyGetTaskByMilestoneApiQuery } from 'redux/api/task'
import { LabelAndValueHandler } from 'utils/LabelAndValueHandler'
import Input from '../Input'
import SelectBox from '../SelectBox'
import TextEditor from '../TextEditor'

const AggregateNoteModal = ({ modalHandler }) => {
	const { defaultValue } = useSelector(state => state.originModalCondition)
	const [getAllNotes] = useLazyGetAllNotesApiQuery()
	const [getMilestone, { data: milestone, isLoading: milestoneLoading }] =
		useLazyGetAllMilestoneByProjectIdApiQuery()
	const { data: projects, isLoading: getProjectLoading } =
		useGetProjectsByOrgIdApiQuery()
	const [getTasks, { data: tasks, isLoading: taskLoading }] =
		useLazyGetTaskByMilestoneApiQuery()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [width] = useWindowSize()
	const [fullScreen, setFullScreen] = useState(false)
	const [errors, setErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [dataSchema, setDataSchema] = useState({
		title: null,
		body: null,
		date: null,
		project_id: null,
		milestone_id: null,
		task_id: null
	})

	useEffect(() => {
		if (defaultValue) {
			const { id, body, milestone, project, title, task } = defaultValue
			setDataSchema({
				id: id,
				title: title,
				body: body,
				date: null,
				project_id: LabelAndValueHandler(defaultValue, 'project'),
				milestone_id: LabelAndValueHandler(defaultValue, 'milestone'),
				task_id: LabelAndValueHandler(defaultValue, 'task')
			})
	

			project && getMilestone({ projectId: project?.id })
			milestone && getTasks(milestone?.id)
		}
	}, [])

	const dataSchemaHandler = (_key, _value) => {
		setDataSchema({ ...dataSchema, [_key]: _value })

		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setDataSchema(data)
		}
	}



	const submitForm = async e => {
		e.preventDefault()
		const newDataSchema = {
			...dataSchema,
			milestone_id: dataSchema?.milestone_id?.id,
			project_id: dataSchema?.project_id?.id,
			task_id: dataSchema?.task_id?.id
		}
		setIsLoading(true)
		try {
			const response = defaultValue
				? await EditNoteApi(navigate, newDataSchema)
				: await AddNoteApi(navigate, newDataSchema)

			await getAllNotes({})
			dispatch(noteModalHandler(false))
			setDataSchema({
				title: null,
				body: null,
				date: null,
				project_id: null,
				milestone_id: null,
				task_id: null
			})
			e.target.reset()
			setIsLoading(false)
			if (response.status === 422) {
				setErrors(response.data.errors)
			}
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}

	return (
		<ModalContainer
			maxHeight={width <= 768 ? true : false}
			setShowModal={modalHandler}>
			<form onSubmit={submitForm}>
				<div
					className={` md:bg-white bg-gray-300  md:h-fit h-full w-screen md:px-10 px-4 md:pt-8 md:pb-8 pt-3 pb-16 ${
						fullScreen
							? 'fixed top-0 right-0 w-screen !h-screen'
							: ' md:w-[1264px] md:max-w-[98vw]'
					}`}>
					<div className='flex items-center justify-between mb-4'>
						<div className='flex items-center '>
							<span className='text-primaryBlue mr-3'>
								<DocumentFillIcon />
							</span>
							<Input
								className={'!bg-white !text-lg'}
								height={54}
								value={dataSchema?.title ? dataSchema?.title : 'Untitled Note'}
								errors={errors?.title}
								type={'text'}
								required={true}
								name={'title'}
								onInput={dataSchemaHandler}
								placeholder='Note Title'
							/>
						</div>

						<div className='flex items-center gap-4'>
							<button
								type='button'
								className='text-xs font-light md:flex hidden text-gray-400'>
								Saved
							</button>
							<button
								type='button'
								onClick={() => setFullScreen(!fullScreen)}
								className='h-9 border md:flex hidden whitespace-nowrap border-solid border-primaryBlue hover:bg-primaryBlue hover:text-white duration-300 text-primaryBlue font-light text-xs rounded-[5px]  items-center gap-1.5 px-4'>
								<FullScreenIcon />
								{fullScreen ? 'base screen' : 'Full screen'}
							</button>
							<button
								disabled={isLoading}
								type='submit'
								className={`md:h-9 h-[52px] flex justify-center ${
									isLoading ? 'border-transparent' : 'border-primaryBlue'
								} items-center gap-3 z-10 md:relative fixed left-0 bottom-0 w-full md:bg-transparent bg-primaryBlue  border px-6 hover:bg-primaryBlue hover:text-white border-solid  md:text-primaryBlue text-white font-light text-xs md:rounded-[5px]`}>
								Save changes
								<Spinner show={isLoading} scale={25} size={3} />
							</button>
							<button
								type='button'
								onClick={modalHandler}
								className='text-opacity-60 text-gray-700 w-10 h-10 md:w-fit md:h-fit flex items-center justify-center rounded md:bg-transparent bg-gray-200'>
								<XIcon color='currentColor' />
							</button>
						</div>
					</div>

					<div className='bg-gray-300 md:border border-solid mb-4 px-3 md:rounded-[10px] py-4 border-gray-200 '>
						<TextEditor
							mode='advanced'
							height={600}
							onChange={dataSchemaHandler}
							name={'body'}
							value={defaultValue?.body}
						/>
					</div>
					<div className='p-3 bg-gray-300 border z-10 border-solid mb-[10px]  grid grid-cols-3 gap-3 border-gray-200 rounded-[10px]'>
						<SelectBox
							value={dataSchema.project_id}
							isSearchable={false}
							placeholder='Select Project'
							options={projects}
							isLoading={getProjectLoading}
							height={54}
							name={'project_id'}
							errors={errors?.project_id}
							onChange={(_, e) => {
								dataSchemaHandler('project_id', e)
								e?.id && getMilestone({ projectId: e.id })
							}}
							className={'custom_select_box_open_top'}
						/>
						<SelectBox
							options={milestone}
							isDisabled={
								!dataSchema.project_id || milestoneLoading ? true : false
							}
							value={dataSchema.milestone_id}
							isSearchable={false}
							placeholder='Select Milestone'
							isLoading={milestoneLoading}
							height={54}
							name={'milestone_id'}
							errors={errors?.milestone_id}
							onChange={(_, e) => {
								dataSchemaHandler('milestone_id', e)
								e?.id && getTasks(e.id)
							}}
							className={'custom_select_box_open_top'}
						/>
						<SelectBox
							isDisabled={
								!dataSchema.milestone_id || taskLoading ? true : false
							}
							isSearchable={false}
							placeholder='Select Task'
							options={tasks}
							isLoading={tasks === 'loading'}
							value={dataSchema.task_id}
							height={54}
							name={'task_id'}
							errors={errors?.task_id}
							onChange={dataSchemaHandler}
							className={'custom_select_box_open_top'}
						/>
					</div>
					{/* <div className='bg-gray-300 border border-solid p-4 md:relative fixed md:bottom-0 md:right-0 bottom-[52px] right-0 w-full  md:rounded-[10px] grid md:grid-cols-4 grid-cols-2 gap-4   border-gray-200 '>
					<div className='custom_select_box custom_select_box_open_top'>
						<ReactSelect isSearchable={false} placeholder='Select Project' />
					</div>
					<div className='custom_select_box custom_select_box_open_top'>
						<ReactSelect isSearchable={false} placeholder='Select Meeting' />
					</div>
					<div className='custom_select_box custom_select_box_open_top'>
						<ReactSelect isSearchable={false} placeholder='Select Milestone' />
					</div>
					<div className='custom_select_box custom_select_box_open_top'>
						<ReactSelect isSearchable={false} placeholder='Select Task' />
					</div>
				</div> */}
				</div>
			</form>
		</ModalContainer>
	)
}

AggregateNoteModal.propTypes = {
	modalHandler: PropTypes.func.isRequired
}
AggregateNoteModal.defaultProps = {
	modalHandler: () => {}
}

export default AggregateNoteModal
