import { createSlice, nanoid } from '@reduxjs/toolkit'

const initialState = {}

const organization = createSlice({
	name: 'organization',
	initialState,
	reducers: {
		setOrganization: (state, action) => ({
			...action.payload
		})
	}
})

export const { setOrganization } = organization.actions
export default organization.reducer
