import React from 'react'

const ProgressIcon = () => {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0 5.625V12.375H18V5.625H0ZM16.875 11.25H1.125V6.75H16.875V11.25Z'
				fill='currentColor'
			/>
			<path d='M2.25 7.875H10.125V10.125H2.25V7.875Z' fill='currentColor' />
		</svg>
	)
}

export default React.memo(ProgressIcon)
