import React from 'react'

const ImportantPriorityIcon = ({...props}) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
            {...props}>
			    <g id="fluent:important-16-filled">
					<path id="Vector" d="M8.16471 6.64535C8.08984 6.24289 8.1045 5.82888 8.20766 5.43273C8.31083 5.03657 8.49995 4.668 8.76161 4.35317C9.02327 4.03835 9.35104 3.785 9.72164 3.61112C10.0922 3.43725 10.4966 3.34711 10.906 3.34711C11.3153 3.34711 11.7197 3.43725 12.0903 3.61112C12.4609 3.785 12.7886 4.03835 13.0503 4.35317C13.312 4.668 13.5011 5.03657 13.6042 5.43273C13.7074 5.82888 13.7221 6.24289 13.6472 6.64535L12.497 12.7728C12.4268 13.1435 12.2295 13.478 11.939 13.7187C11.6486 13.9594 11.2832 14.0911 10.906 14.0911C10.5287 14.0911 10.1633 13.9594 9.87287 13.7187C9.5824 13.478 9.38508 13.1435 9.31496 12.7728L8.16471 6.64535ZM12.9216 17.4531C12.9216 17.9877 12.7092 18.5004 12.3312 18.8784C11.9532 19.2564 11.4405 19.4688 10.906 19.4688C10.3714 19.4688 9.8587 19.2564 9.48069 18.8784C9.10269 18.5004 8.89033 17.9877 8.89033 17.4531C8.89033 16.9186 9.10269 16.4059 9.48069 16.0279C9.8587 15.6499 10.3714 15.4375 10.906 15.4375C11.4405 15.4375 11.9532 15.6499 12.3312 16.0279C12.7092 16.4059 12.9216 16.9186 12.9216 17.4531Z" fill="#E26A00"/>
				</g>
		</svg>
	)
}

export default ImportantPriorityIcon