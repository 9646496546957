import React from 'react'

const EmptyIcon = () => {
	return (
		<svg
			width='77'
			height='77'
			viewBox='0 0 77 77'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.8332 64.1666C11.0686 64.1666 9.55747 63.5378 8.2998 62.2801C7.04213 61.0224 6.41437 59.5124 6.41651 57.7499V19.2499C6.41651 17.4853 7.04534 15.9742 8.30301 14.7165C9.56068 13.4589 11.0707 12.8311 12.8332 12.8333H29.4363C30.2919 12.8333 31.1078 12.9937 31.8843 13.3145C32.6607 13.6353 33.3419 14.0899 33.928 14.678L38.4998 19.2499H64.1665C65.9311 19.2499 67.4422 19.8788 68.6999 21.1364C69.9576 22.3941 70.5853 23.9041 70.5832 25.6666H19.2498C17.4853 25.6666 15.9741 26.2954 14.7165 27.5531C13.4588 28.8108 12.831 30.3208 12.8332 32.0833V57.7499L19.1696 36.6551C19.5974 35.2649 20.3867 34.1548 21.5374 33.3249C22.6881 32.495 23.9575 32.0811 25.3457 32.0833H66.7332C68.9255 32.0833 70.6506 32.9527 71.9082 34.6916C73.1659 36.4305 73.4996 38.3149 72.9092 40.3447L67.1342 59.5947C66.7064 60.985 65.9172 62.0951 64.7665 62.925C63.6157 63.7549 62.3463 64.1687 60.9582 64.1666H12.8332Z'
				fill='#D7D7D7'
			/>
			<path
				d='M69.1252 66.1249L63.3307 60.3202L69.1252 66.1249ZM66.5418 52.5624C66.5418 55.4743 65.3851 58.2669 63.3261 60.3259C61.2671 62.3849 58.4745 63.5416 55.5627 63.5416C52.6508 63.5416 49.8582 62.3849 47.7992 60.3259C45.7402 58.2669 44.5835 55.4743 44.5835 52.5624C44.5835 49.6506 45.7402 46.858 47.7992 44.799C49.8582 42.74 52.6508 41.5833 55.5627 41.5833C58.4745 41.5833 61.2671 42.74 63.3261 44.799C65.3851 46.858 66.5418 49.6506 66.5418 52.5624V52.5624Z'
				stroke='#939393'
				stroke-width='2'
				stroke-linecap='round'
			/>
		</svg>
	)
}

export default React.memo(EmptyIcon)
