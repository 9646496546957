import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const accountantApiRedux = createApi({
	reducerPath: 'accountantApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllAccountantReportApi: builder.query({
			query: queries => {
				return {
					url: `accountant/report`,
					params: queries,
					headers: { orgId: queries?.orgId }
				}
			},
			transformResponse: response => response.data
		}),
		getTeamLogApi: builder.query({
			query: ({ dateFilter, startDate, endDate, userId , orgId, projectId }) => {
				return {
					url: `/accountant/viewLogs/${userId }`,
					params: { dateFilter, startDate, endDate,  projectId },
					headers: { orgId: orgId }
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetAllAccountantReportApiQuery,
	useLazyGetTeamLogApiQuery
} = accountantApiRedux
