import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const useOutsideClick = ref => {
	const [isClickedOutside, setIsClickedOutside] = useState(false)
	useEffect(() => {
		const handleClickOutside = event => {
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				(event.target.parentNode instanceof SVGElement 
					? 
					!event.target.parentNode.parentNode.className.includes('tox') 
					:  
					(
						event.target instanceof SVGElement 
						? 
						!event.target.parentNode.className.includes('tox') 
						: 
						!event.target.parentNode.parentNode.className.includes('tox')
					)
				)
			) {
				
				setIsClickedOutside(true)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [ref])
	return isClickedOutside
}

useOutsideClick.propTypes = {
	ref: PropTypes.func
}

useOutsideClick.defaultProps = {
	ref: () => {}
}

export default useOutsideClick
