import React, { useEffect } from 'react'
import Spinner from './Spinner'
import EmptyIcon from 'components/Icons/EmptyIcon'

const ListZeroPage = ({
	children,
	isLoading,
	isFetching,
	data,
	isHorizontal = false
}) => {
	return (
		<>
			{(!isLoading && isFetching) || isLoading ? (
				<div className=' flex w-full justify-center'>
					<Spinner scale={30} size={3} />
				</div>
			) : !data || data.length === 0 ? (
				<div
					className={`flex ${
						!isHorizontal && 'flex-col my-5'
					}   gap-3 items-center justify-center`}>
					<div className={` ${!isHorizontal ? "w-[170px] h-[170px]":"w-[80px] h-[80px]"} rounded-full  bg-gray-300 flex items-center justify-center`}>
						<EmptyIcon />
					</div>
					<div>
						<h6 className='font-light text-gray-700 text-lg'>
							No results found!
						</h6>
						<span className='mt-1 text-gray-400 text-sm font-light'>
							Nothing to show
						</span>
					</div>
				</div>
			) : (
				<>{children}</>
			)}
		</>
	)
}

export default ListZeroPage
