import React, { useEffect, useRef, useState } from 'react'
import TrackingPlayIcon from 'components/Icons/TrackingPlayIcon'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import PauseIcon from 'components/Icons/PauseIcon'
import ProjectIcon from 'components/Icons/ProjectIcon'
import { useDispatch, useSelector } from 'react-redux'
import { logModalHandler } from 'redux/slices/originModals'
import {
	pauseHandlerCounter,
	trackingStatusHandler
} from 'redux/slices/trackingDetail'
import NotificationBox from './NotificationBox'
import HeaderModalsDropdown from './HeaderModalsDropdown'
import HeaderOrganizationDropdown from './HeaderOrganizationDropdown'
import HeaderUserDropdown from './HeaderUserDropdown'
import { useRole } from 'hooks/useRole'

const TrackingAction = ({ data }) => {
	const location = useLocation()
	const { status, pauseCounter } = useSelector(state => state.trackingDetail)
	const user = useSelector(state => state.user)

	const isAdmin = useRole('Admin')
	const isClient = useRole('Client')
	const isAccountant = useRole('Accountant')
	const showTrackerButton = useRole(['Team', 'Project Manager'])

	const [searchParams] = useSearchParams()
	const dispatch = useDispatch()
	const [time, setTime] = useState(0)
	const timeTracker = useRef()
	const params = useParams()

	useEffect(() => {
		if (params?.projectId) {
			localStorage.setItem('projectDetail', params?.projectId)
		} else {
			if (!localStorage?.timeTracked && localStorage?.projectDetail) {
				localStorage.removeItem('projectDetail')
			}
		}
	}, [params])

	useEffect(() => {
		if (!status) {
			clearInterval(timeTracker.current)
			setTime(0)
		}
	}, [status])

	useEffect(() => {
		if (localStorage?.timeTracked && checkIsForAUser()) {
			TimeTrackHandler('start', starterTimeHandler())
		}
	}, [])

	const checkIsForAUser = () => {
		return (
			JSON.parse(localStorage?.timeTracked)?.user?.user_id === user?.user_id
		)
	}

	const starterTimeHandler = () => {
		let lastTime = new Date(
			JSON.parse(localStorage.timeTracked).startTime
		).getTime()
		let nowTime = new Date().getTime()
		let currentTime = Number(((nowTime - lastTime) / 1000).toFixed())
		setTime(currentTime)
		return currentTime
	}

	const TimeTrackHandler = (_type, _time) => {
		if (_type === 'start') {
			clearInterval(timeTracker.current)
			let projectDetail = params?.projectId
			if (!localStorage?.timeTracked) {
				localStorage.setItem(
					'timeTracked',
					JSON.stringify({
						...data,
						project_id: projectDetail,
						startTime: new Date(_time),
						user
					})
				)
			}
			dispatch(trackingStatusHandler(true))
			timeTracker.current = setInterval(() => {
				if (!pauseCounter) {
					starterTimeHandler()
				}
			}, 1000)
		} else {
			dispatch(pauseHandlerCounter(true))
			dispatch(logModalHandler(true))
		}
	}
	const timeHandler = _time => {
		let hours = Math.floor(_time / 3600)
		let minutes = Math.floor((_time - hours * 3600) / 60)
		let second = _time % 60 < 10 ? `0${_time % 60}` : _time % 60

		return (
			<span>
				<span className='font-bold'>{hours < 10 ? `0${hours}` : hours}</span>:
				<span className='font-medium'>
					{minutes < 10 ? `0${minutes}` : minutes}
				</span>
				:<span className='font-light'>{second}</span>
			</span>
		)
	}

	return (
		<ul className='flex items-center justify-around lg:relative fixed lg:border-t-0 border-t border-solid border-gray-200  right-0 bottom-0 lg:w-fit w-full lg:px-0 md:px-3 px-1 lg:h-fit h-[75px] lg:gap-0 gap-1.5 divide-x-0 lg:bg-transparent bg-gray-300 lg:divide-x divide-gray-200'>
			{showTrackerButton && (
				<>
					{' '}
					{status || params?.projectId ? (
						<li className='lg:pr-4 pr-0 flex-1'>
							{status && checkIsForAUser() ? (
								<button
									onClick={() => TimeTrackHandler('stop', new Date())}
									className='px-4 w-full md:min-w-[190px] flex items-center justify-center border hover:bg-red-700 hover:bg-opacity-5 duration-300 border-solid border-red-700 rounded-[10px] gap-3 text-sm  text-red-700 h-11 '>
									<span className='w-5 h-5 flex bg-red-700 items-center text-white justify-center rounded-full'>
										<PauseIcon width={10} height={11} color='currentColor' />
									</span>
									{timeHandler(time)}
								</button>
							) : (
								<button
									onClick={() => TimeTrackHandler('start', new Date())}
									className='ls:px-4 px-2 flex w-full justify-center items-center border border-solid hover:bg-primaryBlue whitespace-nowrap hover:bg-opacity-5 duration-300 border-primaryBlue rounded-[10px] gap-3 text-sm font-medium text-primaryBlue h-11 '>
									<TrackingPlayIcon />
									Start Time Tracking
								</button>
							)}
						</li>
					) : location.pathname.includes('/conversations') &&
					  !searchParams?.message ? (
						<Link
							to={'/dashboard/projects'}
							className='ls:px-4 px-2 flex w-full justify-center items-center border border-solid hover:bg-primaryBlue whitespace-nowrap hover:bg-opacity-5 duration-300 border-primaryBlue rounded-[10px] gap-3 text-sm font-medium text-primaryBlue h-11 '>
							<ProjectIcon />
							Projects
						</Link>
					) : null}
				</>
			)}

			{!isClient && !isAccountant ? <HeaderModalsDropdown /> : null}

			{!isAccountant ? <NotificationBox /> : null}

			{(!isAccountant && !isAdmin) && <HeaderOrganizationDropdown />}

			<HeaderUserDropdown />
		</ul>
	)
}

TrackingAction.propTypes = {}

export default TrackingAction
