import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const dashboardApiRedux = createApi({
	reducerPath: 'dashboardApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getProjectDashboardApi: builder.query({
			query: ({ projectId, period, teamId }) => {
				return {
					url: `dashboard/projectDashboard/${projectId}`,
					params: { period, teamId }
				}
			},
			transformResponse: response => response.data
		}),
		getDashboardApi: builder.query({
			query: () => {
				return {
					url: `dashboard/aggregateDashboard`
				}
			},
			transformResponse: response => response.data
		}),
		getRecentTaskAndActivity: builder.query({
			query: () => {
				return {
					url: `dashboard/userDashboard`
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetProjectDashboardApiQuery,
	useLazyGetProjectDashboardApiQuery,
	useGetDashboardApiQuery,
	useLazyGetDashboardApiQuery,
	useLazyGetRecentTaskAndActivityQuery
} = dashboardApiRedux
