import React from 'react'

const ErrorDisplay = ({ error }) => {
	return (
		<>
			{error ? (
				<p
					className='text-xs text-red-700 mt-1 -mb-[42px]'
					style={{ display: error ? 'flex' : 'none' }}>
					{error[0]}
				</p>
			) : (
				<></>
			)}
		</>
	)
}

export default React.memo(ErrorDisplay)
