import React from 'react'

const TrashcanIcon = ({ color = '#939393', opacity = 0.5 }) => {
	return (
		<svg
			width='13'
			height='16'
			viewBox='0 0 13 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.89805 5.33301H11.1012L10.551 14.1403C10.5258 14.5423 10.3484 14.9196 10.0548 15.1954C9.76122 15.4711 9.37359 15.6247 8.9708 15.6247H4.02922C3.62644 15.6247 3.2388 15.4711 2.94523 15.1954C2.65166 14.9196 2.47423 14.5423 2.44905 14.1403L1.89805 5.33301ZM12.0417 2.95801V4.54134H0.958344V2.95801H3.33334V2.16634C3.33334 1.74642 3.50016 1.34369 3.79709 1.04676C4.09402 0.749823 4.49675 0.583008 4.91668 0.583008H8.08334C8.50327 0.583008 8.906 0.749823 9.20293 1.04676C9.49986 1.34369 9.66668 1.74642 9.66668 2.16634V2.95801H12.0417ZM4.91668 2.95801H8.08334V2.16634H4.91668V2.95801Z'
				fill={color}
				opacity={opacity}
			/>
		</svg>
	)
}

export default React.memo(TrashcanIcon)
