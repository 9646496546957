import useFetch from 'hooks/useFetch'
const endPoint = '/note'

export const AllNoteApi = async router => {
	let response = await useFetch(router, true).get(`${endPoint}`)

	return response
}

export const AddNoteApi = async (router, data) => {
	let response = await useFetch(router, true).post(`${endPoint}`, {
		...data
	})

	return response
}

export const EditNoteApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/${data.id}`, {
		...data
	})

	return response
}

export const DeleteNoteApi = async (router, _id) => {
	let response = await useFetch(router, true).delete(`${endPoint}/${_id}`)

	return response
}
