import CheckboxIcon from 'components/Icons/CheckboxIcon'
import CubeIcon from 'components/Icons/CubeIcon'
import PencilIcon from 'components/Icons/PencilIcon'
import TrashcanIcon from 'components/Icons/TrashcanIcon'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyGetAllNotesApiQuery } from 'redux/api/note'
import { editModalHandler } from 'redux/slices/originModals'
import { DeleteNoteApi } from 'services/dashboardServices/note'
import NoteGroupBoxItemDeleteModal from './NoteGroupBoxItemDeleteModal'
import DefaultImage from 'components/Common/DefaultImage'
import { toast } from 'react-toastify'

const NoteGroupBoxItem = ({ NoteModalHandler, data, inModal, isAggregate }) => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [modalCondition, setModalCondition] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [getNotes] = useLazyGetAllNotesApiQuery()

	const deleteNote = async () => {
		setIsLoading(true)
		try {
			await DeleteNoteApi(navigate, data.id)
			if (params.projectId) {
				await getNotes({ projectId: params?.projectId })
			} else {
				await getNotes({})
			}
			setModalCondition(false)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	return (
		<>
			<div className='rounded-[10px] border md:flex-col flex flex-row-reverse justify-between overflow-hidden border-solid border-gray-200'>
				<div className='relative group'>
					<div
						dangerouslySetInnerHTML={{ __html: data?.body }}
						className={`md:w-full  w-[145px] object-cover overflow-hidden text-[10px] bg-white px-4 py-3 ${
							inModal ? 'h-[116px]' : 'h-[189px]'
						}`}></div>

					<div className='hidden top-0 left-0 bg gap-1.5  bg-black bg-opacity-30 group-hover:flex items-center justify-center absolute w-full h-full'>
						{NoteModalHandler ? (
							<button
								onClick={() => NoteModalHandler(data)}
								className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
								<PencilIcon />
							</button>
						) : (
							<button
								onClick={() =>
									dispatch(editModalHandler({ modal: 'note', value: data }))
								}
								className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
								<PencilIcon />
							</button>
						)}

						<button
							onClick={() => setModalCondition(true)}
							className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
							<TrashcanIcon />
						</button>
					</div>
				</div>

				<div className='border-t flex-col flex md:justify-start justify-between border-solid border-gray-200 py-[10px] px-2'>
					<h4 className='text-sm text-gray-700 font-medium mb-2'>
						{data?.title}
					</h4>
					{isAggregate ? (
						<div className='text-xs font-light flex items-center gap-2 text-opacity-50 text-gray-700'>
							<DefaultImage
								src={data?.project?.logo}
								bgColor={data?.project?.background_color}
								mainColor={data?.project?.foreground_color}
								size={20}
								type='project'
								iconSize={15}
							/>
							<span className='-mb-0.5'> {data?.project?.name}</span>
						</div>
					) : (
						<ul className='grid gap-2'>
							<li className='flex items-center  text-xs font-light gap-2 text-opacity-50 text-gray-700'>
								<CubeIcon />
								<span className='-mb-0.5'>{data?.project?.name}</span>
							</li>
							<li className='flex items-center  text-xs font-light  gap-2 text-opacity-50 text-gray-700'>
								<CheckboxIcon color='currentColor' width={11} height={11} />
								<span className='-mb-0.5'>{data?.task?.name}</span>
							</li>
						</ul>
					)}
				</div>
			</div>
			{modalCondition && (
				<NoteGroupBoxItemDeleteModal
					isLoading={isLoading}
					deleteHandler={deleteNote}
					modalHandler={() => setModalCondition(false)}
				/>
			)}
		</>
	)
}

export default NoteGroupBoxItem
