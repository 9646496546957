import AttachmentIcon from 'components/Icons/AttachmentIcon'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {  useLazyGetTaskAttachmentsAndSearchAttachmentsApiQuery } from 'redux/api/attachment'
import AddAttachmentModal from './AddAttachmentModal'
import SearchBox from './search'
import TaskAttachmentItem from './taskAttachmentItem'
import { useNavigate } from 'react-router-dom'
import { DownloadAttachmentFileApi } from 'services/dashboardServices/attachment'
import { DownloadBlobFile } from 'utils/DownloadBlobFile'
import Spinner from 'components/Common/Spinner'

const TaskAttachment = ({ data, position = '' }) => {
    const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [attachId, setAttachId] = useState()
    const navigate = useNavigate()
    const organization = useSelector(state => state.organization)
    const [getTaskAttachmentsAndSearchAttachments, { data: taskAttachments, isLoading: searchLoading }] =
        useLazyGetTaskAttachmentsAndSearchAttachmentsApiQuery()

    const refreshAttachmentData = () => {
        getTaskAttachmentsAndSearchAttachments(data.id,  '')
    }

    const downloadFile = async (item) => {
        setAttachId(item.id)
        setLoading(true);
        const response = await DownloadAttachmentFileApi(navigate, item.id);
        DownloadBlobFile(new Blob([response.data], { type: item.file_type }), item.file_name);
        setLoading(false);
    }

    const searchFiles = (term) => {
        term !== '' && getTaskAttachmentsAndSearchAttachments(data.id, term);
    }

    useEffect(() => {
        data && getTaskAttachmentsAndSearchAttachments(data.id)
    }, [data])


    return (
        <div className='flex flex-col justify-start items-start gap-5 rounded-[10px] bg-gray-300 min-w-[278px] max-w-[278px] w-full min-h-[710px] h-full p-5'>
            <div className="flex flex-row justify-between items-center w-full">
                <span className='text-base font-medium text-gray-700'>Files</span>
                <div className='flex items-center gap-1 text-sm font-light text-blue-200 cursor-pointer' onClick={() => setShowAddAttachmentModal(true)}><AttachmentIcon /> Attach file</div>
            </div>
            <SearchBox placeholder="Search Files" onSearch={data => searchFiles(data)} />
            <span className='text-xs font-light text-gray-800b'></span>
            <div className='flex flex-col items-center justify-center w-full'>
                {searchLoading ? <Spinner show={searchLoading}/> : 
                taskAttachments && taskAttachments?.data?.length !== 0 && taskAttachments?.data?.map(item =>
                    (<TaskAttachmentItem item={item} onDownloadFile={(item) => downloadFile(item)} loading={loading} id={attachId}/>)
                )}
            </div>
            {showAddAttachmentModal && (
                <AddAttachmentModal position={position !== '' ? position : 'task'} data={data} refreshData={() => refreshAttachmentData()} modalHandler={() => setShowAddAttachmentModal(false)} orgId={organization.id} />
            )}
        </div>
    )
}

export default TaskAttachment
