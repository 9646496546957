import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setOrganization } from 'redux/slices/organization'
import { login } from 'redux/slices/user'
import { GetUserData } from 'services/auth'

const Transfer = () => {
    const navigate = useNavigate()
	const dispatch = useDispatch()
	const params = useParams()
	useEffect(() => {
		const query = new URLSearchParams(window.location.search)
        localStorage.setItem('token', atob(query.get('token')))
		if(query.get('type') === 'project') { 
			GetUserData(navigate , atob(query.get('token')), params?.id).then(res => {localStorage.setItem('organization', JSON.stringify(res?.data?.organization)); dispatch(setOrganization(res?.data?.organization)); dispatch(login(res?.data))}).catch((error) => toast.error(error?.message))
		} else {
			GetUserData(navigate , atob(query.get('token')), 0).then(res => {localStorage.setItem('organization', JSON.stringify(res?.data?.organization));  dispatch(login(res?.data))}).catch((error) => toast.error(error?.message))
		}
		
		query.get('type') === 'project' && localStorage.setItem('organization', JSON.stringify(query.get('data')))
        navigate(query.get('type') === 'project' ? `/progress/${params?.id}/settings` : query.get('position') === 'profile' ? '/dashboard/profile' : `/space`)
	}, [params])

	return <div className='flex flex-col items-center justify-center p-10 mx-auto'> transferring to portal...</div>
}

export default Transfer
