import { Outlet, useParams } from 'react-router-dom'
import ContentContainer from 'components/ContentContainer/ContentContainer'
import Header from 'components/Header/Header'
import Loading from 'components/Loading/Loading'
import SideMenu from 'components/SideMenu/SideMenu'
import PropTypes from 'prop-types'
import { Suspense, useEffect, useState } from 'react'

// icons
import DashboardIcon from 'components/Icons/Sidebar/DashboardIcon'
import DocumentFillIcon from 'components/Icons/DocumentFillIcon'
import ChatFillIcon from 'components/Icons/ChatFillIcon'
import CubeIcon from 'components/Icons/CubeIcon'
import CheckboxIcon from 'components/Icons/CheckboxIcon'
import TimelineIcon from 'components/Icons/Sidebar/TimelineIcon'
import ProgressIcon from 'components/Icons/Sidebar/ProgressIcon'
import CalendarIcon from 'components/Icons/Sidebar/CalendarIcon'
import ClockFillIcon from 'components/Icons/ClockFillIcon'
import ChatUploadIcon from 'components/Icons/ChatUploadIcon'
import SettingIcon from 'components/Icons/Sidebar/SettingIcon'
import ThreeUserIcon from 'components/Icons/ThreeUserIcon'
import TeamReportIcon from 'components/Icons/Sidebar/TeamReportIcon'
import AddUserIcon from 'components/Icons/AddUserIcon'
import { useWindowSize } from 'hooks/useWindowSize'
import Spinner from '../Spinner'
import { useGetProjectDetailApiQuery } from 'redux/api/projects'
import { useDispatch } from 'react-redux'
import { setProject } from 'redux/slices/project'
import AvailabilityIcon from 'components/Icons/Sidebar/AvailabilityIcon'

const ProjectLayout = () => {
	let params = useParams()
	let [width] = useWindowSize()
	const dispatch = useDispatch()

	const { data, isLoading } = useGetProjectDetailApiQuery(params.projectId)

	useEffect(() => {
		if (data) {
			dispatch(setProject(data.data))
		}
	}, [data])

	const [toggleSideMenu, setToggleSideMenu] = useState(false)
	const menuLinks = [
		{
			groupTitle: 'PROJECT',
			access: ['All'],
			links: [
				{
					title: 'Dashboard',
					path: `/progress/${data?.data?.id}`,
					defaultIcon: <DashboardIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Client', 'Team']
				},
				{
					title: 'Notes',
					path: `/progress/${data?.data?.id}/notes`,
					defaultIcon: <DocumentFillIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Team']
				},
				{
					title: 'Conversations',
					path: `/progress/${data?.data?.id}/conversations`,
					defaultIcon: <ChatFillIcon />,
					nested: false,
					access: []
				},
				{
					title: 'Milestones',
					path: `/progress/${data?.data?.id}/milestones`,
					defaultIcon: <CubeIcon width={15} height={15} />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Client', 'Team']
				},
				{
					title: 'Tasks',
					path: `/progress/${data?.data?.id}/tasks`,
					defaultIcon: <CheckboxIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Client', 'Team']
				},

				{
					title: 'Progress',
					path: `/progress/${data?.data?.id}/progress`,
					defaultIcon: <ProgressIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Client', 'Team']
				},
				{
					title: 'Timeline',
					path: `/progress/${data?.data?.id}/timeline`,
					defaultIcon: <TimelineIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Team']
				},
				{
					title: 'Calendar',
					path: `/progress/${data?.data?.id}/calendar`,
					defaultIcon: <CalendarIcon />,
					nested: false,
					access: []
				},
				{
					title: 'Logs',
					path: `/progress/${data?.data?.id}/logs`,
					defaultIcon: <ClockFillIcon />,
					nested: false,
					access: ['Admin', 'Project Manager', 'Team']
				},
				{
					title: 'Attachments',
					path: `/progress/${data?.data?.id}/attachments`,
					defaultIcon: <ChatUploadIcon />,
					nested: false,
					access: ['Team', 'Project Manager']
				},
				{
					title: 'Availability',
					path: `/progress/${data?.data?.id}/availability`,
					defaultIcon: <AvailabilityIcon />,
					nested: false,
					access: []
				},
				{
					title: 'Report',
					path: `/progress/${data?.data?.id}/report`,
					defaultIcon: <TeamReportIcon />,
					nested: false,
					access: ['Client']
				},
				{
					title: 'Project Details',
					path: `/progress/${data?.data?.id}/settings`,
					defaultIcon: <SettingIcon />,
					nested: false,
					access: ['Admin', 'Project Manager']
				},
				
				
			]
		},
		{
			groupTitle: 'TEAM',
			access: ['Admin', 'Project Manager'],
			links: [
				{
					title: 'Meeting',
					path: `/progress/${data?.data?.id}/meeting`,
					defaultIcon: <ThreeUserIcon />,
					nested: false,
					access: []
				},
				{
					title: 'Report',
					path: `/progress/${data?.data?.id}/report`,
					defaultIcon: <TeamReportIcon />,
					nested: false,
					access: ['Admin', 'Project Manager']
				},
				{
					title: 'Team',
					path: `/progress/${data?.data?.id}/team`,
					defaultIcon: <AddUserIcon />,
					nested: true,
					access: ['Admin', 'Project Manager']
				}
			]
		}
	]
	return (
		<section>
			{isLoading && (
				<div
					style={{ zIndex: 99999999 }}
					className='fixed flex  items-center justify-center top-0 right-0 w-full h-full bg-black bg-opacity-50'>
					<Spinner />
				</div>
			)}
			<Header
				projectHeader={true}
				data={data?.data}
				toggleSideMenu={toggleSideMenu}
				setToggleSideMenu={() => setToggleSideMenu(prevState => !prevState)}
			/>
			<div className='flex'>
				<SideMenu
					data={data?.data}
					menuLinks={menuLinks}
					toggleSideMenu={toggleSideMenu}
					setToggleSideMenu={() => setToggleSideMenu(false)}
				/>
				<div
					style={{
						width: width <= 1288 ? '100%' : 'calc(100% - 235px)',
						minHeight: `calc(100vh - 75px)`
					}}
					className=' xl:pr-6 xl:px-0 lg:px-6 px-3 pt-5 lg:pb-2 pb-[75px]'>
					<Suspense fallback={<Spinner />}>
						<Outlet />
					</Suspense>
				</div>
			</div>
			{/* <ContentContainer ChildComponent={} /> */}
		</section>
	)
}

ProjectLayout.propTypes = {
	ChildComponent: PropTypes.object.isRequired
}

ProjectLayout.defaultProps = {
	ChildComponent: {}
}

export default ProjectLayout
