import React from 'react'

const DocumentIconV2 = () => {
	return (
		<svg
			width='15'
			height='19'
			viewBox='0 0 15 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.75 0H9.03544C9.35036 6.72569e-05 9.65235 0.125223 9.875 0.347938L14.2771 4.75C14.4998 4.97265 14.6249 5.27464 14.625 5.58956V16.625C14.625 17.2549 14.3748 17.859 13.9294 18.3044C13.484 18.7498 12.8799 19 12.25 19H2.75C2.12011 19 1.51602 18.7498 1.07062 18.3044C0.625222 17.859 0.375 17.2549 0.375 16.625V2.375C0.375 1.74511 0.625222 1.14102 1.07062 0.695621C1.51602 0.250223 2.12011 0 2.75 0V0ZM9.28125 1.78125V4.15625C9.28125 4.47119 9.40636 4.77324 9.62906 4.99594C9.85176 5.21864 10.1538 5.34375 10.4688 5.34375H12.8438L9.28125 1.78125Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(DocumentIconV2)
