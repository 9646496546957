import Spinner from 'components/Common/Spinner'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import PropTypes from 'prop-types'
import React from 'react'

const NoteGroupBoxItemDeleteModal = ({
	modalHandler,
	deleteHandler,
	isLoading
}) => {
	return (
		<ModalContainer>
			<div className='sm:px-14 px-5 py-8 text-center bg-white rounded-[20px] sm:w-[470px] w-[95vw]'>
				<h5 className='font-semibold text-xl text-gray-700 mb-2'>
					Delete “Scrum” Note?
				</h5>
				<h6 className='font-light text-sm mb-5 text-gray-700 text-opacity-70'>
					This note will be permanently deleted from space.
				</h6>
				<div className='grid sm:grid-cols-2 gap-[10px]'>
					<button
						onClick={modalHandler}
						className='text-base border duration-300 pt-1.5 border-solid hover:bg-red-700 hover:text-white border-red-700 text-red-700 font-medium h-12 rounded-[10px] flex items-center justify-center'>
						Cancel
					</button>
					<button
						disabled={isLoading}
						onClick={deleteHandler}
						className={`text-base duration-300 pt-1.5 gap-3 ${
							isLoading ? 'border-transparent' : 'border-red-700'
						} text-white border border-solid hover:opacity-90  bg-red-700 font-medium h-12 rounded-[10px] flex items-center justify-center`}>
						Delete
						<Spinner show={isLoading} scale={25} size={3} />
					</button>
				</div>
			</div>
		</ModalContainer>
	)
}

NoteGroupBoxItemDeleteModal.propTypes = {
	modalHandler: PropTypes.func.isRequired,
	isLoading: PropTypes.bool
}

NoteGroupBoxItemDeleteModal.defaultProps = {
	modalHandler: () => {},
	isLoading: false
}

export default NoteGroupBoxItemDeleteModal
