import Input from 'components/Common/Input'
import Spinner from 'components/Common/Spinner'
import CheckboxIcon from 'components/Icons/CheckboxIcon'
import XIcon from 'components/Icons/XIcon'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLazyGetProjectContactsApiQuery } from 'redux/api/contact'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import {
	useLazyGetAllTasksApiQuery,
	useLazyGetProjectTaskApiQuery
} from 'redux/api/task'
import { taskModalHandler } from 'redux/slices/originModals'
import { AddTaskApi } from 'services/dashboardServices/task'

// style
import './Task.scss'

import { UploadMediaApi } from 'services/media'
import SelectBox from 'components/Common/SelectBox'
import MultiSelectBox from 'components/Common/MultiSelectBox'
import CounterBox from 'components/Common/CounterBox'
import Range from 'components/Common/Range'
import { useEffect, useState } from 'react'
import { RouterChecker } from 'utils/RouteChecker'
import TextEditor from 'components/Common/TextEditor'
import { useLazyGetProjectProgressReportApiQuery } from 'redux/api/progressReport'
import LowPriorityIcon from 'components/Icons/tasks/lowPriority'
import MediumPriorityIcon from 'components/Icons/tasks/mediumPriority'
import ImportantPriorityIcon from 'components/Icons/tasks/importantPriority'
import UrgentPriorityIcon from 'components/Icons/tasks/urgentPriority'
import { setRefreshTask } from 'redux/slices/task'


function AddTaskModal({ handler }) {
	const params = useParams()
	const [getAllTasks, { isLoading: AllTaskLoading }] =
		useLazyGetAllTasksApiQuery()
	const [getTask] = useLazyGetProjectTaskApiQuery()
	const [getMilestones, { data: milestone, isLoading: milestoneLoading }] =
		useLazyGetAllMilestoneByProjectIdApiQuery()
	const { data: projects, isLoading: projectsLoading } =
		useGetProjectsByOrgIdApiQuery({})
	const [
		getContacts,
		{ data: projectContacts, isLoading: projectContactsLoading }
	] = useLazyGetProjectContactsApiQuery()
	const [getProgressReport] = useLazyGetProjectProgressReportApiQuery()

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [errors, setErrors] = useState()
	const [isLoading, setIsLoading] = useState(null)
	const taskTypes = [
		{id: 1, label: 'Task'},
		{id: 2, label: 'QA'},
		{id: 3, label: 'Story'},
		{id: 4, label: 'BUG'},
		{id: 5, label: 'Hotfix'},
	]
	const taskPriorities = [
		{id: 1, label: 'low', icon: <LowPriorityIcon />},
		{id: 2, label: 'medium', icon: <MediumPriorityIcon />},
		{id: 3, label: 'important', icon: <ImportantPriorityIcon />},
		{id: 4, label: 'urgent', icon: <UrgentPriorityIcon />},
	]
	const [dataSchema, setDataSchema] = useState({
		name: null,
		description: null,
		project_id: null,
		milestone_id: null,
		due_date: null,
		time_budget: 0,
		team_id: null,
		priority: taskPriorities[1],
		status: null,
		type: taskTypes[0]
	})
	const [file, setFile] = useState(null)
	

	useEffect(() => {
		if (params?.projectId) {
			getContacts({ projectId: params?.projectId })
			getMilestones({ projectId: params?.projectId })
		}
	}, [])
	useEffect(() => {
		// console.log('projects', projects)
		if (params?.projectId && projects) {
			dataSchemaHandler(
				'project_id',
				projects.find(item => item.id === Number(params?.projectId))
			)
		}
	}, [projects])

	const addTask = async e => {
		let newDataSchema = {
			...dataSchema,
			project_id: dataSchema?.project_id?.id,
			milestone_id: dataSchema?.milestone_id?.id,
			team_ids: dataSchema?.team_id?.map(item => item.id),
			priority: dataSchema?.priority.label,
			type: dataSchema?.type.label
		}
		e.preventDefault()
		const status = e.nativeEvent.submitter.getAttribute('name')
		setIsLoading(status)
		try {
			let response = await AddTaskApi(navigate, newDataSchema)
			if (response.status === 200) {
				e.target.reset()
				setDataSchema({
					name: null,
					description: null,
					project_id: null,
					milestone_id: null,
					due_date: null,
					time_budget: 0,
					team_id: null,
					priority: taskPriorities[1],
					status: null,
					type: taskTypes[0]
				})
				await uploadFile(response.data.id)
				// RouterChecker('/dashboard/tasks')
				// 	? await getAllTasks({})
				// 	: RouterChecker(`/progress/${params?.projectId}/progress`)
				// 	? await getProgressReport({
				// 			projectId: params?.projectId
				// 	  })
				// 	: await getTask({ projectId: params?.projectId })
				if(RouterChecker('/dashboard/tasks')) {
					// await getAllTasks({})
					dispatch(setRefreshTask(true))
				} else if(RouterChecker(`/progress/${params?.projectId}/progress`)) {
					// await getProgressReport({
					// 				projectId: params?.projectId
					// 		  })
					dispatch(setRefreshTask(true))
				} else {
					// navigate(`/progress/${params?.projectId}/tasks`)
					dispatch(setRefreshTask(true))
					// await getTask({ projectId: params?.projectId })
				}

				setFile(null)
				toast.success('Task successfully created')
				if (status === 'close' && !AllTaskLoading) {
					dispatch(taskModalHandler(false))
				}
			} else if (response.status === 422) {
				setErrors(response.data.errors)
			}
			setIsLoading(null)
		} catch (error) {
			setIsLoading(null)
			console.error(error)
		}
	}

	const uploadFile = async id => {
		dataSchemaHandler('file', null)
		if (!file) {
			return
		}
		setIsLoading('file')
		try {
			let response = await UploadMediaApi(navigate, {
				target: 'L',
				subject_id: 3,
				record_id: id,
				type_id: 2,
				image: file
			})
			dataSchemaHandler('file', response.data.data.url)

			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}

	const dataSchemaHandler = (_key, _value) => {
		setDataSchema({ ...dataSchema, [_key]: _value })

		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setErrors(data)
		}
	}



	return (
		<ModalContainer rounded={10} setShowModal={handler}>
			<div className='w-[1090px] bg-white px-10 pt-8 pb-7'>
				<div className='flex justify-between items-center mb-10'>
					<h6 className='font-medium flex items-center gap-[10px] text-lg'>
						<span className='text-primaryBlue'>
							<CheckboxIcon />
						</span>
						New Task
					</h6>
					<button onClick={() => handler()}>
						<XIcon width={15} hight={15} color='#212121' />
					</button>
				</div>

				<form onSubmit={addTask}>
					<div className='grid grid-cols-2 gap-5'>
						<Input
							label={'Task Name'}
							height={50}
							name='name'
							onInput={dataSchemaHandler}
							placeholder='Enter Task Name'
							errors={errors?.name}
						/>
						<SelectBox
							height={50}
							errors={errors?.project_id}
							label={'Select Project'}
							isDisabled={!projects}
							name={'project_id'}
							grayBg
							withSearchIcon
							isLoading={projectsLoading}
							onChange={(_, e) => {
								e?.id && getContacts({ projectId: e.id })
								dataSchemaHandler('project_id', e)
								e?.id && getMilestones({ projectId: e.id })
							}}
							value={dataSchema.project_id}
							options={projects}
							placeholder='Search Project'
						/>
					</div>
					<div className='grid grid-cols-2 gap-5 mt-5'>
						<div className='task_select_box flex flex-col gap-5'>
							{/* <Input
								type={'textarea'}
								label={'Task Description'}
								height={144}
								name='description'
								onInput={dataSchemaHandler}
								placeholder='Describe your task'
								errors={errors?.description}
								value={dataSchema.description}
							/> */}
							<div className='flex items-center gap-5 w-full'>
							<SelectBox
								height={50}
								containerClassName={'w-full'}
								errors={errors?.priority}
								label={'Select Task Priority'}
								onChange={dataSchemaHandler}
								options={taskPriorities}
								value={dataSchema.priority}
								name={'priority'}
								grayBg
							/>		
							<SelectBox
								height={50}
								containerClassName={'w-full'}
								errors={errors?.type}
								label={'Select Task Type'}
								onChange={dataSchemaHandler}
								options={taskTypes}
								value={dataSchema.type}
								name={'type'}
								grayBg
							/>		
							</div>
							
							<TextEditor
								mode='advanced'
								label={'Task Description'}
								height={256}
								onChange={dataSchemaHandler}
								name='description'
								errors={errors?.description}
								value={dataSchema.description}
							/>
												{/* <Range
								label={'Task Priority'}
								name={'priority'}
								onChange={dataSchemaHandler}
								value={dataSchema.priority}
							/> */}
							
						</div>

						<div className='task_select_box  flex flex-col gap-5'>
							<SelectBox
								height={50}
								errors={errors?.milestone_id}
								label={'Select Milestone'}
								isLoading={milestoneLoading}
								onChange={dataSchemaHandler}
								options={milestone}
								placeholder='Search Milestone'
								value={dataSchema.milestone_id}
								isDisabled={!milestone}
								name={'milestone_id'}
								grayBg
							/>

							<Input
								type={'date'}
								label={'Due Date'}
								height={50}
								name='due_date'
								onInput={dataSchemaHandler}
								placeholder='Choose from Calendar'
								errors={errors?.due_date}
								defaultValue={dataSchema.due_date}
								format='YYYY-MM-DD'
							/>
							<CounterBox
								handler={dataSchemaHandler}
								label={'Task Time Budget'}
								name={'time_budget'}
								placeholder={'Enter Task Time Budget'}
								height={50}
								value={dataSchema?.time_budget}
								errors={errors?.time_budget}
							/>
							<MultiSelectBox
								name={'team_id'}
								onChange={dataSchemaHandler}
								isDisabled={
									!dataSchema.project_id || projectContactsLoading
										? true
										: false
								}
								value={dataSchema?.team_id}
								options={projectContacts?.filter(item => !item.role.name.includes('Accountant') && !item.role.name.includes('Client'))}
								placeholder={'Search Contact'}
								height={50}
								label={'Assign To'}
								errors={errors?.team_ids}
							/>
						</div>
					</div>

					<div className='grid grid-cols-2 mt-14 gap-5'>
						<button
							disabled={isLoading}
							name='clear'
							type='submit'
							className={`h-11 rounded-[10px] flex items-center justify-center gap-3 text-primaryBlue border border-solid  text-center text-base font-medium ${
								!isLoading && 'border-primaryBlue'
							}`}>
							Submit & Create More
							{isLoading === 'clear' && <Spinner scale={25} />}
						</button>
						<button
							disabled={isLoading}
							name='close'
							type='submit'
							className={`h-11 rounded-[10px] flex items-center justify-center gap-3 border border-solid bg-primaryBlue text-white text-center text-base font-medium${
								!isLoading && 'border-primaryBlue'
							}`}>
							Submit & Close
							{isLoading === 'close' && <Spinner scale={25} />}
						</button>
					</div>
				</form>
			</div>
		</ModalContainer>
	)
}

ModalContainer.propTypes = {
	handler: PropTypes.func.isRequired
}
ModalContainer.defaultProps = {
	handler: () => {}
}

export default AddTaskModal
