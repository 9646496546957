import useFetch from 'hooks/useFetch'
const endPoint = '/team'

export const AddTeamApi = async (router, data) => {
	let response = await useFetch(router, true).post(`${endPoint}`, data)

	return response
}

export const JoinToProjectApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/setMembership`, data)

	return response
}


export const CancelJoinUserToProjectApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/cancelMembership`, data)

	return response
}

export const ArchiveProjectContactApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/setArchivedTeam`, data)

	return response
}