import React from 'react'

function ChatFillIcon() {
	return (
		<svg
			width='19'
			height='19'
			viewBox='0 0 19 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.5175 0.75C14.4875 0.75 18.25 4.82486 18.25 9.48686C18.25 14.8937 13.84 18.25 9.5 18.25C8.065 18.25 6.4725 17.8644 5.195 17.1108C4.74875 16.8391 4.3725 16.6376 3.89125 16.7953L2.12375 17.3211C1.6775 17.4613 1.275 17.1108 1.40625 16.6376L1.9925 14.6746C2.08875 14.403 2.07125 14.1138 1.93125 13.886C1.17875 12.5014 0.75 10.9854 0.75 9.51315C0.75 4.90373 4.43375 0.75 9.5175 0.75ZM13.5163 8.40023C12.895 8.40023 12.3963 8.89973 12.3963 9.52191C12.3963 10.1353 12.895 10.6436 13.5163 10.6436C14.1375 10.6436 14.6363 10.1353 14.6363 9.52191C14.6363 8.89973 14.1375 8.40023 13.5163 8.40023ZM9.4825 8.40023C8.87 8.39146 8.3625 8.89973 8.3625 9.51315C8.3625 10.1353 8.86125 10.6348 9.4825 10.6436C10.1038 10.6436 10.6025 10.1353 10.6025 9.52191C10.6025 8.89973 10.1038 8.40023 9.4825 8.40023ZM5.44875 8.40023C4.8275 8.40023 4.32875 8.89973 4.32875 9.52191C4.32875 10.1353 4.83625 10.6436 5.44875 10.6436C6.07 10.6348 6.56875 10.1353 6.56875 9.52191C6.56875 8.89973 6.07 8.40023 5.44875 8.40023Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(ChatFillIcon)
