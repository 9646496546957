import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const profileApiRedux = createApi({
	reducerPath: 'profileApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		GetUserInfoApi: builder.query({
			query: () => `user/profile`,
			transformResponse: response => response.data
		}),
		GetAllTimezoneApi: builder.query({
			query: () => `user/getAllTimeZones`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					label: item.regionArea,
					value: item.timeZone
				}))
		}),
		GetAllTimezoneShowTimeApi: builder.query({
			query: () => `user/getAllTimeZones`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					label: item.timeZone,
					value: item.timeZone
				}))
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetUserInfoApiQuery,
	useGetAllTimezoneApiQuery,
	useGetAllTimezoneShowTimeApiQuery
} =
	profileApiRedux
