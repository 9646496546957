import React from 'react'

const DashboardIcon = () => {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.8912 0.666992C15.7253 0.666992 17.3253 2.27449 17.3337 5.10866V12.892C17.3337 15.7253 15.7253 17.3337 12.8912 17.3337H5.10866C2.27449 17.3337 0.666992 15.7253 0.666992 12.892V5.10866C0.666992 2.27449 2.27449 0.666992 5.10866 0.666992H12.8912ZM9.41699 4.10866C9.18282 3.96699 8.89949 3.96699 8.67532 4.10866C8.44949 4.24949 8.32533 4.50866 8.34949 4.76699V13.2587C8.39199 13.617 8.69116 13.8837 9.04116 13.8837C9.40032 13.8837 9.69949 13.617 9.73282 13.2587V4.76699C9.76616 4.50866 9.64199 4.24949 9.41699 4.10866ZM5.52532 6.84199C5.30032 6.70032 5.01616 6.70032 4.79199 6.84199C4.56616 6.98366 4.44199 7.24116 4.46699 7.50033V13.2587C4.49949 13.617 4.79949 13.8837 5.15782 13.8837C5.51699 13.8837 5.81616 13.617 5.84949 13.2587V7.50033C5.87532 7.24116 5.74949 6.98366 5.52532 6.84199ZM13.2412 9.86699C13.017 9.72532 12.7337 9.72532 12.5003 9.86699C12.2745 10.0087 12.1503 10.2578 12.1837 10.5253V13.2587C12.217 13.617 12.5162 13.8837 12.8753 13.8837C13.2253 13.8837 13.5245 13.617 13.567 13.2587V10.5253C13.5912 10.2578 13.467 10.0087 13.2412 9.86699Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(DashboardIcon)
