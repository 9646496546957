import PlayIcon from 'components/Icons/PlayIcon'
import React, { useRef, useState } from 'react'

const MessageTypeVideo = () => {
	const [play, setIsPlay] = useState(false)
	const video = useRef()
	return (
		<div className='relative rounded-[10px] sm:w-[263px] w-full  overflow-hidden'>
			<video
				ref={video}
				onPause={()=>setIsPlay(false)}
				className=' w-full max-h-[263px] rounded-[10px] '
				controls={play}
				src='https://aspb2.asset.aparat.com/aparat-video/54b7ffcba265045cd1893aed42f22d5b6923823-360p.mp4?wmsAuthSign=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjQwZTJhYzY1MmFhZjhmMzQ2MjEwNjlkOGZhZGZiNWIxIiwiZXhwIjoxNjczMjc0MjYxLCJpc3MiOiJTYWJhIElkZWEgR1NJRyJ9.770Nd4Aq1i5_kFZI-tIoitOl2BjoWvsxTylLfoM-XHE'></video>
			{!play && (
				<div className='absolute top-0 right-0 bg-opacity-40 w-full h-full bg-black p-2'>
					<span className='text-xs font-medium text-white text-opacity-80 bg-opacity-5 bg-black h-5 px-2 rounded-sm backdrop-blur-sm'>
						38 MB , 20:31
					</span>
					<button
						onClick={() => {
							setIsPlay(true)
							video.current.play()
						}}
						className='absolute w-11 h-11 text-white rounded-full bg-black bg-opacity-40 overflow-hidden top-1/2 right-1/2 flex items-center justify-center translate-x-1/2 -translate-y-1/2'>
						<span className='relative z-[1]'>
							<PlayIcon width={14} height={15} color='currentColor' />
						</span>
						<i className='w-full absolute  backdrop-blur-sm flex h-full'></i>
					</button>
				</div>
			)}
		</div>
	)
}

export default MessageTypeVideo
