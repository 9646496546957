import React, { useEffect, useState } from 'react'
import SubTaskItem from '../../SubTaskItem'
import PlusBoxIcon from 'components/Icons/PlusBoxIcon'
import MagnifierIcon from 'components/Icons/MagnifierIcon'
import AddSubTaskTaskModal from 'components/Common/Modals/AddTaskModal/AddSubTaskModal'
import ListZeroPage from 'components/Common/ListZeroPage'
import { useLazyGetSubtaskByTaskIdApiQuery } from 'redux/api/task'

const Subtasks = ({ parentData }) => {
	const [getSubtask, { data, isFetching, isLoading }] =
		useLazyGetSubtaskByTaskIdApiQuery()
	const [modalHandler, setModalHandler] = useState(false)
	useEffect(() => {
		getSubtask({ taskId: parentData?.id })
	}, [])
	console.log(data);

	return (
		<div>
			<div className='flex  mb-4 gap-[7px]'>
				<button
					onClick={() => setModalHandler(true)}
					className='flex flex-shrink-0 md:h-11 bg-white h-[52px] left-0 z-10 md:relative fixed md:bottom-0 bottom-[75px] md:w-fit md:bg-transparent  w-full md:rounded-[5px] px-6 justify-center gap-1.5 items-center border border-solid border-primaryBlue md:text-primaryBlue text-white font-medium text-base'>
					<span className='md:inline-block hidden'>
						<PlusBoxIcon />
					</span>{' '}
					<span className='-mb-0.5'>New Subtask</span>
				</button>
				<div className='w-full relative'>
					<input
						className='h-11 bg-white px-5 w-full border border-solid border-gray-200 placeholder:text-gray-350 placeholder:text-xs pl-[50px] placeholder:text-opacity-50 rounded-[10px]'
						placeholder='Search Subtasks'
					/>
					<span className='absolute text-gray-350 left-4 top-1/2 -translate-y-1/2'>
						<MagnifierIcon />
					</span>
				</div>
			</div>
			<div
				className={`border border-solid border-gray-200 rounded-[10px] divide-x divide-gray-200 ${
					!data && 'py-3'
				}`}>
				<ListZeroPage
					data={data}
					isLoading={isLoading}
					isFetching={isFetching}>
					{data?.map(item => (
						<SubTaskItem data={item} isStatus={false} />
					))}
				</ListZeroPage>
			</div>
			{modalHandler && (
				<AddSubTaskTaskModal
					parentTask={parentData}
					handler={() => setModalHandler(false)}
				/>
			)}
		</div>
	)
}

export default Subtasks
