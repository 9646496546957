import Spinner from '../Spinner';
import React, { ReactNode } from 'react';


export function Button({
    children,
    inverted,
    className,
    loading,
    ...rest
}) {
   
    return (
        <button className={`relative h-11 w-fit flex items-center justify-center font-medium text-base gap-3 text-white bg-primaryBlue rounded-[10px] px-4 disabled:opacity-20 ${
            className ?? ''
        }`} {...rest}>
            {children}
            {loading && <Spinner inverted={inverted} />}
        </button>
    );
}