import React from 'react'

function ClockIcon({ width = 14, height = 14, size = 1.5 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.99984 12.8337C10.2215 12.8337 12.8332 10.222 12.8332 7.00033C12.8332 3.77866 10.2215 1.16699 6.99984 1.16699C3.77818 1.16699 1.1665 3.77866 1.1665 7.00033C1.1665 10.222 3.77818 12.8337 6.99984 12.8337Z'
				stroke='currentColor'
				stroke-width={size}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M8.75 9.33333L7.34183 7.92517C7.12303 7.70643 7.00007 7.40973 7 7.10033V3.5'
				stroke='currentColor'
				stroke-width={size}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(ClockIcon)
