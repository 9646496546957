import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import XIcon from 'components/Icons/XIcon'
import PlusStrokeIcon from 'components/Icons/PlusStrokeIcon'
import ReactSelect from 'react-select'
import { useWindowSize } from 'hooks/useWindowSize'
import { AddAttachmentApi } from 'services/dashboardServices/attachment'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { fileSize } from 'utils/filesize'
import { useSelector } from 'react-redux'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import {
	useLazyGetSubtaskByTaskIdApiQuery,
	useLazyGetTaskByMilestoneApiQuery
} from 'redux/api/task'
import { Button } from 'components/Common/Button'

const AddAttachmentModal = ({
	modalHandler,
	orgId,
	refreshData,
	data,
	position = ''
}) => {
	const [width] = useWindowSize()
	const [file, setFile] = useState(null)
	const [projects, setProjects] = useState()
	const [projectId, setProjectId] = useState()
	const [taskId, setTaskId] = useState()
	const [milestoneId, setMilestoneId] = useState()
	const [milestone, setMilestone] = useState()
	const [task, setTask] = useState()
	const [subTask, setSubTask] = useState()
	const [loading, setLoading] = useState()
	const navigate = useNavigate()
	const project = useSelector(state => state.project)
	const [
		getMilestone,
		{ data: milestoneList, isLoading: milestonesIsLoading }
	] = useLazyGetAllMilestoneByProjectIdApiQuery()
	const [getTasksList, { data: tasksList, isLoading: tasksIsLoading }] =
		useLazyGetTaskByMilestoneApiQuery()
	const [
		getSubTasksList,
		{ data: subTasksList, isLoading: subTasksIsLoading }
	] = useLazyGetSubtaskByTaskIdApiQuery()

	React.useEffect(() => {
		getMilestone({ projectId: project?.detail?.id })
	}, [])

	const onFileUpload = (e, setFieldValue) => {
		const temp = []
		Array.from(e.target.files).map(item => {
			if (
				item.type === 'application/pdf' ||
				item.type === 'image/jpg' ||
				item.type === 'image/jpeg' ||
				item.type === 'image/png' ||
				item.type === 'image/bmp' ||
				item.type === 'image/gif' ||
				item.type === 'video/mp4' ||
				item.type === 'application/msword' ||
				item.type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				item.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				item.type === 'application/vnd.ms-excel'
			) {
				temp.push(item)
			} else {
				toast.warning(
					`supported file types: jpg/jpeg/png/gif/bmp/mp4/pdf/doc/docx/xlsx/xls`,
					{ delay: 500 }
				)
			}
		})
		setFieldValue('file', temp)
		setFile(temp)
	}

	const saveAttachment = async data => {
		setLoading(true)
		try {
			const response = await AddAttachmentApi(navigate, data)
			if (response.status === 200) {
				toast.success('File successfully Uploaded')
				refreshData()
			} else if (response.status === 422) {
				setLoading(false)
				toast.error(response.data.message)
				return
			}
			setLoading(false)
			modalHandler(false)
		} catch (error) {
			setLoading(false)
			error
				? toast.error(error?.data?.message)
				: toast.error('error in uploading file')
			modalHandler(false)
		}
	}

	React.useEffect(() => {
		milestoneId && getTasksList(milestoneId.id)
	}, [milestoneId])

	React.useEffect(() => {
		console.log(project)
		if (project) {
			const temp = [
				{ value: project?.detail?.id, label: project?.detail?.name }
			]
			setProjects(temp)
		}
	}, [project])

	React.useEffect(() => {
		if (position === 'task') {
			data &&
				setMilestone({
					value: data?.milestone?.id,
					label: data?.milestone?.name
				})
			// if(data && data.parent_id === undefined {
			if (data && !data?.parent_id) {
				setTask({ value: data?.id, label: data?.label })
			} else {
				getSubTasksList({ taskId: data?.parent_id?.id })
			}
		}
		if (position === 'progressReport') {
			data &&
				setMilestone({ value: data?.milestone_id, label: data?.milestone_name })
			data && setTask({ value: data?.task_id, label: data?.task_name })
		}
	}, [data])

	React.useEffect(() => {
		if (subTasksList?.length !== 0) {
			if (position !== '') {
				const subTaskTemp = subTasksList?.filter(item => item.id === data.id)[0]
				subTaskTemp &&
					setSubTask({ value: subTaskTemp?.id, label: subTaskTemp?.label })
			}
		}
	}, [subTasksList])

	return (
		<ModalContainer setShowModal={modalHandler}>
			<div className='md:w-[578px] w-[95vw] bg-white rounded-[10px] px-5 py-6 '>
				<div className='flex items-center mb-7 justify-between'>
					<h6 className='text-lg text-gray-700 font-medium'>Add File</h6>
					<button
						onClick={modalHandler}
						className='text-gray-700 md:w-fit md:h-fit w-10 h-10 flex items-center justify-center md:bg-transparent bg-gray-200 rounded text-opacity-60'>
						<XIcon color='currentColor' width={18} height={18} />
					</button>
				</div>
				<Formik
					initialValues={{
						file: '',
						task_id: '',
						project_id: '',
						milestone_id: '',
						caption: ''
					}}
					onSubmit={(values, { setSubmitting }) => {
						console.log(values)
						if (Object.values(values).some(item => item === '')) {
							values.project_id = projects[0]?.value
							values.milestone_id =
								position === 'task'
									? milestone.value
									: position === 'progressReport'
									? milestone.value
									: milestoneId?.value
							if (position === 'task') {
								values.task_id = !data.parent_id ? task.value : subTask.value
							}
							if (position === 'progressReport') {
								values.task_id = task.value
							}
						}
						saveAttachment(values)
					}}>
					{({ errors, touched, isValidating, setFieldValue }) => (
						<Form>
							<div className='grid grid-cols-2 gap-2'>
								{file ? (
									file.length !== 0 &&
									file?.map((i, index) => (
										<div
											className={`overflow-scroll-y flex-col md:h-24 h-16 border-2 col-span-2  text-gray-400 text-sm gap-3  font-medium  overflow-hidden relative flex items-center justify-center rounded-[10px] ${
												file
													? 'border-solid border-primaryBlue bg-opacity-5 bg-primaryBlue'
													: 'border-dashed bg-gray-300 border-gray-400'
											}`}>
											<div className='w-full h-full'>
												<i className='absolute w-[100%] h-full left-0 top-0 bg-primaryBlue bg-opacity-10'></i>
												<div className='relative z-20 w-full flex px-6 items-center justify-between h-full'>
													<div className='flex items-center'>
														<span className='min-w-[36px] mr-3 bg-white flex min-h-[50px] text-primaryBlue rounded-[5px] border border-primaryBlue border-solid font-medium text-xs items-center justify-center'>
															{i.name.split('.')[1]}
														</span>
														<div>
															<h6 className='text-sm font-medium text-gray-700'>
																{i.name}
															</h6>
															<span className='text-gray-700 text-xs font-medium text-opacity-80'>
																<span className='ml-1'>{`${fileSize(
																	i.size
																)}`}</span>
															</span>
														</div>
													</div>
													<button
														onClick={() => setFile(null)}
														className='text-primaryBlue '>
														<XIcon
															color='currentColor'
															width={18}
															height={18}
														/>
													</button>
												</div>
											</div>
										</div>
									))
								) : (
									<div
										className={`overflow-scroll-y flex-col md:h-24 h-16 border-2 col-span-2  text-gray-400 text-sm gap-3  font-medium  overflow-hidden relative flex items-center justify-center rounded-[10px] ${
											file
												? 'border-solid border-primaryBlue bg-opacity-5 bg-primaryBlue'
												: 'border-dashed bg-gray-300 border-gray-400'
										}`}>
										<PlusStrokeIcon />
										{width <= 768
											? 'Add file'
											: '	Drag Your FIles Here to Upload.'}
										<input
											multiple={true}
											className='absolute w-full h-full left-0 top-0 cursor-pointer opacity-0'
											type={'file'}
											name='file'
											required={true}
											onChange={e => onFileUpload(e, setFieldValue)}
										/>
									</div>
								)}

								{/* <div
									className={`overflow-scroll-y flex-col md:h-24 h-16 border-2 col-span-2  text-gray-400 text-sm gap-3  font-medium  overflow-hidden relative flex items-center justify-center rounded-[10px] ${file
										? 'border-solid border-primaryBlue bg-opacity-5 bg-primaryBlue'
										: 'border-dashed bg-gray-300 border-gray-400'
										}`}>
									{file ? (
										// null
										// <div className=' w-full h-full'>
										// 	<i className='absolute w-[100%] h-full left-0 top-0 bg-primaryBlue bg-opacity-10'></i>
										// 	<div className='relative z-20 w-full flex px-6 items-center justify-between h-full'>
										// 		<div className='flex items-center'>
										// 			<span className='min-w-[36px] mr-3 bg-white flex min-h-[50px] text-primaryBlue rounded-[5px] border border-primaryBlue border-solid font-medium text-xs items-center justify-center'>
										// 				{file.name.split('.')[1]}
										// 			</span>
										// 			<div>
										// 				<h6 className='text-sm font-medium text-gray-700'>
										// 					{file.name}
										// 				</h6>
										// 				<span className='text-gray-700 text-xs font-medium text-opacity-80'>
										// 					<span className='ml-1'>{`${fileSize(file.size)}`}</span>
										// 				</span>
										// 			</div>
										// 		</div>
										// 		<button
										// 			onClick={() => setFile(null)}
										// 			className='text-primaryBlue '>
										// 			<XIcon color='currentColor' width={18} height={18} />
										// 		</button>
										// 	</div>
										// </div>
										file.length !== 0 && file?.map((i, index) => (
										<div className='w-full h-full'>
										<i className='absolute w-[100%] h-full left-0 top-0 bg-primaryBlue bg-opacity-10'></i>
										<div className='relative z-20 w-full flex px-6 items-center justify-between h-full'>
											<div className='flex items-center'>
												<span className='min-w-[36px] mr-3 bg-white flex min-h-[50px] text-primaryBlue rounded-[5px] border border-primaryBlue border-solid font-medium text-xs items-center justify-center'>
													{i.name.split('.')[1]}
												</span>
												<div>
													<h6 className='text-sm font-medium text-gray-700'>
														{i.name}
													</h6>
													<span className='text-gray-700 text-xs font-medium text-opacity-80'>
														<span className='ml-1'>{`${fileSize(i.size)}`}</span>
													</span>
												</div>
											</div>
											<button
												onClick={() => setFile(null)}
												className='text-primaryBlue '>
												<XIcon color='currentColor' width={18} height={18} />
											</button>
										</div>
									</div>))
									) : (
										<>
											<PlusStrokeIcon />
											{width <= 768 ? 'Add file' : '	Drag Your FIles Here to Upload.'}
											<input
												multiple={true}
												className='absolute w-full h-full left-0 top-0 cursor-pointer opacity-0'
												type={'file'}
												name='file'
												required={true}
												onChange={e => onFileUpload(e, setFieldValue)}
											/>
										</>
									)}
								</div> */}
								<div className='custom_select_box bg_gray h-14'>
									<ReactSelect
										isSearchable={false}
										placeholder={'Select Project'}
										name='project_id'
										options={projects}
										isLoading={loading}
										onChange={e => {
											setProjectId(e)
											setFieldValue('project_id', e.value)
										}}
										value={projects}
										isDisabled={true}
									/>
								</div>
								<div className='custom_select_box bg_gray h-14'>
									<ReactSelect
										isSearchable={false}
										placeholder={'Select Milestone'}
										name='milestone_id'
										options={milestoneList}
										isLoading={milestonesIsLoading}
										onChange={e => {
											setMilestoneId(e)
											setFieldValue('milestone_id', e.value)
										}}
										value={
											position === 'task' || position === 'progressReport'
												? milestone
												: milestoneId
										}
										isDisabled={
											position === 'task' || position === 'progressReport'
												? true
												: false
										}
									/>
								</div>
								<div className='custom_select_box bg_gray h-14'>
									<ReactSelect
										isSearchable={false}
										placeholder={'Select Task'}
										name='task_id'
										options={tasksList}
										isLoading={tasksIsLoading}
										onChange={e => {
											position === '' && getSubTasksList({ taskId: e.value })
											setTaskId(e)
											setFieldValue('task_id', e.value)
										}}
										value={
											position === 'task'
												? subTask
													? subTask
													: task
												: position === 'progressReport'
												? task
												: taskId
										}
										isDisabled={
											position === 'task' || position === 'progressReport'
												? true
												: false
										}
									/>
								</div>

								{position === '' && (
									<div className='custom_select_box bg_gray h-14'>
										<ReactSelect
											isSearchable={false}
											placeholder={'Select SubTask'}
											name='subTask_id'
											options={subTasksList}
											isLoading={subTasksIsLoading}
											onChange={e => {
												setFieldValue('task_id', e.value)
											}}
										/>
									</div>
								)}

								<Field
									component='textarea'
									className='col-span-2 font-light text-sm placeholder:text-opacity-40 resize-none px-5 py-2 bg-gray-300 border border-solid border-gray-200 rounded-[10px] h-[151px]'
									name='caption'
									placeholder='Caption'
								/>
							</div>
							<Button
								className='!w-full mt-6'
								type='submit'
								disabled={loading}
								loading={loading}>
								Add
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		</ModalContainer>
	)
}

AddAttachmentModal.propTypes = {
	modalHandler: PropTypes.func.isRequired
}

export default AddAttachmentModal
