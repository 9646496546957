import DefaultOrganizationIcon from 'components/Icons/DefaultOrganizationIcon'
import DefaultProjectIcon from 'components/Icons/DefaultProjectIcon'
import { useColor } from 'hooks/useColor'
import React from 'react'

const DefaultImage = ({
	src,
	size = 36,
	name,
	fontSize = 14,
	className,
	iconSize,
	type = 'user',
	bgColor,
	mainColor
}) => {
	const color = useColor()

	if (src) {
		return (
			<img
				style={{ width: size + 'px', height: size + 'px' }}
				className={` shrink-0 object-cover rounded-[10px] ${className}`}
				src={src}
			/>
		)
	} else {
		if (type === 'organization') {
			return (
				<div
					className='flex items-center justify-center border border-solid border-gray-200 rounded-[10px]'
					style={{
						width: size + 'px',
						height: size + 'px'
					}}>
					<DefaultOrganizationIcon />
				</div>
			)
		} else if (type === 'project') {
			return <DefaultProjectIcon size={iconSize} />
		} else if (type === 'user') {
			return (
				<div
					className={`rounded-[10px] shrink-0 uppercase flex items-center justify-center font-medium ${className}`}
					style={{
						width: size + 'px',
						height: size + 'px',
						fontSize: fontSize + 'px',
						background: bgColor || color.main,
						color: mainColor || color.content
					}}>
					{name && (
						<>
							{name?.split(' ')[0] && name?.split(' ')[0][0]}
							{name?.split(' ')[1] && name?.split(' ')[1][0]}
						</>
					)}
				</div>
			)
		}
	}
}

export default DefaultImage
