import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const organizationAdminApiRedux = createApi({
	reducerPath: 'organizationAdApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllOrganizationsApi: builder.query({
			query: ({  search }) => {
				return {
					url: `organization/getOrganizationList`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
		getOrganizationSummaryApi: builder.query({
			query: (organizationId) => {
				return {
					url: `organization/${organizationId}/summary`
				}
			},
			transformResponse: response => response.data
		}),
		getOrganizationProjectsApi: builder.query({
			query: ({organizationId, search}) => {
				return {
					url: `organization/${organizationId}/projects`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
		getOrganizationMembersApi: builder.query({
			query: ({organizationId, search, archived = 0}) => {
				return {
					url: `organization/${organizationId}/members`,
					params: {
						search,
						archived
					}
				}
			},
			transformResponse: response => response.data
		}),
		getOrganizationReportsApi: builder.query({
			query: ({organizationId, search}) => {
				return {
					url: `organization/${organizationId}/reports`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetAllOrganizationsApiQuery,
	useLazyGetOrganizationSummaryApiQuery,
	useLazyGetOrganizationProjectsApiQuery,
	useLazyGetOrganizationMembersApiQuery,
	useLazyGetOrganizationReportsApiQuery
} = organizationAdminApiRedux
