import React from 'react'
import PropTypes from 'prop-types'
import MessageTypeText from './MessageTypeText'
import ConversationReplyBox from './ConversationReplyBox'
import ReplyArrow from 'components/Icons/ReplyArrow'
import MessageTypeAudio from './MessageTypeAudio'
import MessageTypePhoto from './MessageTypePhoto'
import MessageTypeVideo from './MessageTypeVideo'
import MessageTypeLink from './MessageTypeLink'
import MessageTypeFile from './MessageTypeFile'

const ConversationMessageItem = ({ isMe, type }) => {
	return (
		<div
			className={`flex items-end gap-1.5 ${
				isMe && 'self-end flex-row-reverse'
			}`}>
			{!isMe && (
				<img
					class='w-[37px] h-[37px] rounded-[10px] shrink-0'
					src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Lionel-Messi-Argentina-2022-FIFA-World-Cup_%28cropped%29.jpg/230px-Lionel-Messi-Argentina-2022-FIFA-World-Cup_%28cropped%29.jpg'
				/>
			)}

			<div
				className={`${type === 'text' ? 'sm:w-fit' : 'sm:w-[291px]'} ${
					isMe
						? 'bg-primaryBlue rounded-br-sm text-white'
						: 'bg-white rounded-bl-sm text-gray-700'
				} sm:max-w-[65%] sm:min-w-[291px] w-[238px] rounded-[10px] pt-3 pr-4 pb-1.5 pl-3`}>
				<div className='flex justify-between items-center mb-3'>
					<h6 className={`text-sm font-medium text-center `}>
						{isMe ? 'You' : 'Morteza'}
					</h6>

					<span
						className={` ${
							isMe ? 'bg-white text-primaryBlue' : 'bg-primaryBlue text-white'
						} h-5 px-1 rounded-[5px] text-[10px] font-medium  min-w-[89px] flex items-center justify-center`}>
						Web Developer
					</span>
				</div>
				<ConversationReplyBox isMe={isMe} />
				{type === 'text' ? (
					<MessageTypeText />
				) : type === 'photo' ? (
					<MessageTypePhoto />
				) : type === 'video' ? (
					<MessageTypeVideo />
				) : type === 'file' ? (
					<MessageTypeFile />
				) : type === 'link' ? (
					<MessageTypeLink />
				) : (
					<MessageTypeAudio />
				)}

				<span className='mt-3 text-current font-light text-xs text-opacity-40 block text-right'>
					21:30
				</span>
			</div>

			<button className='text-xs font-medium flex gap-0.5 text-primaryBlue '>
				<ReplyArrow />
				Reply
			</button>
		</div>
	)
}

ConversationMessageItem.propTypes = {
	isMe: PropTypes.bool.isRequired,
	type: PropTypes.string
}

ConversationMessageItem.defaultProps = {
	isMe: false,
	type: 'text'
}

export default ConversationMessageItem
