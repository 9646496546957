import React from 'react'

const AddUserIcon = () => {
	return (
		<svg
			width='18'
			height='16'
			viewBox='0 0 18 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.91675 10.5128C10.3057 10.5128 13.1667 11.0705 13.1667 13.2241C13.1667 15.3778 10.2874 15.9168 6.91675 15.9168C3.52775 15.9168 0.666748 15.3583 0.666748 13.2055C0.666748 11.0519 3.54527 10.5128 6.91675 10.5128ZM14.8326 4.25016C15.2461 4.25016 15.5817 4.59137 15.5817 5.00984V5.99005H16.5843C16.9969 5.99005 17.3334 6.33125 17.3334 6.74973C17.3334 7.1682 16.9969 7.50941 16.5843 7.50941H15.5817V8.49048C15.5817 8.90896 15.2461 9.25016 14.8326 9.25016C14.4199 9.25016 14.0834 8.90896 14.0834 8.49048V7.50941H13.0826C12.669 7.50941 12.3334 7.1682 12.3334 6.74973C12.3334 6.33125 12.669 5.99005 13.0826 5.99005H14.0834V5.00984C14.0834 4.59137 14.4199 4.25016 14.8326 4.25016ZM6.91675 0.0834961C9.21222 0.0834961 11.0523 1.94735 11.0523 4.27253C11.0523 6.59771 9.21222 8.46156 6.91675 8.46156C4.62127 8.46156 2.78122 6.59771 2.78122 4.27253C2.78122 1.94735 4.62127 0.0834961 6.91675 0.0834961Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(AddUserIcon)
