import React, { useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import ErrorDisplay from './ErrorDisplay'
import './TextEditor.scss'

const TextEditor = ({ value, name, onChange, label, height, errors, mode }) => {
	const editorRef = useRef(null)
	const log = () => {
		if (editorRef.current) {
			onChange(name, editorRef.current.getContent())
		}
	}


	return (
		<div className='flex flex-col' style={height && { height: height + 'px' }}>
			{label && (
				<label className='font-light flex text-gray-700 text-sm mb-0.5'>
					{label}
				</label>
			)}
			{mode === 'simple' ?
			<Editor
			apiKey='9bt0tdq480063dsc69gjft97azn9h2ge5duaurfbxjhx0hn6'
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={value}
			init={{
				height: 163,
				menubar: false,
				inline: true,
				plugins: [
				'preview',],
				content_style:
					'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
			}}
			/>
		:
		<Editor
			//mohammad  apiKey='8zr5c9r92psr4cpk9kc56nztirgy7hjay1xva1jgpk8u93kq'
			apiKey='9bt0tdq480063dsc69gjft97azn9h2ge5duaurfbxjhx0hn6'
			onBlur={log}
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={value}
			init={{
				height: 500,
				menubar: false,
				autosave_interval: '5s',
				plugins: [
					'advlist',
					'autolink',
					'lists',
					// 'link',
					'image',
					'charmap',
					'pageBrake',
					'print',
					'preview',
					'anchor',
					'searchreplace',
					'visualblocks',
					'code',
					'fullscreen',
					'insertdatetime',
					'media',
					'table',
					'paste',
					'code',
					'help',
					'wordcount',
					'emoticons',
					'template',
					'codesample',
					'autosave'
				],
				toolbar:
					'undo redo | styles | bold italic underline | alignleft aligncenter alignright alignjustify |' +
					'bullist numlist outdent indent | link image | print preview media fullscreen |' +
					'forecolor backcokor emotions restoredraft',
				content_style:
					'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
					autosave_ask_before_unload: false,
					autosave_prefix: 'tinymce-autosave-{path}{query}-{id}-'
			}}
			/>
			}
			
				<ErrorDisplay error={errors} />

		</div>
	)
}

export default TextEditor
