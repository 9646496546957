import React from 'react'
import ReactDOM from 'react-dom'
// import 'assets/styles/style.scss'
import 'assets/styles/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from 'redux/store'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'

const rootElement = document.getElementById('root')

ReactDOM.render(
	// <ErrorBoundary>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	// </ErrorBoundary>
	rootElement
)

serviceWorkerRegistration.register()
