import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	status: false,
	pauseCounter: false
}

const trackingDetail = createSlice({
	name: 'trackingDetail',
	initialState,
	reducers: {
		trackingStatusHandler: (state, { payload }) => ({
			status: payload
		}),
		pauseHandlerCounter: (state, { payload }) => ({
			pauseCounter: payload,
			status: state.status
		})
	}
})

export const { trackingStatusHandler, pauseHandlerCounter } =
	trackingDetail.actions
export default trackingDetail.reducer
