import useFetch from 'hooks/useFetch'

export const UploadMediaApi = async (router, data) => {
	let formData = new FormData()

	formData.append('subject_id', data.subject_id)
	formData.append('record_id', data.record_id)
	formData.append('file', data.image)
	formData.append('type_id', data.type_id)
	formData.append('target', data.target)
	let response = await useFetch(router, true).post(
		`/media/uploadMedia`,
		formData
	)

	return response
}
