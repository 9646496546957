import ListZeroPage from 'components/Common/ListZeroPage'
import Spinner from 'components/Common/Spinner'
import MagnifierIcon from 'components/Icons/MagnifierIcon'
import PlusBoxIcon from 'components/Icons/PlusBoxIcon'
import NoteGroupBox from 'components/Page/Note/NoteGroupBox'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyGetAllNotesApiQuery } from 'redux/api/note'
import { taskModalHandler } from 'redux/slices/originModals'
import AddNoteOfTaskDetailModal from './AddNoteOfTaskDetailModal'

const TaskDetailNoteModal = ({ parentData }) => {
	const [getNotes, { data, isFetching, isLoading }] =
		useLazyGetAllNotesApiQuery()
	const [search, setSearch] = useState(undefined)
	const [modalCondition, setModalCondition] = useState(false)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!data) {
			getNotes({ search: search, taskId: parentData.id })
		} else {
			const search = setTimeout(() => {
				getNotes({ search: search, taskId: parentData.id })
			}, 300)
			return () => clearTimeout(search)
		}
	}, [search])

	return (
		<>
			<div>
				<div className='flex  mb-4 gap-[7px]'>
					<button
						onClick={() => setModalCondition(true)}
						className='flex flex-shrink-0 md:h-11 bg-white h-[52px] left-0 z-10 md:relative fixed md:bottom-0 bottom-[75px] md:w-fit md:bg-transparent  w-full md:rounded-[5px] px-6 justify-center gap-1.5 items-center border border-solid border-primaryBlue md:text-primaryBlue text-white font-medium text-base'>
						<span className='md:inline-block hidden'>
							<PlusBoxIcon />
						</span>{' '}
						<span className='-mb-0.5'>New Note</span>
					</button>
					<div className='w-full relative'>
						<input
							onInput={e => setSearch(e.target.value)}
							className='h-11 bg-white px-5 w-full placeholder:text-gray-350 placeholder:text-xs pl-[50px] placeholder:text-opacity-50 rounded-[10px]'
							placeholder='Search Milestones'
						/>
						<span className='absolute text-gray-350 left-4 top-1/2 -translate-y-1/2'>
							<MagnifierIcon />
						</span>
					</div>
				</div>
				<ListZeroPage data={data} isLoading={isLoading} isFetching={isFetching}>
					<div className='grid gap-8'>
						<NoteGroupBox inModal={true} data={data} />
					</div>
				</ListZeroPage>
			</div>

			{modalCondition && (
				<AddNoteOfTaskDetailModal
					updateList={() => getNotes({ search: search, taskId: parentData.id })}
					taskDetail={parentData}
					modalHandler={() => setModalCondition(false)}
				/>
			)}
		</>
	)
}

export default TaskDetailNoteModal
