import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	log: false,
	task: false,
	subTask: false,
	milestone: false,
	note: false,
	defaultValue: null
}

const originModalCondition = createSlice({
	name: 'originModalCondition',
	initialState,
	reducers: {
		logModalHandler: (state, { payload }) => ({ log: payload }),
		milestoneModalHandler: (state, { payload }) => ({ milestone: payload }),
		taskModalHandler: (state, { payload }) => ({ task: payload }),
		subTaskModalHandler: (state, { payload }) => ({ subTask: payload }),
		noteModalHandler: (state, { payload }) => ({ note: payload }),
		editModalHandler: (state, { payload: { modal, value } }) => ({
			defaultValue: value,
			[modal]: true
		}),
	}
})

export const {
	logModalHandler,
	taskModalHandler,
	milestoneModalHandler,
	noteModalHandler,
	editModalHandler,
	subTaskModalHandler,
} = originModalCondition.actions
export default originModalCondition.reducer
