import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import TrashcanIcon from 'components/Icons/TrashcanIcon'
import SendMessageIcon from 'components/Icons/SendMessageIcon'
import PauseIcon from 'components/Icons/PauseIcon'
import PlayIcon from 'components/Icons/PlayIcon'
import MicIcon from 'components/Icons/MicIcon'

const ConversationVoiceRecorder = ({
	voice,
	removeVoice,
	recordingTime,
	startRecording,
	stopRecording
}) => {
	const audioRef = useRef(new Audio(voice))
	const intervalRef = useRef()
	const playerSize = useRef()

	const [trackProgress, setTrackProgress] = useState(0)
	const [isPlaying, setIsPlaying] = useState(false)
	const [playerWidth, setPlayerWidth] = useState([])

	const { duration } = audioRef.current

	useEffect(() => {
		if (isPlaying) {
			audioRef.current.play()
			startTimer()
		} else {
			clearInterval(intervalRef.current)
			audioRef.current.pause()
		}
	}, [isPlaying])

	useEffect(() => {
		let playerCountWidth = (playerSize.current.offsetWidth / 19).toFixed()
		let count = []
		for (let index = 0; index < playerCountWidth; index++) {
			count.push(index)
		}
		setPlayerWidth(count)
		// Pause and clean up on unmount
		return () => {
			audioRef.current.pause()
			clearInterval(intervalRef.current)
		}
	}, [])

	const startTimer = () => {
		// Clear any timers already running
		clearInterval(intervalRef.current)
		intervalRef.current = setInterval(() => {
			if (audioRef.current.ended) {
				setIsPlaying(false)
				setTrackProgress(0)
			} else {
				setTrackProgress(audioRef.current.currentTime)
			}
		}, [1000])
	}

	const onScrub = value => {
		// Clear any timers already running
		clearInterval(intervalRef.current)
		audioRef.current.currentTime = value
		setTrackProgress(audioRef.current.currentTime)
	}

	const onScrubEnd = () => {
		// If not already playing, start
		if (!isPlaying) {
			setIsPlaying(true)
		}
		startTimer()
	}

	const timeHandler = _time => {
		let minutes = Math.floor(_time / 60)
		let second = _time % 60 < 10 ? `0${_time % 60}` : _time % 60
		if (second) {
			return minutes + ':' + second
		} else {
			return '--:--'
		}
	}
	return (
		<div className='w-full rounded-md bg-gray-200 pl-4 flex items-center justify-between ml-2'>
			<button onClick={() => removeVoice(null)}>
				<TrashcanIcon color='#212121' opacity={0.6} />
			</button>
			{isPlaying && (
				<span className='text-xs -mb-1 flex-shrink-0  font-medium text-gray-700 text-opacity-60  ml-3'>
					{timeHandler(trackProgress.toFixed())}
				</span>
			)}

			<div
				className={`mx-3 flex gap-3 w-full h-[30px]  items-center ${
					isPlaying ? '' : 'bg-white px-3 rounded-[5px]'
				}`}>
				{!isPlaying && (
					<button onClick={() => setIsPlaying(true)} className='opacity-60'>
						<PlayIcon color={'#212121'} />
					</button>
				)}

				<div ref={playerSize} className='w-full flex-1 '>
					<div className='relative w-full h-4 overflow-hidden'>
						<input
							type='range'
							value={trackProgress}
							step='1'
							min='0'
							max={recordingTime ? recordingTime : `${recordingTime}`}
							className='w-full absolute z-[1] cursor-pointer left-0 opacity-0'
							onChange={e => onScrub(e.target.value)}
							onMouseUp={onScrubEnd}
							onKeyUp={onScrubEnd}
						/>

						<div className='absolute'>
							<ul
								className={`flex gap-0.5 ${
									trackProgress !== 0 && 'opacity-50'
								}`}>
								{playerWidth.map((item, index) => (
									<li
										key={'audio' + index}
										className='flex gap-0.5 items-center'>
										<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-2'></i>
										<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-3'></i>
										<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-4'></i>
										<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-3'></i>
										<i className='flex border-l-2 border-solid border-gray-700 border-opacity-40 h-2'></i>
									</li>
								))}
							</ul>
							<ul
								style={{
									width: trackProgress
										? `${(trackProgress * 100) / recordingTime}%`
										: 0
								}}
								className='flex gap-0.5 left-0 top-0 overflow-hidden absolute'>
								{playerWidth.map((item, index) => (
									<li
										key={'audio' + index}
										className='flex gap-0.5 items-center'>
										<i className='flex border-l-2 border-solid border-primaryBlue h-2'></i>
										<i className='flex border-l-2 border-solid border-primaryBlue h-3'></i>
										<i className='flex border-l-2 border-solid border-primaryBlue h-4'></i>
										<i className='flex border-l-2 border-solid border-primaryBlue h-3'></i>
										<i className='flex border-l-2 border-solid border-primaryBlue h-2'></i>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				{!isPlaying && (
					<span className='text-xs font-medium -mb-[7px] flex text-gray-700 text-opacity-40'>
						{timeHandler(recordingTime.toFixed())}
					</span>
				)}
			</div>

			<div className='flex-shrink-0 flex gap-4'>
				{isPlaying ? (
					<button onClick={() => setIsPlaying(false)} className='opacity-60'>
						<PauseIcon color='#212121' />
					</button>
				) : (
					<button
						onMouseDown={() => startRecording()}
						onMouseLeave={() => stopRecording()}
						onMouseUp={() => stopRecording()}
						className='text-primaryBlue'>
						<MicIcon />
					</button>
				)}

				<button
					onClick={() => removeVoice(null)}
					className='h-[37px] flex-shrink-0  w-[37px] bg-primaryBlue  rounded-[5px] flex items-center justify-center '>
					<SendMessageIcon />
				</button>
			</div>
		</div>
	)
}

ConversationVoiceRecorder.propTypes = {}

export default ConversationVoiceRecorder
