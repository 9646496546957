import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const meetingApiRedux = createApi({
	reducerPath: 'meetingApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllMeetingsApi: builder.query({
			query: () => {
				return {
					url: 'meeting'
				}
			},
			transformResponse: response =>
				response?.data?.map(item => ({
					...item,
					value: item.id,
					label: item.title
				}))
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const { useLazyGetAllMeetingsApiQuery } = meetingApiRedux
