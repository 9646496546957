import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const projectApiRedux = createApi({
	reducerPath: 'projectApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getProjectApi: builder.query({
			query: id => `project/${id}`,
			transformResponse: response => response.data
		}),
		getProjectTaskAndMilestoneApi: builder.query({
			query: id => `project/getProjectElements/${id}`,
			transformResponse: response => response.data
		}),
		getProjectsByOrgIdApi: builder.query({
			query: () =>
				`project/getProjectsByOrgId/${
					JSON.parse(localStorage.organization).id
				}`,
			transformResponse: response =>
					response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getUserProjectApi: builder.query({
			query: ({ search }) => {
				return {
					url: `project/getUserProjects`,
					params: { search }
				}
			},
			transformResponse: response => response.data
		}),
		getProjectInfoApi: builder.query({
			query: id => `project/getProjectInfo/${id}`,
			transformResponse: response => response.data
		}),
		getAllProjectsApi: builder.query({
			query: () => `project`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getProjectDetailApi: builder.query({
			query: id => `project/${id}`
		}),
		getProjectAsTemplateApi: builder.query({
			query: search => `project/getTemplates?search=${search}`,
			transformResponse: response => response.data
		}),
		getPendingProjects: builder.query({
			query: id => `project/getUserPendingProjects`,
			transformResponse: response => response.data
		}),
		getProjectByUserIdApi: builder.query({
			query: ({ userId }) => `project/getProjectsByContactId/${userId}`,
			transformResponse: response => response.data
		}),
		getProjectsPositionsApi: builder.query({
			query: ({projectId}) => `project/getPositions/${projectId}`,
			transformResponse: response => response.data
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetAllProjectsApiQuery,
	useGetProjectsByOrgIdApiQuery,
	useLazyGetProjectsByOrgIdApiQuery,
	useGetUserProjectApiQuery,
	useLazyGetUserProjectApiQuery,
	useLazyGetAllProjectsApiQuery,
	useGetProjectDetailApiQuery,
	useGetProjectApiQuery,
	useGetProjectAsTemplateApiQuery,
	useLazyGetProjectAsTemplateApiQuery,
	useGetProjectInfoApiQuery,
	useLazyGetPendingProjectsQuery,
	useGetProjectByUserIdApiQuery,
	useGetProjectTaskAndMilestoneApiQuery,
	useGetProjectsPositionsApiQuery
} = projectApiRedux
