import React, { useEffect, useState } from 'react'
import NoteGroupBoxItem from './NoteGroupBoxItem'
import PropTypes from 'prop-types'
import moment from 'moment'

const NoteGroupBox = ({
	NoteModalHandler,
	data,
	inModal,
	isAggregate = false
}) => {
	const [mainData, setMainData] = useState('loading')
	const [date, setDate] = useState([])
	useEffect(() => {
		groupHandler()
	}, [data])

	const groupHandler = () => {
		if (data && data.length) {
			const dataWithDate = data.map(item => ({
				...item,
				date: item.created_at.split('T')[0]
			}))
			const result = dataWithDate.reduce((prev, next) => {
				;(prev[next.date] = prev[next.date] || []).push(next)

				return prev
			}, {})
			setDate(Object.keys(result).map(item => item))
			setMainData(result)
		} else {
			setMainData([])
		}
	}
	return (
		<div className='grid gap-5'>
			{Array.isArray(mainData) ? (
				<>empty</>
			) : (
				<>
					{date.map(item => (
						<div>
							<h3 className='text-gray-700 font-light text-opacity-50 mb-3 text-xs'>
								{moment(item).format('DD MMMM YYYY')}
							</h3>
							<div
								className={`grid gap-2 ${
									inModal
										? 'lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'
										: ' xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'
								}`}>
								{mainData[item].map((item, index) => (
									<NoteGroupBoxItem
										isAggregate={isAggregate}
										inModal={inModal}
										key={'note_item' + index}
										data={item}
										NoteModalHandler={NoteModalHandler}
									/>
								))}
							</div>
						</div>
					))}
				</>
			)}
		</div>
	)
}

NoteGroupBox.propTypes = {
	data: PropTypes.array,
	NoteModalHandler: PropTypes.func.isRequired,
	inModal: PropTypes.bool
}
NoteGroupBox.defaultProps = {
	NoteModalHandler: null,
	data: [],
	inModal: false
}

export default NoteGroupBox
