import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	progress: null,
	triggerRefreshProgressList: { flag: false, type: '' }
}

const Progress = createSlice({
	name: 'Progress',
	initialState,
	reducers: {
		setProgress: (state, { payload }) => ({
			progress: payload
		}),
		refreshProgressList: (state, action) => ({
			triggerRefreshProgressList: action.payload
		})
	}
})

export const { setProgress, refreshProgressList } = Progress.actions
export default Progress.reducer
