import React from 'react'
import TUSBlack from 'assets/images/tus-black.png'
import SEOHeader from 'components/SEOHeader/SEOHeader'
import { Link, useNavigate } from 'react-router-dom'

const TermsOfService = () => {
	const navigate = useNavigate()
	return (
		<section>
			<SEOHeader title='Press Release' />
			<div className='bg-gray-300 pt-16 md:pt-[104px] pb-14 relative overflow-hidden'>
				<div className='absolute top-24 -right-9 md:-top-10 md:-right-4 w-[188px] h-[188px] md:w-[260px] md:h-[260px] bg-[#3485f01a] blur-[100px]'></div>
				<div className='max-w-[1224px] px-4 mx-auto'>
					<div
						className='mb-8 md:mb-[45px] relative cursor-pointer'
						onClick={() => navigate('/')}>
						<div className='absolute top-0 md:top-1/2 -translate-y-1/2 left-0 w-[245px] h-[245px] md:w-[408px] md:h-[408px] bg-[#3485f01a] blur-[100px]'></div>
						<img
							src={TUSBlack}
							alt='teamupspace-logo'
							className='lg:w-32 md:w-[340px] h-auto pl-4 relative'
						/>
					</div>
					<div className='lg:hidden flex flex-col items-start gap-[5px] mb-[15px]'>
						<div className='md:flex lg:hidden text-[28px] md:min-w-36'>
							<p className='font-bold'>TeamUp Space User Agreement</p>
						</div>
					</div>
					<div className='p-[60px] bg-white rounded-[20px] relative z-1'>
						<div className='hidden lg:flex text-4xl md:min-w-36 mt-[36px]'>
							<p className='text-2xl'>TeamUp Space User Agreement</p>
						</div>
						<div className=' flex flex-col justify-between items-start gap-4 relative pt-6 md:pt-36 md:text-xl'>
							<span className='md:text-xl md:w-[800px]'>
								Please read this User Agreement carefully before using our
								Services. These terms are a contract between us and our users.
								Your use of our Services is also subject to our Privacy Policy
								(Appendix 1), which covers how we collect, use, share, and store
								your personal information.
							</span>
							<span className='font-extrabold md:text-xl md:w-[800px] text-[#3485f0]'>
								A. Definitions
							</span>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								1- Contract
							</span>
							<p className='md:text-xl md:w-[800px] '>
								You agree that by registering in TeamUp Space or accessing or
								using our services (described below), you agree to enter into a
								legally binding contract with TeamUp Space (even if you are
								using our Services on behalf of a company). If you do not agree
								to this contract (“Contract” or “User Agreement”), do not click
								register and do not access or otherwise use any of our Services.
								If you wish to terminate this contract, at any time you can do
								so by closing your account and no longer accessing or using our
								Services.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								2- Services
							</span>
							<p className='md:text-xl md:w-[800px] '>
								The “Service” refers to the applications, software, products,
								and services provided by TeamUp Space. Registered users of our
								Services are “Members” and unregistered users are “Visitors”.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								3- Account
							</span>
							<p className='md:text-xl md:w-[800px] '>
								An "Account" represents your legal relationship with TeamUp
								Space. A “Personal Account” represents an individual User’s
								authorization to log in to and use the Service and serves as a
								User’s identity on TeamUp Space. “Organizations” are shared
								workspaces that may be associated with a single entity or with
								one or more Users where multiple Users can collaborate across
								many projects at once. A Personal Account can be a member of any
								number of Organizations.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								4- TeamUp Space
							</span>
							<p className='md:text-xl md:w-[800px] '>
								“TeamUp Space”, “We”, and “Us” refer to TeamUp Space, Inc., as
								well as our affiliates, directors, subsidiaries, contractors,
								licensors, officers, agents, and employees.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								5- Members and Users
							</span>
							<p className='md:text-xl md:w-[800px] '>
								“The User”, “You”, and “Your” refer to the individual person,
								company, or organization that has visited or is using the
								Website or Service; that accesses or uses any part of the
								Account; or that directs the use of the Account in the
								performance of its functions. A User must be at least 16 years
								of age.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								6- Change
							</span>
							<p className='md:text-xl md:w-[800px] '>
								We may modify this Contract, our Privacy Policy and our Cookies
								Policy from time to time. If we make material changes to it, we
								will provide you with notice through our Services, or by other
								means, to provide you with the opportunity to review the changes
								before they become effective. If you object to any changes, you
								may close your account. Your continued use of our Services after
								we publish or send a notice about our changes to these terms
								means that you consent to the updated terms as of their
								effective date.
							</p>
							<span className='font-extrabold md:text-xl md:w-[800px] text-[#3485f0]'>
								B. Terms of Use
							</span>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>1- Account Controls</p>
								<li className='ml-[50px]'>
									Users: Subject to these Terms, you retain ultimate
									administrative control over your Personal Account and the
									Content within it.
								</li>
								<li className='ml-[50px]'>
									Organizations: The "owner" of an organization that was created
									under these Terms has ultimate administrative control over
									that Organization and the Content within it. Within the
									Service, an owner can manage User access to the Organization’s
									data and projects. An organization may have multiple owners,
									but there must be at least one Personal Account designated as
									the owner of an organization. If you are the owner of an
									Organization under these Terms, we consider you responsible
									for the actions that are performed on or through that
									Organization.
								</li>
							</ul>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>2- Account Requirements</p>
								<li className='ml-[50px]'>
									You must provide a valid email address to complete the signup
									process. Any other information requested, such as your real
									name, is optional, unless you are accepting these terms on
									behalf of a legal entity (in which case we need more
									information about the legal entity) or if you opt for a paid
									Account, in which case additional information will be
									necessary for billing purposes.
								</li>
								<li className='ml-[50px]'>
									You must be a human to create an Account. Accounts registered
									by "bots" or other automated methods are not permitted. We do
									permit machine accounts:
								</li>
								<li className='ml-[50px]'>
									You must be a human to create an Account. Accounts registered
									by "bots" or other automated methods are not permitted. We do
									permit machine accounts:
								</li>
								<li className='ml-[50px]'>
									One person or legal entity may maintain no more than one free
									Account.
								</li>
								<li className='ml-[50px]'>
									You must be 16 or older. TeamUp Space does not target our
									Service to people under 16, and we do not permit any Users
									under 16 on our Service. If we learn of any User under the age
									of 16, we will terminate that User’s Account immediately. If
									you are a resident of a country outside the United States,
									your country’s minimum age may be older; in such a case, you
									are responsible for complying with your country’s laws.
								</li>
								<li className='ml-[50px]'>
									Your login may only be used by one person — i.e., a single
									login may not be shared by multiple people. A paid
									Organization may only provide access to as many Personal
									Accounts as your subscription allows.
								</li>
								<li className='ml-[50px]'>
									You may not use TeamUp Space in violation of export control or
									sanctions laws of the United States or any other applicable
									jurisdiction. You may not use TeamUp Space if you are or are
									working on behalf of a Specially Designated National (SDN) or
									a person subject to similar blocking or denied party
									prohibitions administered by a U.S. government agency. TeamUp
									Space may allow persons in certain sanctioned countries or
									territories to access certain TeamUp Space services pursuant
									to U.S. government authorizations. For more information,
									please see our Export Controls policy.
								</li>
							</ul>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>3- Account Security</p>
								<li className='ml-[50px]'>
									You are responsible for all content posted and activity that
									occurs under your Account (even when content is posted by
									others who have Accounts under your Account).
								</li>
								<li className='ml-[50px]'>
									You are responsible for maintaining the security of your
									Account and password. TeamUp Space cannot and will not be
									liable for any loss or damage from your failure to comply with
									this security obligation.
								</li>
								<li className='ml-[50px]'>
									You will promptly notify TeamUp Space by contacting us through
									the TeamUp Space Support call or email, if you become aware of
									any unauthorized use of, or access to, our Service through
									your Account, including any unauthorized use of your password
									or Account.
								</li>
							</ul>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>4- Acceptable Use</p>
								<p>
									Your use of the Website and Service must not violate any
									applicable laws, including copyright or trademark laws, export
									control or sanctions laws, or other laws in your jurisdiction.
									You are responsible for making sure that your use of the
									Service is in compliance with laws and any applicable
									regulations. You also agree that you will comply with these
									Community Policies of us:
								</p>
								<li className='ml-[50px]'>
									<ul className='list-disc'>
										You will:
										<li className='ml-[50px]'>
											Comply with all applicable laws, including, without
											limitation, privacy laws, intellectual property laws,
											anti-spam laws, export control laws, tax laws, and
											regulatory requirements;
										</li>
										<li className='ml-[50px]'>
											Provide accurate information to us and keep it updated;
										</li>
										<li className='ml-[50px]'>
											Use your real name on your profile
										</li>
										<li className='ml-[50px]'>
											Use the Services in a professional manner.
										</li>
									</ul>
								</li>
								<li className='ml-[50px]'>
									<ul className='list-disc'>
										You will not:
										<li className='ml-[50px]'>
											Create a false identity on TeamUp Space, misrepresent your
											identity, create a member profile for anyone other than
											yourself (a real person), or use or attempt to use
											another’s account;
										</li>
										<li className='ml-[50px]'>
											Develop, support or use software, devices, scripts, robots
											or any other means or processes (including crawlers,
											browser plugins and add-ons or any other technology) to
											scrape the Services or otherwise copy profiles and other
											data from the Services;
										</li>
										<li className='ml-[50px]'>
											Override any security feature or bypass or circumvent any
											access controls or use limits of the Service (such as caps
											on keyword searches or profile views);
										</li>
										<li className='ml-[50px]'>
											Copy, use, disclose or distribute any information obtained
											from the Services, whether directly or through third
											parties (such as search engines), without the consent of
											TeamUp Space;
										</li>
										<li className='ml-[50px]'>
											Disclose information that you do not have the consent to
											disclose (such as confidential information of others
											(including your employer));
										</li>
										<li className='ml-[50px]'>
											Violate the intellectual property rights of others,
											including copyrights, patents, trademarks, trade secrets
											or other proprietary rights. For example, do not copy or
											distribute (except through the available sharing
											functionality) the content of others without their
											permission, which they may give by posting under a
											Creative Commons license;
										</li>
										<li className='ml-[50px]'>
											Violate the intellectual property or other rights of
											TeamUp Space, including, without limitation, (i) copying
											or distributing our learning videos or other materials or
											(ii) copying or distributing our technology, unless it is
											released under open-source licenses; (iii) using the word
											“TeamUp Space” or our logos in any business name, email,
											or URL except as provided in the Brand Guidelines;
										</li>
										<li className='ml-[50px]'>
											Create any content that contains software viruses, worms,
											or any other harmful code;
										</li>
										<li className='ml-[50px]'>
											Reverse engineer, decompile, disassemble, decipher or
											otherwise attempt to derive the source code for the
											Services or any related technology that is not open
											source;
										</li>
										<li className='ml-[50px]'>
											Imply or state that you are affiliated with or endorsed by
											TeamUp Space without our express consent (e.g.,
											representing yourself as an accredited TeamUp Space
											trainer);
										</li>
										<li className='ml-[50px]'>
											Rent, lease, loan, trade, sell/re-sell or otherwise
											monetize the Services or related data or access to the
											same, without TeamUp Space’s consent;
										</li>
										<li className='ml-[50px]'>
											Deep-link to our Services for any purpose other than to
											promote your profile or projects and spaces on our
											Services, without TeamUp Space’s consent;
										</li>
										<li className='ml-[50px]'>
											Use bots or other automated methods to access the
											Services, add or download contacts, send or redirect
											messages;
										</li>
										<li className='ml-[50px]'>
											Monitor the Services’ availability, performance or
											functionality for any competitive purpose;
										</li>
										<li className='ml-[50px]'>
											Engage in “framing,” “mirroring,” or otherwise simulating
											the appearance or function of the Services;
										</li>
										<li className='ml-[50px]'>
											Overlay or otherwise modify the Services or their
											appearance (such as by inserting elements into the
											Services or removing, covering, or obscuring an
											advertisement included on the Services);
										</li>
										<li className='ml-[50px]'>
											Interfere with the operation of, or place an unreasonable
											load on, the Services (e.g., spam, denial of service
											attack, viruses, gaming algorithms); and/or
										</li>
										<li className='ml-[50px]'>
											Violate the Professional Community Policies or any
											additional terms concerning a specific Service that are
											provided when you sign up for or start using such Service.
										</li>
									</ul>
								</li>
							</ul>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>5- Limitation of Liability</p>
								<p>
									You understand and agree that we will not be liable to you or
									any third party for any loss of profits, use, goodwill, or
									data, or for any incidental, indirect, special, consequential
									or exemplary damages, however arising, that result from
								</p>
								<li className='ml-[50px]'>
									the use, disclosure, or display of your User-Generated
									Content;
								</li>
								<li className='ml-[50px]'>
									your use or inability to use the Service;
								</li>
								<li className='ml-[50px]'>
									any modification, price change, suspension or discontinuance
									of the Service;
								</li>
								<li className='ml-[50px]'>
									the Service generally or the software or systems that make the
									Service available;
								</li>
								<li className='ml-[50px]'>
									unauthorized access to or alterations of your transmissions or
									data;
								</li>
								<li className='ml-[50px]'>
									statements or conduct of any third party on the Service;
								</li>
								<li className='ml-[50px]'>
									any other user interactions that you input or receive through
									your use of the Service; or
								</li>
								<li className='ml-[50px]'>
									any other matter relating to the Service.
								</li>
								<p>
									Our liability is limited whether or not we have been informed
									of the possibility of such damages, and even if a remedy set
									forth in this Agreement is found to have failed of its
									essential purpose. We will have no liability for any failure
									or delay due to matters beyond our reasonable control.
								</p>
							</ul>
							<span className='font-extrabold md:text-xl md:w-[800px] text-[#3485f0]'>
								C. User-Generated Content
							</span>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								1. Responsibility for User-Generated Content
							</span>
							<p className='md:text-xl md:w-[800px] '>
								You may create or upload User-Generated Content while using the
								Service. You are solely responsible for the content of, and for
								any harm resulting from, any User-Generated Content that you
								post, upload, link to or otherwise make available via the
								Service, regardless of the form of that Content. We are not
								responsible for any public display or misuse of your
								User-Generated Content.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								2- Removal of the Content
							</span>
							<p className='md:text-xl md:w-[800px] '>
								We have the right to refuse or remove any User-Generated Content
								that, in our sole discretion, violates any laws or TeamUp Space
								terms or policies.
							</p>
							<ul className='list-disc'>
								<p className='text-[#3485f0]'>
									3. Ownership of Content, Right to Post, and License Grants
								</p>
								<li className='ml-[50px]'>
									You own the content and information that you submit to the
									Services, and you are only granting Us and our affiliates the
									following non-exclusive license:  A worldwide, transferable
									and sublicensable right to use, copy, modify, distribute,
									publish and process, information and content that you provide
									through our Services and the services of others, without any
									further consent, notice and/or compensation to you or others.
									Although you can end this license for specific content by
									deleting such content from the Services, or generally by
									closing your account, except (a) to the extent you shared it
									with others as part of the Service and they copied, re-shared
									it or stored it and (b) for the reasonable time it takes to
									remove from backup and other systems.
								</li>
								<li className='ml-[50px]'>
									Because you own your content and information and we only have
									non-exclusive rights to it, you may choose to make it
									available to others, including under the terms of a Creative
									Commons license.
								</li>
								<li className='ml-[50px]'>
									You and We agree that we may access, store, process and use
									any information and personal data that you provide in
									accordance with, the terms of the Privacy Policy and your
									choices (including settings).
								</li>
							</ul>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								4. Private Content
							</span>
							<p className='md:text-xl md:w-[800px] '>
								Some Entities (for example space, project, …) may be defined as
								private by user, which allow the User to control access to
								Content.  TeamUp Space considers the contents of private
								entities to be confidential to you. TeamUp Space will protect
								the contents of private entities from unauthorized use, access,
								or disclosure in the same manner that we would use to protect
								our own confidential information of a similar nature and in no
								event with less than a reasonable degree of care.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								5. Limits
							</span>
							<p className='md:text-xl md:w-[800px] '>
								TeamUp Space reserves the right to limit your use of the
								Services, including the number of your connections and your
								ability to contact other Members. TeamUp Space reserves the
								right to restrict, suspend, or terminate your account if you
								breach this Contract or the law or are misusing the Services.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								6- Automated Processing Right
							</span>
							<p className='md:text-xl md:w-[800px] '>
								We use the information and data that you provide and that we
								have about Members to make recommendations for connections,
								content and features that may be useful to you. For example, we
								use data and information about you to recommend projects to you
								and your profile to project owners.
							</p>
							<span className='font-extrabold md:w-[800px] text-[#3485f0]'>
								D. Intellectual Property Rights
							</span>
							<p className='md:text-xl md:w-[800px] '>
								TeamUp Space reserves all of its intellectual property rights in
								the Services. Trademarks and logos used in connection with the
								Services are the trademarks of their respective owners. TeamUp
								Space logo and other TeamUp Space trademarks, service marks,
								graphics and logos used for our Services are trademarks or
								registered trademarks of TeamUp Space.
							</p>
							<span className='font-extrabold md:text-xl md:w-[800px] text-[#3485f0]'>
								E. Payment
							</span>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								1. Pricing
							</span>
							<p className='md:text-xl md:w-[800px] '>
								Our pricing and payment terms are available at
								Teamupspace.com/pricing. If you agree to a subscription price,
								that will remain your price for the duration of the payment
								term; however, prices are subject to change at the end of a
								payment term.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								2. Responsibility for Payment
							</span>
							<p className='md:text-xl md:w-[800px] '>
								You are responsible for all fees, including taxes, associated
								with your use of the Service. By using the Service, you agree to
								pay TeamUp Space any charge incurred in connection with your use
								of the Service. If you dispute the matter, contact us through
								the TeamUp Space Support call or email. You are responsible for
								providing us with a valid means of payment for paid Accounts.
								Free Accounts are not required to provide payment information.
							</p>
							<ul className='list-disc'>
								<p className='text-[#3485f0] font-extrabold'> F. Termination</p>
								<p>
									Both you and TeamUp Space may terminate this Contract at any
									time with notice to the other. On termination, you lose the
									right to access or use the Services. The following shall
									survive termination:
								</p>
								<li className='ml-[50px]'>
									Our rights to use and disclose your feedback;
								</li>
								<li className='ml-[50px]'>
									Members and/or Visitors’ rights to further re-share content
									and information you shared through the Services;
								</li>
								<li className='ml-[50px]'>
									Any amounts owed by either party prior to termination remain
									owed after termination.
								</li>
							</ul>
							<span className='font-extrabold md:text-xl md:w-[800px] text-[#3485f0]'>
								Appendix 1. Privacy Policy
							</span>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								1- Related Services
							</span>
							<p className='md:text-xl md:w-[800px] '>
								This Privacy Policy applies to all our services.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								2- Data We Collect
							</span>
							<ul className='list-disc'>
								<p>We can collect all data you provide to Us, including:</p>
								<li className='ml-[50px]'>
									Registration information and payment (e.g., credit card) and
									billing information
								</li>
								<li className='ml-[50px]'>Profile information</li>
								<li className='ml-[50px]'>
									Content you generate within Services
								</li>
								<li className='ml-[50px]'>
									Data from others, that generated about you
								</li>
								<li className='ml-[50px]'>
									Service use data, including logs of usage data when you visit
									or otherwise use our Services
								</li>
								<li className='ml-[50px]'>
									Cookies and similar technologies to collect data (e.g., device
									IDs) to recognize you and your device(s) across different
									services and devices where you have engaged with our Services.
								</li>
								<li className='ml-[50px]'>
									Your Device and Location information including: URL of both
									the site you came from and the one you go to and the time of
									your visit, information about your network and device (e.g.,
									IP address, proxy server, operating system, web browser and
									add-ons, device identifier and features, cookie IDs and/or
									ISP, or your mobile carrier), your location based on your
									phone settings.
								</li>
								<li className='ml-[50px]'>
									Messages and information about you when you send, receive, or
									engage with messages in connection with our Services.
								</li>
							</ul>
							<p>
								Our Services are dynamic, and we often introduce new features,
								which may require the collection of new information. If we
								collect materially different personal data or materially change
								how we collect, use or share your data, we will notify you and
								may also modify this Privacy Policy.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								3- How We Use Your Data
							</span>
							<ul className='list-disc'>
								<p>
									We use your data to provide, support, personalize and develop
									our Services. These include:
								</p>
								<li className='ml-[50px]'>
									Help you collaborate more effectively and increase your
									productivity through our services. For example, you can stay
									connected with colleagues in spaces and projects, stay
									informed about their work via notifications and feeds,
									collaborate better with project management tools, and discover
									new spaces, projects, and people to follow.
								</li>
								<li className='ml-[50px]'>
									Provide our products and deliver our services including
									troubleshooting, improving, and personalizing the features of
									the Service.
								</li>
								<li className='ml-[50px]'>
									Business operations such as billing, accounting, improving our
									internal operations, securing our systems, detecting
									fraudulent or illegal activity, and meeting our legal
									obligations.
								</li>
								<li className='ml-[50px]'>
									Improve and develop our products and services including
									developing new services or features, and conduct research.
								</li>
								<li className='ml-[50px]'>
									Personalization of our Service by understanding you and your
									preferences to enhance your experience and enjoyment using our
									Service.
								</li>
								<li className='ml-[50px]'>
									Provide customer support and respond to your questions.
								</li>
								<li className='ml-[50px]'>
									Deliver promotional communications with you about new
									services, features, offers, promotions, and other information
									about our Service.
								</li>
								<li className='ml-[50px]'>
									Personalize and measure the effectiveness of enterprise
									business ads, including those you see off the Services,
									promotional communications or marketing you receive related to
									the Enterprise Marketing Pages.
								</li>
								<li className='ml-[50px]'>
									Send you information, including confirmations, invoices,
									technical notices, updates, security alerts, support and
									administrative messages.{' '}
								</li>
							</ul>
							<p>
								We combine data we collect from different sources for these
								purposes to give you a more seamless, consistent, and
								personalized experience.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								4- How We Share Information
							</span>
							<ul className='list-disc'>
								<p>We share private data as described below:</p>
								<li className='ml-[50px]'>
									If you collaborate on or become a member of a space or
									project, then their other members may receive your personal
									data, for example by having the ability to view your activity
									in the Organization’s timeline.
								</li>
								<li className='ml-[50px]'>
									We share your personal data with service providers who process
									the information on our behalf to provide or improve our
									Service. For example: payment processing, network data
									transmission, web analytics, marketing operations, security,
									online advertising, and other similar services. Such
									processing by service providers and any related cross-border
									data transfers will be in compliance with applicable law.
								</li>
								<li className='ml-[50px]'>
									For security purposes, for example: prevent spam or attempts
									to commit fraud, operate and maintain the security of the
									Service, including to prevent or stop an attack on our systems
									or networks, protect the rights or property or ourselves or
									others, including enforcing our agreements, terms, and
									policies.
								</li>
								<li className='ml-[50px]'>For legal disclosure</li>
							</ul>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								5- How We Use cookies and tracking technologies
							</span>
							<p>
								The TeamUp Space Services use cookies and similar technologies
								for a variety of purposes, including to store your preferences
								and settings, enable you to sign-in, analyze how our Services
								perform, track your interaction with the Services, develop
								inferences, combat fraud, and fulfill other legitimate purposes.
							</p>
							<span className='md:text-xl md:w-[800px] text-[#3485f0]'>
								6- How TeamUp Space Secures Your Information
							</span>
							<p>
								TeamUp Space takes reasonable measures necessary to protect your
								personal data from unauthorized access, alteration, or
								destruction; maintain data accuracy; and help ensure the
								appropriate use of your personal data. To help us protect
								personal data, we request that you use a strong password and
								never share your password with anyone or use the same password
								with other sites or accounts.
							</p>
							<ul className='list-disc'>
								<p>
									In addition, if your account has private entities (space,
									project), you control the access to that Content. TeamUp Space
									personnel does not access private entity content except for:
								</p>
								<li className='ml-[50px]'>security purposes,</li>
								<li className='ml-[50px]'>
									automated scanning for known vulnerabilities, active malware,
									or other content known to violate our Terms of Service
								</li>
								<li className='ml-[50px]'>
									to assist the repository owner with a support matter
								</li>
								<li className='ml-[50px]'>
									to maintain the integrity of the Service
								</li>
								<li className='ml-[50px]'>
									to comply with our legal obligations if we have reason to
									believe the contents are in violation of the law,
								</li>
								<li className='ml-[50px]'>or with your consent.</li>
							</ul>
							<p>
								TeamUp Space will provide notice regarding private repository
								access where not prohibited by law or if in response to a
								security threat or other risk to security.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-[#fff] border-[#f3f3f3] border-solid border'>
				<div className=' max-w-[1232px] px-[30px] py-7 mx-auto flex justify-between items-start sm:items-center flex-col sm:flex-row'>
					<div className='flex justify-start items-start sm:items-center flex-col sm:flex-row gap-4 sm:gap-16'>
						<img
							src={TUSBlack}
							alt='teamupspace-logo'
							className='w-24 sm:w-[120px] h-auto mb-4 sm:mb-0 cursor-pointer'
							onClick={() => navigate('/')}
						/>
						<Link className='text-[#212121]' to={'/login'}>
							Login
						</Link>
						{/* <Link className='text-[#212121]' to={'/'}>
							Privacy Policy
						</Link> */}
					</div>
					<p className='text-xs text-[#21212165] mt-4 sm:mt-0'>
						Copyright 2023 TeamUp Space
					</p>
				</div>
			</div>
		</section>
	)
}

export default TermsOfService
