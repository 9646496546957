import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	detail: null,
	projectId: 0,
	refetchProjDetail: false,
	modalClosed: false
}

const Project = createSlice({
	name: 'Project',
	initialState,
	reducers: {
		setProject: (state, { payload }) => ({ ...state, detail: payload }),
		setProjectId: (state, { payload }) => ({ ...state, projectId: payload }),
		triggerProjectDetailRefetch: (state, { payload }) => ({
			...state,
			refetchProjDetail: payload
		}),
		triggerModalClosed: (state, { payload }) => ({
			...state,
			modalClosed: payload
		})
	}
})

export const {
	setProject,
	setProjectId,
	triggerProjectDetailRefetch,
	triggerModalClosed
} = Project.actions
export default Project.reducer
