import React from 'react'

const PencilIcon = ({ color = '#939393', opacity = 0.5, width = '15', height = '15' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 15 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g opacity={opacity} clip-path='url(#clip0_1111_33771)'>
				<path
					d='M1.75833 9.66634L0.966665 13.083C0.939355 13.2079 0.940291 13.3373 0.969405 13.4618C0.998519 13.5863 1.05507 13.7027 1.13494 13.8026C1.21481 13.9024 1.31597 13.9831 1.43103 14.0388C1.54609 14.0946 1.67215 14.1239 1.8 14.1247C1.85957 14.1307 1.91959 14.1307 1.97916 14.1247L5.41666 13.333L12.0167 6.75801L8.33333 3.08301L1.75833 9.66634Z'
					fill={color}
				/>
				<path
					d='M14.0917 3.46625L11.6333 1.00791C11.4717 0.847108 11.253 0.756836 11.025 0.756836C10.797 0.756836 10.5783 0.847108 10.4167 1.00791L9.05 2.37458L12.7292 6.05375L14.0958 4.68708C14.1758 4.6067 14.2392 4.51133 14.2822 4.40644C14.3253 4.30155 14.3473 4.18919 14.3469 4.0758C14.3465 3.96241 14.3238 3.8502 14.28 3.74561C14.2362 3.64101 14.1722 3.54608 14.0917 3.46625Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1111_33771'>
					<rect width={width} height={height} fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default React.memo(PencilIcon)
