import React from 'react'

const MediumPriorityIcon = ({...props}) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
            {...props}>
			    <g id="ph:hourglass-fill">
					<path id="Vector" d="M15.1973 6.40226V3.7832C15.1973 3.47137 15.0734 3.1723 14.8529 2.9518C14.6324 2.7313 14.3333 2.60742 14.0215 2.60742H5.79102C5.47918 2.60742 5.18011 2.7313 4.95961 2.9518C4.73911 3.1723 4.61523 3.47137 4.61523 3.7832V6.42871C4.61577 6.61123 4.65859 6.79114 4.74031 6.95433C4.82204 7.11753 4.94046 7.25958 5.08628 7.36934L8.92668 10.25L5.08555 13.1307C4.93981 13.2405 4.82151 13.3825 4.73991 13.5457C4.6583 13.7089 4.61563 13.8888 4.61523 14.0713V16.7168C4.61523 17.0286 4.73911 17.3277 4.95961 17.5482C5.18011 17.7687 5.47918 17.8926 5.79102 17.8926H14.0215C14.3333 17.8926 14.6324 17.7687 14.8529 17.5482C15.0734 17.3277 15.1973 17.0286 15.1973 16.7168V14.0977C15.1973 13.9158 15.1551 13.7364 15.074 13.5736C14.9929 13.4107 14.8751 13.2689 14.7299 13.1593L10.8807 10.25L14.7299 7.34068C14.8749 7.23088 14.9925 7.08904 15.0736 6.92625C15.1546 6.76346 15.197 6.58412 15.1973 6.40226Z" fill="#3484F0"/>
				</g>
		</svg>
	)
}

export default MediumPriorityIcon