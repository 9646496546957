import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const userAdminApiRedux = createApi({
	reducerPath: 'userApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAdminAllUsersApi: builder.query({
			query: ({ search }) => {
				return {
					url: `user/getUserList`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
		getAdminUserSummaryApi: builder.query({
			query: (userId) => {
				return {
					url: `user/${userId}/summary`
				}
			},
			transformResponse: response => response.data
		}),
		getAdminUserSettingApi: builder.query({
			query: (userId) => {
				return {
					url: `user/${userId}/settings`
				}
			},
			transformResponse: response => response.data
		}),
		getAdminUserProjectsApi: builder.query({
			query: ({ userId, search }) => {
				return {
					url: `user/${userId}/projects`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
		getAdminUserOrganizationsApi: builder.query({
			query: ({ userId, search }) => {
				return {
					url: `user/${userId}/organizations`,
					params: {
						search,
					}
				}
			},
			transformResponse: response => response.data
		}),
		getAdminUserReportsApi: builder.query({
			query: ({ userId, search }) => {
				return {
					url: `user/${userId}/reports`
				}
			},
			transformResponse: response => response.data
		}),
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useLazyGetAdminAllUsersApiQuery,
	useLazyGetAdminUserSummaryApiQuery,
	useLazyGetAdminUserSettingApiQuery,
	useLazyGetAdminUserProjectsApiQuery,
	useLazyGetAdminUserOrganizationsApiQuery,
	useLazyGetAdminUserReportsApiQuery,
} = userAdminApiRedux
