import React from 'react'

const CubeIcon = ({ width = 12, height = 12 }) => {
	return (
		<svg
			className='shrink-0'
			width={width}
			height={height}
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.5625 5.9007L0.75 3.87653V8.3857C0.752536 8.57373 0.808803 8.75712 0.912162 8.91423C1.01552 9.07133 1.16166 9.1956 1.33333 9.27236L5.5275 11.2499H5.5625V5.9007ZM6 5.12486L10.9642 3.04236C10.8806 2.94954 10.7795 2.8742 10.6667 2.8207L6.46667 0.854862C6.32078 0.785815 6.1614 0.75 6 0.75C5.8386 0.75 5.67922 0.785815 5.53333 0.854862L1.33333 2.8207C1.22049 2.8742 1.11938 2.94954 1.03583 3.04236L6 5.12486ZM6.4375 5.9007V11.2499H6.46667L10.6667 9.27236C10.8375 9.19602 10.983 9.07266 11.0863 8.91668C11.1896 8.76071 11.2464 8.57857 11.25 8.39153V3.87653L6.4375 5.9007Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default CubeIcon
