import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const resetPassword = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {
		resetPasswordEmailHandler: (state, { payload }) => ({
			...payload
		})
	}
})

export const { resetPasswordEmailHandler } = resetPassword.actions
export default resetPassword.reducer
