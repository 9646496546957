import LinkIcon from 'components/Icons/LinkIcon'
import React from 'react'

const MessageTypeLink = () => {
	return (
		<div className='flex items-center gap-4 -mb-3'>
			<LinkIcon />
			<div>
				<h6 className='text-black text-sm font-semibold mb-0.5'>Link Title</h6>
				<span className='text-xs text-black font-light -mt-1'>Link</span>
			</div>
		</div>
	)
}

export default MessageTypeLink
