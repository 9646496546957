import React from 'react'

const ArrowWithLineIcon = ({ width = 19, height = 16, color = '#212121' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 19 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.6666 8.29716C18.6666 8.70849 18.361 9.04844 17.9644 9.10224L17.8541 9.10966L3.57146 9.10901L8.73136 14.2478C9.04935 14.5644 9.05045 15.0789 8.73384 15.3969C8.446 15.686 7.99467 15.7131 7.67609 15.4778L7.58479 15.3994L1.03062 8.87335C0.988708 8.83162 0.952301 8.78645 0.921404 8.73875C0.912676 8.72439 0.903791 8.7097 0.89537 8.69472C0.887623 8.68187 0.880623 8.66854 0.87402 8.65506C0.864847 8.63544 0.855885 8.61517 0.847748 8.59449C0.841137 8.57854 0.835617 8.56303 0.830582 8.5474C0.824594 8.52801 0.818779 8.50736 0.813776 8.48638C0.810057 8.47174 0.807112 8.45765 0.804543 8.4435C0.800928 8.42245 0.797853 8.40067 0.795658 8.37862C0.793764 8.36181 0.792624 8.34516 0.791992 8.32848C0.79183 8.31837 0.791626 8.30779 0.791626 8.29716L0.792032 8.26568C0.792656 8.24974 0.793745 8.2338 0.795301 8.21791L0.791626 8.29716C0.791626 8.24588 0.796375 8.19572 0.805456 8.14708C0.807564 8.13547 0.810074 8.12354 0.812853 8.11167C0.818624 8.08721 0.825294 8.06365 0.832973 8.04056C0.836744 8.02909 0.841135 8.01683 0.845829 8.00466C0.855324 7.98022 0.865643 7.95691 0.876997 7.93421C0.882271 7.92354 0.888178 7.91236 0.894363 7.9013C0.904516 7.88325 0.914976 7.8661 0.926037 7.84939C0.933842 7.83756 0.942484 7.82524 0.951508 7.81312L0.958538 7.80376C0.980429 7.77516 1.00418 7.74806 1.0296 7.72263L1.03057 7.72188L7.58474 1.1948C7.9027 0.878152 8.41714 0.879217 8.73379 1.19717C9.02165 1.48623 9.04693 1.93767 8.81027 2.25526L8.73141 2.34622L3.57363 7.48401L17.8541 7.48466C18.3029 7.48466 18.6666 7.84843 18.6666 8.29716Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(ArrowWithLineIcon)
