import useFetch from 'hooks/useFetch'
const endPoint = '/task'

export const GetAllTaskApi = async (router, {milestoneId, search, type, priority, teamId, perPage, pageNumber, sort}) => {
	let response = await useFetch(router, true).get(`${endPoint}?sort=${sort ?? ''}&milestoneId=${milestoneId ?? ''}&search=${search ?? ''}&type=${type ?? ''}&priority=${priority ?? ''}&teamId=${teamId ?? ''}&pageNumber=${pageNumber}&perPage=${perPage}`)

	return response
}

export const GetUserTaskApi = async (router, {projectId, milestoneId, search, type, priority, teamId, perPage, pageNumber, sort}) => {
	let response = await useFetch(router, true).get(`task/getUserTasks?sort=${sort ?? ''}&milestoneId=${milestoneId ?? ''}&projectId=${projectId ?? ''}&search=${search ?? ''}&type=${type ?? ''}&priority=${priority ?? ''}&teamId=${teamId ?? ''}&pageNumber=${pageNumber}&perPage=${perPage}`)

	return response
}

export const AddTaskApi = async (router, data) => {
	let response = await useFetch(router, true).post(`${endPoint}`, {
		...data
	})

	return response
}

export const UpdateTaskApi = async (router, data) => {
	let response = await useFetch(router, true).put(`${endPoint}/${data.id}`, {
		...data
	})

	return response
}

export const GetTaskByMilestoneId = async (router, _id) => {
	let response = await useFetch(router, true).get(
		`${endPoint}/getByMilestoneId/${_id}`
	)

	return response
}

export const DeleteTaskApi = async (router, _id) => {
	let response = await useFetch(router, true).delete(`${endPoint}/forceDelete/${_id}`)

	return response
}

export const ChangeTaskProgressApi = async (router, data) => {
	let response = await useFetch(router, true).put(
		`${endPoint}/setTaskProgress`,
		data
	)

	return response
}


export const GetTaskByTaskIdApi = async (router, task_id) => {
	let response = await useFetch(router, true).get(`${endPoint}/${task_id}`)

	return response
}