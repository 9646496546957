import React from 'react'

function XIcon({ color = '#F3F3F', width = 16, height = 16, ...props }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				d='M10.119 8.0021L15.0605 3.0606C15.2017 2.92174 15.314 2.75631 15.3909 2.57385C15.4678 2.39139 15.5078 2.1955 15.5087 1.9975C15.5095 1.79949 15.4711 1.60327 15.3957 1.42017C15.3204 1.23707 15.2095 1.07071 15.0695 0.930676C14.9295 0.790645 14.7631 0.679718 14.58 0.604293C14.397 0.528869 14.2008 0.49044 14.0028 0.491223C13.8047 0.492006 13.6088 0.531985 13.4264 0.608855C13.2439 0.685724 13.0784 0.797963 12.9395 0.939097L7.99805 5.8806L3.05655 0.939597C2.91725 0.800297 2.75187 0.689799 2.56987 0.61441C2.38787 0.539022 2.1928 0.50022 1.9958 0.50022C1.7988 0.50022 1.60373 0.539022 1.42172 0.61441C1.23972 0.689799 1.07435 0.800297 0.935046 0.939597C0.653718 1.22093 0.495669 1.60249 0.495669 2.00035C0.495669 2.39821 0.653718 2.77977 0.935046 3.0611L5.87655 8.0021L0.923046 12.9556C0.645558 13.2377 0.490787 13.618 0.492445 14.0137C0.494103 14.4094 0.652055 14.7885 0.931897 15.0682C1.21174 15.348 1.5908 15.5059 1.9865 15.5074C2.38221 15.509 2.7625 15.3542 3.04455 15.0766L7.99755 10.1236L12.9385 15.0646C13.2315 15.3576 13.6155 15.5041 13.999 15.5041C14.3825 15.5041 14.7665 15.3576 15.0595 15.0646C15.1989 14.9254 15.3095 14.7601 15.3849 14.5781C15.4603 14.3961 15.4991 14.2011 15.4991 14.0041C15.4991 13.8071 15.4603 13.6121 15.3849 13.4301C15.3095 13.2481 15.1989 13.0828 15.0595 12.9436L10.119 8.0021Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(XIcon)
