import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const milestoneApiRedux = createApi({
	reducerPath: 'milestoneApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllMilestoneApi: builder.query({
			query: () => `milestone`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getAllMilestoneByProjectIdApi: builder.query({
			query: ({ projectId }) => `milestone/getListByProjectId/${projectId}`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getProjectMilestoneApi: builder.query({
			query: ({ search, projectId }) => {
				return {
					url: `milestone/getByProjectId/${projectId}`,
					params: { search }
				}
			},
			transformResponse: response => ({
				...response,
				milestones: response.milestones.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
			})
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetProjectMilestoneApiQuery,
	useLazyGetProjectMilestoneApiQuery,
	useGetAllMilestoneApiQuery,
	useLazyGetAllMilestoneApiQuery,
	useGetAllMilestoneByProjectIdApiQuery,
	useLazyGetAllMilestoneByProjectIdApiQuery
} = milestoneApiRedux
