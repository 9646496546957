import ChatFillIcon from 'components/Icons/ChatFillIcon'
import ClockFillIcon from 'components/Icons/ClockFillIcon'
import DocumentFillIcon from 'components/Icons/DocumentFillIcon'
import PenFillIcon from 'components/Icons/PenFillIcon'
import React, { useState } from 'react'
import TaskDetailConversation from './TaskDetailModal/TaskDetailConversation'
import TaskDetailEditBox from './TaskDetailModal/TaskDetailEditBox'
import TaskDetailLogContent from './TaskDetailModal/TaskDetailLogContent'
import TaskDetailNoteModal from './TaskDetailModal/TaskDetailNoteModal'
import CheckBoxStrokeIcon from 'components/Icons/CheckBoxStrokeIcon'
import Subtasks from './TaskDetailModal/Subtasks'
import SubTaskDetailEditBox from './TaskDetailModal/SubTaskDetailEditBox'

function TaskDetailModalTabContent({
	data,
	updateList,
	modalHandler,
	isSubtask
}) {
	const [tabCondition, setTabCondition] = useState('Edit-Task')
	return (
		<div className='flex-1'>
			<ul
				className={`grid  ${
					!isSubtask ? 'grid-cols-4' : 'grid-cols-3'
				} border-b border-solid border-gray-400 border-opacity-10 mb-3`}>
				{/* <li className='relative flex justify-center pb-3'>
					<button
						onClick={() => setTabCondition('Conversations')}
						className={`flex items-center  gap-1.5 text-sm font-light justify-center ${
							tabCondition === 'Conversations'
								? ' text-primaryBlue !font-medium'
								: 'text-gray-400 text-opacity-60'
						}`}>
						<ChatFillIcon />
						Conversations
					</button>
					<i
						className={`w-full absolute left-0  bottom-0 h-1 bg-primaryBlue rounded-t-[50px] ${
							tabCondition === 'Conversations' ? 'opacity-100' : 'opacity-0'
						}`}></i>
				</li> */}
				<li className='relative flex justify-center pb-3'>
					<button
						onClick={() => setTabCondition('Task-Notes')}
						className={`flex items-center  gap-1.5 text-sm font-light justify-center ${
							tabCondition === 'Task-Notes'
								? ' text-primaryBlue !font-medium'
								: 'text-gray-400 text-opacity-60'
						}`}>
						<DocumentFillIcon />
						Task Notes
					</button>
					<i
						className={`w-full absolute left-0  bottom-0 h-1 bg-primaryBlue rounded-t-[50px] ${
							tabCondition === 'Task-Notes' ? 'opacity-100' : 'opacity-0'
						}`}></i>
				</li>
				<li className='relative flex justify-center pb-3'>
					<button
						onClick={() => setTabCondition('Logs')}
						className={`flex items-center  gap-1.5 text-sm font-light justify-center ${
							tabCondition === 'Logs'
								? ' text-primaryBlue !font-medium'
								: 'text-gray-400 text-opacity-60'
						}`}>
						<ClockFillIcon />
						Logs
					</button>
					<i
						className={`w-full absolute left-0  bottom-0 h-1 bg-primaryBlue rounded-t-[50px] ${
							tabCondition === 'Logs' ? 'opacity-100' : 'opacity-0'
						}`}></i>
				</li>
				{!isSubtask && (
					<li className='relative flex justify-center pb-3'>
						<button
							onClick={() => setTabCondition('subtask')}
							className={`flex items-center  gap-1.5 text-sm font-light justify-center ${
								tabCondition === 'subtask'
									? ' text-primaryBlue !font-medium'
									: 'text-gray-400 text-opacity-60'
							}`}>
							<CheckBoxStrokeIcon size={19} />
							Subtasks
						</button>
						<i
							className={`w-full absolute left-0  bottom-0 h-1 bg-primaryBlue rounded-t-[50px] ${
								tabCondition === 'subtask' ? 'opacity-100' : 'opacity-0'
							}`}></i>
					</li>
				)}

				<li className='relative flex justify-center pb-3'>
					<button
						onClick={() => setTabCondition('Edit-Task')}
						className={`flex items-center  gap-1.5 text-sm font-light justify-center ${
							tabCondition === 'Edit-Task'
								? ' text-primaryBlue !font-medium'
								: 'text-gray-400 text-opacity-60'
						}`}>
						<PenFillIcon />
						{isSubtask ? 'Edit SubTask' : 'Edit Task'}
					</button>
					<i
						className={`w-full absolute left-0  bottom-0 h-1 bg-primaryBlue rounded-t-[50px] ${
							tabCondition === 'Edit-Task' ? 'opacity-100' : 'opacity-0'
						}`}></i>
				</li>
			</ul>

			{tabCondition === 'Conversations' ? (
				<TaskDetailConversation />
			) : (
				<div className='pt-3'>
					{/* <h5 className='text-gray-700 font-medium mb-4'>{tabCondition}</h5> */}
					{tabCondition === 'Logs' ? (
						<TaskDetailLogContent parentData={data} />
					) : tabCondition === 'Task-Notes' ? (
						<TaskDetailNoteModal parentData={data} />
					) : tabCondition === 'subtask' ? (
						<Subtasks parentData={data} />
					) : (
						<>
							{isSubtask ? (
								<SubTaskDetailEditBox
									modalHandler={modalHandler}
									updateList={updateList}
									data={data}
								/>
							) : (
								<TaskDetailEditBox
									modalHandler={modalHandler}
									updateList={updateList}
									data={data}
								/>
							)}
						</>
					)}
				</div>
			)}
		</div>
	)
}

export default TaskDetailModalTabContent
