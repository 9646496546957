import React from 'react'

const SideMenuIcon = () => {
	return (
		<svg
			width='17'
			height='15'
			viewBox='0 0 17 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<line
				x1='1.25'
				y1='1.75'
				x2='15.75'
				y2='1.75'
				stroke='#939393'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='1.25'
				y1='7.75'
				x2='11.75'
				y2='7.75'
				stroke='#939393'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='1.25'
				y1='13.75'
				x2='6.75'
				y2='13.75'
				stroke='#939393'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
		</svg>
	)
}

export default React.memo(SideMenuIcon)
