import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import {
	logModalHandler,
	milestoneModalHandler,
	noteModalHandler,
	subTaskModalHandler,
	taskModalHandler
} from 'redux/slices/originModals'
import AddLogModal from './Modals/AddLogModal'
import AddMilestoneModal from './Modals/AddMilestoneModal'
import AddTaskModal from './Modals/AddTaskModal/AddTaskModal'
import AggregateNoteModal from './Modals/AggregateNoteModal'
import AddSubTaskTaskModal from './Modals/AddTaskModal/AddSubTaskModal'
import { useRole } from 'hooks/useRole'
import { useGetProgressItemsApiQuery } from 'redux/api/progressReport'
import { setProgress } from 'redux/slices/progress'

const PanelLayoutWrapper = () => {
	const navigate = useNavigate()
	const isAccountant = useRole('Accountant')
	const isAdmin = useRole('Admin')
	const { data, isLoading } = useGetProgressItemsApiQuery()
	const { log, task, milestone, note, subTask } = useSelector(
		state => state.originModalCondition
	)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!localStorage?.organization && !isAccountant) {
			// navigate('/space')
		}

		if (!localStorage?.token) {
			navigate('/login')
		}
	}, [])
	useEffect(() => {
		if (!isLoading) {
			dispatch(setProgress(data))
		}
	}, [isLoading])

	return (
		<>
			<div>
				<Outlet />
			</div>

			{log && <AddLogModal handler={() => dispatch(logModalHandler(false))} />}
			{task && (
				<AddTaskModal handler={() => dispatch(taskModalHandler(false))} />
			)}
			{milestone && (
				<AddMilestoneModal
					modalHandler={() => dispatch(milestoneModalHandler(false))}
				/>
			)}
			{subTask && (
				<AddSubTaskTaskModal
					handler={() => dispatch(subTaskModalHandler(false))}
				/>
			)}

			{note && (
				<AggregateNoteModal
					modalHandler={() => dispatch(noteModalHandler(false))}
				/>
			)}
		</>
	)
}

export default PanelLayoutWrapper
