import React from 'react'

const SettingIcon = () => {
	return (
		<svg
			width='16'
			height='18'
			viewBox='0 0 16 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.59761 0.666992C9.22781 0.666992 9.79841 1.01699 10.1135 1.53366C10.2668 1.78366 10.369 2.09199 10.3435 2.41699C10.3264 2.66699 10.4031 2.91699 10.5393 3.15033C10.9737 3.85866 11.936 4.12533 12.6854 3.72533C13.5286 3.24199 14.5931 3.53366 15.0785 4.35866L15.6491 5.34199C16.1431 6.16699 15.8705 7.22533 15.0189 7.70033C14.295 8.12533 14.0395 9.06699 14.4739 9.78366C14.6101 10.0087 14.7634 10.2003 15.0019 10.317C15.2999 10.4753 15.5299 10.7253 15.6917 10.9753C16.0068 11.492 15.9812 12.1253 15.6747 12.6837L15.0785 13.6837C14.7634 14.217 14.1758 14.5503 13.5711 14.5503C13.2731 14.5503 12.9409 14.467 12.6684 14.3003C12.447 14.1587 12.1915 14.1087 11.919 14.1087C11.0759 14.1087 10.369 14.8003 10.3435 15.6253C10.3435 16.5837 9.55995 17.3337 8.58057 17.3337H7.42236C6.43446 17.3337 5.65096 16.5837 5.65096 15.6253C5.63393 14.8003 4.92708 14.1087 4.08396 14.1087C3.80292 14.1087 3.54743 14.1587 3.33453 14.3003C3.062 14.467 2.72135 14.5503 2.4318 14.5503C1.81862 14.5503 1.231 14.217 0.915894 13.6837L0.328268 12.6837C0.0131649 12.142 -0.00386769 11.492 0.311236 10.9753C0.447497 10.7253 0.702986 10.4753 0.992541 10.317C1.231 10.2003 1.38429 10.0087 1.52907 9.78366C1.95488 9.06699 1.69939 8.12533 0.975508 7.70033C0.132393 7.22533 -0.140129 6.16699 0.345301 5.34199L0.915894 4.35866C1.40984 3.53366 2.46586 3.24199 3.31749 3.72533C4.05841 4.12533 5.02076 3.85866 5.45509 3.15033C5.59135 2.91699 5.668 2.66699 5.65096 2.41699C5.63393 2.09199 5.72761 1.78366 5.88942 1.53366C6.20452 1.01699 6.77512 0.683659 7.39681 0.666992H8.59761ZM8.00998 6.65033C6.67292 6.65033 5.59135 7.70033 5.59135 9.00866C5.59135 10.317 6.67292 11.3587 8.00998 11.3587C9.34704 11.3587 10.4031 10.317 10.4031 9.00866C10.4031 7.70033 9.34704 6.65033 8.00998 6.65033Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(SettingIcon)
