import React from 'react'

function ArrowIcon({ width = 8, height = 6, color = '#212121', className }) {
	return (
		<svg
			width={width}
			height={height}
			className={className && className}
			viewBox='0 0 8 6'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.234835 0.984835C0.367968 0.851702 0.5763 0.839599 0.723106 0.948526L0.765165 0.984835L4 4.2195L7.23484 0.984835C7.36797 0.851702 7.5763 0.839599 7.72311 0.948526L7.76516 0.984835C7.8983 1.11797 7.9104 1.3263 7.80147 1.47311L7.76516 1.51517L4.26516 5.01516C4.13203 5.1483 3.9237 5.1604 3.77689 5.05147L3.73483 5.01516L0.234835 1.51517C0.0883883 1.36872 0.0883883 1.13128 0.234835 0.984835Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(ArrowIcon)
