import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/user'
import adminReducer from './slices/admin'
import originModalCondition from './slices/originModals'
import trackingDetail from './slices/trackingDetail'
import project from './slices/project'

import { taskApiRedux } from './api/task'
import { logApiRedux } from './api/log'
import { projectApiRedux } from './api/projects'
import { noteApiRedux } from './api/note'
import { milestoneApiRedux } from './api/milestone'
import { contactApiRedux } from './api/contact'
import { roleApiRedux } from './api/role'
import { organizationApiRedux } from './api/organization'
import { meetingApiRedux } from './api/meeting'
import { timelineApiRedux } from './api/timeline'
import { reportApiRedux } from './api/report'
import { dashboardApiRedux } from './api/dashboard'
import { progressReportApiRedux } from './api/progressReport'
import { profileApiRedux } from './api/profile'
import organization from './slices/organization'
import { teamApiRedux } from './api/team'
import resetPassword from './slices/resetPassword'
import { clientApiRedux } from './api/client'
import { accountantApiRedux } from './api/accountant'
import progress from './slices/progress'
import ProgressReportDrag from './slices/progressReportDrag'
import { userAdminApiRedux } from './api/admin/user'
import { organizationAdminApiRedux } from './api/admin/organization'
import { projectAdminApiRedux } from './api/admin/project'
import { AttachmentApiRedux } from './api/attachment'
import attachment from './slices/attachment'
import log from './slices/log'
import task from './slices/task'

export const rootReducer = combineReducers({
	user: userReducer,
	originModalCondition: originModalCondition,
	trackingDetail: trackingDetail,
	admin: adminReducer,
	project: project,
	progress: progress,
	organization: organization,
	resetPassword: resetPassword,
	progressReportDrag: ProgressReportDrag,
	attachment: attachment,
	log: log,
	task: task,
	[taskApiRedux.reducerPath]: taskApiRedux.reducer,
	[logApiRedux.reducerPath]: logApiRedux.reducer,
	[projectApiRedux.reducerPath]: projectApiRedux.reducer,
	[noteApiRedux.reducerPath]: noteApiRedux.reducer,
	[milestoneApiRedux.reducerPath]: milestoneApiRedux.reducer,
	[contactApiRedux.reducerPath]: contactApiRedux.reducer,
	[roleApiRedux.reducerPath]: roleApiRedux.reducer,
	[organizationApiRedux.reducerPath]: organizationApiRedux.reducer,
	[meetingApiRedux.reducerPath]: meetingApiRedux.reducer,
	[timelineApiRedux.reducerPath]: timelineApiRedux.reducer,
	[reportApiRedux.reducerPath]: reportApiRedux.reducer,
	[dashboardApiRedux.reducerPath]: dashboardApiRedux.reducer,
	[progressReportApiRedux.reducerPath]: progressReportApiRedux.reducer,
	[profileApiRedux.reducerPath]: profileApiRedux.reducer,
	[teamApiRedux.reducerPath]: teamApiRedux.reducer,
	[clientApiRedux.reducerPath]: clientApiRedux.reducer,
	[accountantApiRedux.reducerPath]: accountantApiRedux.reducer,
	[userAdminApiRedux.reducerPath]: userAdminApiRedux.reducer,
	[organizationAdminApiRedux.reducerPath]: organizationAdminApiRedux.reducer,
	[projectAdminApiRedux.reducerPath]: projectAdminApiRedux.reducer,
	[AttachmentApiRedux.reducerPath]: AttachmentApiRedux.reducer
})
