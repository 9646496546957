import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'
import { createSlice, nanoid } from '@reduxjs/toolkit'
import { setRecentAttachments } from 'redux/slices/attachment'

const initialState = {}

const attachment = createSlice({
    name: 'attachment',
    initialState,
    reducers: {
        setAttachment: (state, action) => ({
            ...action.payload
        }),
    }
})

export const { setAttachment } = attachment.actions
export default attachment.reducer
export const AttachmentApiRedux = createApi({
    reducerPath: 'AttachmentApiRedux',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            return RequestHeaderHandler(headers)
        }
    }),
    endpoints: builder => ({
        getAllAttachmentsApi: builder.query({
            query: ({ organizationId, taskId, milestoneId, fileType, projectId }) => {
                return {
                    url: `attachment/?milestoneId=${milestoneId}&taskId=${taskId}&fileType=${fileType}&projectId=${projectId}`,
                    headers: { orgId: organizationId }
                }
            },
            transformResponse: response =>
                response.data
        }),
        getRecentAttachmentsApi: builder.query({
            query: ({ organizationId, projectId }) => {
                return {
                    url: `attachment/getRecentFiles?projectId=${projectId}`,
                    headers: { orgId: organizationId }
                }
            },
            transformResponse: response => response.data
        }),
        getAttachmentByIdApi: builder.query({
            query: ({ attachmentId }) => {
                return {
                    url: `attachment/${attachmentId}`,
                }
            },
            transformResponse: response =>
                response.data
        }),
        downloadAttachmentApi: builder.query({
            query: (attachmentId) => {
                console.log(attachmentId)
                return {
                    url: `attachment/download/${attachmentId}`,
                }
            },
            transformResponse: response =>
                response
        }),
        getTaskAttachmentsAndSearchAttachmentsApi: builder.query({
            query: (taskId, searchTerm = '') => {
                return {
                    url: `attachment/getTaskAttachments/${taskId}?search=${searchTerm}`,
                }
            },
            transformResponse: response =>
                response
        }),

    }),
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true
})

export const {
    useLazyGetAllAttachmentsApiQuery,
    useLazyGetRecentAttachmentsApiQuery,
    useGetAttachmentByIdApiQuery,
    useLazyDownloadAttachmentApiQuery,
    useLazyGetTaskAttachmentsAndSearchAttachmentsApiQuery,

} = AttachmentApiRedux
