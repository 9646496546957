import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const taskApiRedux = createApi({
	reducerPath: 'taskApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllTasksApi: builder.query({
			query: ({ projectId, milestoneId, search,  type, priority, teamId, perPage, pageNumber, sort }) => {
				return {
					url: `task?sort=${sort}`,
					params: {
						search,
						teamId,
						projectId,
						milestoneId,
						type,
						priority,
						perPage,
						pageNumber
					}
				}
			},
			transformResponse: response =>
			response = {meta: response.meta, data: response.data.map(item => ({
				...item,
				value: item.id,
				label: item.name
			}))}
				
		}),
		getTaskApi: builder.query({
			query: ({ taskId }) => {
				return {
					url: `task/${taskId}`
				}
			},
			transformResponse: response => response.data
		}),
		getTaskByMilestoneApi: builder.query({
			query: milestoneId => `task/getByMilestoneId/${milestoneId}`,
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getProjectTaskApi: builder.query({
			query: ({ projectId, search, milestoneId, teamId, sort, type, priority, }) => {
				return {
					url: `task/getByProjectId/${projectId}?sort=${sort}`,
					params: {
						search,
						milestoneId,	
						type,
						priority,
						teamId
					}
				}
			},
			transformResponse: response =>
				response.data.map(
					item =>
						item && {
							...item,
							value: item.id,
							label: item.name
						}
				)
		}),
		getSubtaskByTaskIdApi: builder.query({
			query: ({ taskId }) => {
				return {
					url: `task/getSubTasks/${taskId}`
				}
			},
			transformResponse: response =>
				response.data.map(item => ({
					...item,
					value: item.id,
					label: item.name
				}))
		}),
		getUserTasksApi: builder.query({
			query: ({ teamId, search, projectId, milestoneId, type, priority, perPage, pageNumber, sort }) => {
				return {
					url: `task/getUserTasks?sort=${sort}`,
					params: {
						search, 
						projectId,
						milestoneId,
						teamId,
						type,
						priority,
						perPage,
						pageNumber
					}
				}
			},
			transformResponse: response =>
			response.data.map(item => ({
				...item,
				value: item.id,
				label: item.name
			}))
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const {
	useGetAllTasksApiQuery,
	useGetTaskApiQuery,
	useLazyGetSubtaskByTaskIdApiQuery,
	useLazyGetAllTasksApiQuery,
	useGetTaskByMilestoneApiQuery,
	useLazyGetTaskByMilestoneApiQuery,
	useGetProjectTaskApiQuery,
	useLazyGetProjectTaskApiQuery,
	useLazyGetUserTasksApiQuery,
} = taskApiRedux
