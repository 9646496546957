import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const timelineApiRedux = createApi({
	reducerPath: 'timelineApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllTimelineApi: builder.query({
			query: ({
				type,
				projectId,
				milestoneId,
				taskId,
				startDate,
				endDate,
				page
			}) => {
				return {
					url: 'timeline',
					params: {
						type,
						projectId,
						milestoneId,
						taskId,
						startDate,
						endDate,
						page
					}
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const { useLazyGetAllTimelineApiQuery } = timelineApiRedux
