import React from 'react'

const AvailabilityIcon = () => {
	return (
		<svg
			width='14'
			height='18'
			viewBox='0 0 14 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.375 2.25C12.8306 2.25 13.2 1.87225 13.2 1.40625V0.84375C13.2 0.377754 12.8306 0 12.375 0H0.825C0.369359 0 0 0.377754 0 0.84375V1.40625C0 1.87225 0.369359 2.25 0.825 2.25C0.825 5.44799 2.57868 8.1469 4.97894 9C2.57868 9.8531 0.825 12.552 0.825 15.75C0.369359 15.75 0 16.1278 0 16.5938V17.1562C0 17.6222 0.369359 18 0.825 18H12.375C12.8306 18 13.2 17.6222 13.2 17.1562V16.5938C13.2 16.1278 12.8306 15.75 12.375 15.75C12.375 12.552 10.6213 9.8531 8.22106 9C10.6213 8.1469 12.375 5.44799 12.375 2.25Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default AvailabilityIcon
