import useFetch from 'hooks/useFetch'
const endPoint = '/search'

export const SearchApi = async (router, search) => {
	let response = await useFetch(router, true).get(
		`${endPoint}?search=${search}`
	)

	return response
}
