import Spinner from 'components/Common/Spinner'
import ArrowIcon from 'components/Icons/ArrowIcon'
import ProjectIcon from 'components/Icons/ProjectIcon'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyGetAllOrganizationApiQuery } from 'redux/api/organization'
import { setOrganization } from 'redux/slices/organization'
import { login } from 'redux/slices/user'
import { DropdownOutsideClick } from 'utils/DropdownOutsideClick'

const HeaderOrganizationDropdown = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [selectedOrganization, setSelectedOrganization] = useState()
	const [dropDownStatus, setDropDownStatus] = useState(false)
	const dropdownRef = useRef()
	const [
		getOrganization,
		{ data: organizations, isLoading: organizationsLoading }
	] = useLazyGetAllOrganizationApiQuery()

	const organization = useSelector(state => state.organization)
	const user= useSelector(state => state.user)

	const changeOrganization = data => {
		setSelectedOrganization(data)
		dispatch(setOrganization(data))
		dispatch(login({ ...user, role: data.role }))
		localStorage.setItem('organization', JSON.stringify(data))
		navigate('/dashboard')
		if (window.location.pathname === '/dashboard') {
			window.location.reload(true)
		}
	}

	useEffect(() => {
		setSelectedOrganization(organization)
		getOrganization({})
	}, [organization])
	useEffect(() => {
		document.addEventListener('click', e =>
			DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
		)
		return () =>
			document.removeEventListener('click', e =>
				DropdownOutsideClick(e, dropdownRef.current, setDropDownStatus)
			)
	}, [])

	return (
		<>
			{' '}
			{selectedOrganization && (
				<li ref={dropdownRef} className=' !border-0'>
					<div className=' relative'>
						<button
							onClick={() => setDropDownStatus(!dropDownStatus)}
							className='min-w-[180px] border border-solid border-gray-200 flex items-center justify-between px-3 rounded-[10px] overflow-hidden relative h-11 '>
							<div className='flex gap-2 items-center'>
								<img
									className='w-[26px] h-[26px]'
									src={selectedOrganization?.logo}
								/>
								<div className='grid'>
									<span className='text-[6px] font-bold text-gray-700 text-opacity-10'>
										SPACE
									</span>
									<span className='text-gray-700 text-sm font-medium truncate '>
										{selectedOrganization?.name}
									</span>
								</div>
							</div>
							<ArrowIcon className={'-mb-2'} />
						</button>
						{dropDownStatus && (
							<div className='pt-4 mb-2 absolute  right-0 lg:top-full bottom-full lg:bottom-0'>
								<ul className='bg-white shadow-custom rounded-[10px] min-w-[193px] py-4 px-3'>
									{organizationsLoading ? (
										<Spinner scale={30} size={3} />
									) : (
										<>
											{organizations?.map((item, index) => (
												<li
													key={'org-item-' + index}
													className='py-3 last:pb-0 px-2 w-full first:pt-0 pl-1 pr-0 '>
													<button
														onClick={() => changeOrganization(item)}
														className={`text-sm w-full group/item hover:text-primaryBlue justify-between font-medium flex items-center ${
															item.id === selectedOrganization.id
																? 'text-primaryBlue font-medium'
																: 'text-gray-700 '
														}`}>
														<div className='flex gap-3 items-center '>
															<img
																className='w-[26px] h-[26px] object-contain'
																src={item?.logo}
															/>
															<span className='truncate  pr-2'>
																{item?.name}
															</span>
														</div>
														{item.id === selectedOrganization.id && (
															<i className='bg-primaryBlue shrink-0 rounded-full w-2 h-2 flex'></i>
														)}
													</button>
												</li>
											))}
										</>
									)}
									{user?.role?.name === 'Admin' && (
										<li className='py-3 px-2  pb-0 border-t border-solid border-opacity-5 border-gray-700  '>
											<Link
												to={'/space/create'}
												className='text-xs group/item hover:text-primaryBlue text-gray-400 leading-[18px] gap-4 font-medium flex items-center'>
												<span className='flex text-gray-400 group-hover/item:!text-primaryBlue w-[18px]  -mt-1'>
													<ProjectIcon />
												</span>
												Create New Space
											</Link>
										</li>
									)}
								</ul>
							</div>
						)}
					</div>
				</li>
			)}
		</>
	)
}

export default HeaderOrganizationDropdown
