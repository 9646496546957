import React from 'react'

const TeamReportIcon = () => {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.46347 3.13276C6.87192 3.0563 7.28143 3.26024 7.45963 3.62883C7.50399 3.69571 7.5395 3.76787 7.56528 3.84355C7.67848 5.59092 7.79923 7.30867 7.91243 9.02642C7.90985 9.20497 7.93792 9.38268 7.99544 9.55211C8.13087 9.88545 8.46788 10.0969 8.83312 10.0778L14.3799 9.7224L14.4177 9.73721L14.5189 9.74449C14.7194 9.77016 14.9064 9.86169 15.0483 10.0057C15.2139 10.1737 15.3047 10.3994 15.3007 10.6331C15.082 13.8182 12.7469 16.4794 9.56906 17.165C6.39123 17.8506 3.13356 16.396 1.57323 13.5947C1.11443 12.7879 0.8245 11.8993 0.72046 10.9811C0.680107 10.709 0.66244 10.4341 0.667633 10.1592C0.67815 6.77239 3.08948 3.84906 6.46347 3.13276ZM9.76136 0.667191C13.5704 0.781216 16.7537 3.54634 17.3307 7.24203C17.3343 7.2641 17.3343 7.2866 17.3307 7.30867L17.3296 7.413C17.3171 7.55091 17.2605 7.68244 17.1664 7.78804C17.0489 7.92005 16.8826 8.00066 16.7043 8.01205L10.3047 8.43409L10.1993 8.43618C10.0246 8.42766 9.85711 8.35972 9.72662 8.24227C9.57004 8.10133 9.48127 7.90216 9.48214 7.69368L9.05198 1.4076V1.30394C9.05981 1.12715 9.13899 0.960673 9.27207 0.841223C9.40514 0.721772 9.58118 0.65916 9.76136 0.667191Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(TeamReportIcon)
