import './ContentContainer.scss'
import PropTypes from 'prop-types'

const ContentContainer = ({ ChildComponent }) => {
	return (
		<div className='ContentContainer'>
			<ChildComponent />
		</div>
	)
}

ContentContainer.propTypes = {
	ChildComponent: PropTypes.object.isRequired
}

ContentContainer.defaultProps = {
	ChildComponent: {}
}

export default ContentContainer
