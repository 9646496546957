import useFetch from 'hooks/useFetch'
const endPoint = '/attachment'

export const AddAttachmentApi = async (router, data) => {
    let response = await useFetch(router, true).post(`${endPoint}/addFile`, {
        ...data
    }, {
        headers: { "Content-Type": "multipart/form-data" }
    })

    return response
}

export const DeleteAttachmentApi = async (router, id) => {
    let response = await useFetch(router, true).delete(`${endPoint}/delete/${id}`)
    return response
}

export const GetMediaTypesApi = async (router, id) => {
    let response = await useFetch(router, true).get(`/mediaType`)
    return response
}

export const DownloadAttachmentFileApi = async (router, id) => {
    let response = await useFetch(router, true).get(`${endPoint}/download/${id}`, {responseType: 'blob'}
    )
    return response
}


