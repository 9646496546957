import React from 'react'
import PropTypes from 'prop-types'

const MessageTypeText = props => {
	return (
		<p className='text-current text-opacity-80 font-light text-sm'>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
			tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra
			accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean{' '}
		</p>
	)
}

MessageTypeText.propTypes = {}

export default MessageTypeText
