import React from 'react'

function MinesIcon() {
	return (
		<svg
			width='13'
			height='3'
			viewBox='0 0 13 3'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.5 1.5H1.5'
				stroke='currentColor'
				stroke-width='2'
				stroke-linecap='round'
			/>
		</svg>
	)
}

export default React.memo(MinesIcon)
