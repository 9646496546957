import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const SEOHeader = ({ title }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content='Welcome to Teamup Space' />
		</Helmet>
	)
}

SEOHeader.propTypes = {
	title: PropTypes.string.isRequired
}

SEOHeader.defaultProps = {
	title: 'Index'
}

export default SEOHeader
