import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RequestHeaderHandler } from 'utils/RequestHeaderHandler'

export const noteApiRedux = createApi({
	reducerPath: 'noteApiRedux',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			return RequestHeaderHandler(headers)
		}
	}),
	endpoints: builder => ({
		getAllNotesApi: builder.query({
			query: ({ search, taskId, milestoneId, projectId }) => {
				return {
					url: 'note',
					params: { search, taskId, milestoneId, projectId }
				}
			},
			transformResponse: response => response.data
		})
	}),
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true
})

export const { useGetAllNotesApiQuery, useLazyGetAllNotesApiQuery } =
	noteApiRedux
