import React from 'react'

function DownloadIcon({ width = 17, height = 20, ...props }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 17 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M2.03125 17.6875H14.9688C15.2419 17.6876 15.5049 17.7914 15.7045 17.9778C15.904 18.1643 16.0254 18.4196 16.044 18.6921C16.0627 18.9647 15.9771 19.2341 15.8048 19.446C15.6324 19.6579 15.386 19.7965 15.1154 19.8337L14.9688 19.8438H2.03125C1.75809 19.8437 1.49515 19.7399 1.29555 19.5534C1.09595 19.3669 0.974581 19.1116 0.955963 18.8391C0.937345 18.5666 1.02287 18.2972 1.19525 18.0853C1.36763 17.8734 1.61402 17.7348 1.88463 17.6976L2.03125 17.6875H14.9688H2.03125ZM8.35338 0.447562L8.5 0.4375C8.76053 0.437511 9.01224 0.531861 9.20859 0.703103C9.40493 0.874344 9.53263 1.11089 9.56806 1.369L9.57813 1.51562V12.5671L12.8197 9.327C13.0025 9.14425 13.2449 9.03326 13.5027 9.01427C13.7605 8.99527 14.0165 9.06952 14.2241 9.2235L14.3449 9.327C14.5276 9.50981 14.6386 9.7522 14.6576 10.01C14.6766 10.2678 14.6024 10.5238 14.4484 10.7314L14.3449 10.8522L9.26188 15.9337C9.07933 16.1163 8.83732 16.2274 8.57985 16.2466C8.32238 16.2659 8.06655 16.1921 7.85888 16.0387L7.73813 15.9337L2.65513 10.8522C2.46222 10.6598 2.3493 10.4015 2.33906 10.1293C2.32883 9.85707 2.42203 9.59105 2.59994 9.38473C2.77785 9.17841 3.02727 9.0471 3.29806 9.01718C3.56884 8.98727 3.84091 9.06099 4.05956 9.2235L4.18031 9.327L7.42188 12.57V1.51562C7.42189 1.2551 7.51624 1.00338 7.68748 0.807037C7.85872 0.610691 8.09527 0.482994 8.35338 0.447562L8.5 0.4375L8.35338 0.447562Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default React.memo(DownloadIcon)
